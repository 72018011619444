export default [
  // agence
  // {
  //   path: '/forms/agence',
  //   name: 'forms-agence',
  //   component: () => import('@/views/apps/formulaires/agence-voyage/FormAgenceVoyage.vue'),
  // },
  // {
  //   path: '/details/agence',
  //   name: 'data-agence',
  //   component: () => import('@/views/apps/formulaires/agence-voyage/PreviewAgenceVoyage.vue'),
  //   props: true,
  // },
  // {
  //   path: '/list/agence',
  //   name: 'list-agence',
  //   component: () => import('@/views/apps/formulaires/agence-voyage/List.vue'),
  //   props: true,
  // },
  // transport
  // {
  //   path: '/forms/transport',
  //   name: 'forms-transport',
  //   component: () => import('@/views/apps/formulaires/transport/Transport.vue'),
  // },
  // restauration
  // {
  //   path: '/forms/restauration',
  //   name: 'forms-restauration',
  //   component: () => import('@/views/apps/formulaires/restauration/ListRestauration.vue'),
  // },
  // hotel
  // {
  //   path: '/forms/hotel',
  //   name: 'forms-hotel',
  //   component: () => import('@/views/apps/formulaires/hotel/ListHotels.vue'),
  // },
  // aeroport arrive
  // {
  //   path: '/forms/FormArriveAeroport',
  //   name: 'FormArriveAeroport',
  //   component: () => import(
  //     '@/views/apps/formulaires/ficheEnregistrementClient/entree-aeroport/ListEntreeAeroport.vue'
  //   ),
  // },
  // aeroport depart
  // {
  //   path: '/forms/FormDepartAeroport',
  //   name: 'FormDepartAeroport',
  //   component: () => import(
  //     '@/views/apps/formulaires/formDepartAeroport/FormDepartAeroport.vue'
  //   ),
  // },
  // Fiche de renseignement
  // {
  //   path: '/forms/renseignement',
  //   name: 'forms-renseignement',
  //   component: () => import(
  //     '@/views/apps/formulaires/ficheRenseignement/FicheRenseignement.vue'
  //   ),
  // },
  // Fiche d'enregistrement des clients
  // {
  //   path: '/forms/enregistrementclients',
  //   name: 'forms-enregistrementclients',
  //   component: () => import(
  //     '@/views/apps/formulaires/ficheEnregistrementClient/hotel/ListHotelClient.vue'
  //   ),
  // },

  // Fiches Remplies
  // {
  //   path: '/fiches-remplies-grand-public',
  //   name: 'fiches-remplies-grand-public',
  //   component: () => import('@/views/apps/formulaires/fichesRemplies/List.vue'),
  //   meta: {
  //     resource: 'Public',
  //   },
  // },
]
