export default [
  // Liste des plaintes
  {
    path: '/reclamation/list',
    name: 'plainte-list',
    component: () => import('@/views/pages/plainte/Index.vue'),
    meta: {
      resource: 'complaint',
      action: 'list',
    },
  },
  // Création d'une plainte
  {
    path: '/reclamation/create',
    name: 'plainte-create',
    component: () => import('@/views/pages/plainte/Create.vue'),
    meta: {
      resource: 'complaint',
      action: 'create',
    },
  },
  // Détails d'une plainte
  {
    path: '/reclamation/details/:id?',
    name: 'plainte-details',
    component: () => import('@/views/pages/plainte/Details.vue'),
    meta: {
      resource: 'complaint',
      action: 'show',
    },
  },
  // Modification d'une plainte
  {
    path: '/reclamation/update/:id?',
    name: 'plainte-update',
    component: () => import('@/views/pages/plainte/Update.vue'),
    meta: {
      resource: 'complaint',
      action: 'edit',
    },
  },
];
