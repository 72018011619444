const publicFormsObj = [
  // { subject: 'formulaireAeroportEntree' },
  // { subject: 'formulaireAeroportSortie' },
  // { subject: 'formulaireFrontiereSortie' },
  // { subject: 'formulaireFrontiereEntree' },
  // { subject: 'formulaireHotel' },
  // { subject: 'formulaireTourismeEmetteur' },
  // { subject: 'formulaireTourismeRecepteur' },
  // { subject: 'formulaireTourismeInterne' },
]

const publicActions = ['read', 'create']

const publicFormsPermissions = []

publicFormsObj.forEach(form => {
  publicActions.forEach(action => {
    publicFormsPermissions.push({ ...form, action })
  })
})

export const initialAbility = [
  // {
  //   action: 'manage',
  //   subject: 'all',
  // },
  // {
  //   action: 'read',
  //   subject: 'statsTest',
  // },
  {
    action: 'read',
    subject: 'Auth',
  },
  {
    action: 'read',
    subject: 'Public',
  },
  {
    action: 'list',
    subject: 'Public',
  },
  {
    action: 'create',
    subject: 'Public',
  },
  {
    action: 'access',
    subject: 'Public',
  },
  ...publicFormsPermissions,
  {
    action: 'read',
    subject: 'statistiques',
  },
  // {
  //   action: 'read',
  //   subject: 'statistiquesEtablissemnts',
  // },
  // {
  //   action: 'read',
  //   subject: 'statistiquesFrontieresTerrestres',
  // },
]

export const _ = undefined
