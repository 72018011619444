/* eslint-disable global-require */
<template>
  <div class="contact">
    <img
      :src="require('@/assets/images/tourismeBenin/creds.jpg')"
      alt=""
      class="back-image"
    />
    <div class="filigrane-color" />
    <div class="contact-wrapper">
      <!-- <div class="left-infos">
        <h1>Vous avez une question ?</h1>
        <p>
          Merci de nous contacter en écrivant à cette adresse email:
          mtca.dsisa@gouv.bj. <br />
          Nous mettons tout en oeuvre pour répondre le plus rapidement possible.
        </p>
        <h3>
          Heures d'Ouverture : <br />
          Lundi - Vendredi 8h - 17h30
        </h3>
      </div> -->
      <validation-observer ref="loginForm" v-slot="{ invalid }">
        <b-form @submit.prevent="login" class="contact-form">
          <router-link to="/" class="logo mx-auto">
            <img
              :src="require('@/assets/images/logo/logo_mtca.png')"
              alt="logo"
            />
          </router-link>
          <p>{{ $t("home.platformName") }}</p>

          <!-- <div v-if="error" class="my-1">
            <b-badge variant="light-danger" class="badge-glow">
              <small class="text-danger">
                {{ error }}
              </small>
            </b-badge>
          </div> -->

          <div class="contact-names-email">
            <b-form-group :label="$t('login.email')" label-for="login-email">
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  :placeholder="$t('login.emailPlaceholder')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div>
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{ $t("login.pwd") }}</label>
                <b-link href="/forgot-password">
                  <small>{{ $t("login.forgotPassword") }}</small>
                </b-link>
              </div>
              <validation-provider
                v-slot="{ errors }"
                name="Password"
                vid="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    :placeholder="$t('login.pwdPlaceholder')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="mt-2 text-center">
            <b-button
              type="submit"
              class="d-flex mx-auto align-items-center"
              variant="primary"
              :disabled="invalid"
            >
              <b-spinner v-if="isLoading" small class="mr-1" />
              {{ $t("login.loginBtn") }}
            </b-button>
            <div class="text-center mt-1">
              <b-link href="/">
                <small>{{ $t("login.backHome") }}</small>
              </b-link>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import { $themeConfig } from "@themeConfig";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { AbilityBuilder } from "@casl/ability";
import { initialAbility } from "@/libs/acl/config";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import store from "@/store/index";
import localstorageService from "@/services/localstorage/localstorage.service";
import ColorBandGouvBj from "@/components/ColorBandGouvBj.vue";
import AppLogo from "@/components/AppLogo.vue";
import RecaptchaWidget from "@/components/RecaptchaWidget.vue";
import utilsService from "@/services/utils/utils.service";
import { mapActions } from "vuex";

export default {
  page() {
    return {
      title: () => this.title,
      meta: [{ name: "description", content: $themeConfig.app.description }],
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BSpinner,
    BBadge,

    // eslint-disable-next-line vue/no-unused-components
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,

    ColorBandGouvBj,
    AppLogo,
    RecaptchaWidget,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
    };
  },
  data() {
    return {
      title: "Connexion",
      status: "",
      password: "",
      userEmail: "",
      error: null,
      sideImg: require("@/assets/images/pages/image-stat-1.jpg"),

      // validation rules
      required,
      email,
      isLoading: false,
      recaptchaResponse: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    // imgUrl() {
    //   if (store.state.appConfig.layout.skin === 'dark') {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.sideImg = require('@/assets/images/pages/login-default.png')
    //     return this.sideImg
    //   }
    //   return this.sideImg
    // },
  },
  mounted() {
    const { redirectMotive } = this.$route.params;
    if (redirectMotive && redirectMotive === "sessionExpired") {
      const errorMessage =
        "Votre session a expiré. Veuillez vous reconnecter pour accéder à votre compte.";
      this.$toast({
        component: ToastificationContent,
        props: {
          title: errorMessage,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
  methods: {
    ...mapActions("imagePuzzleSliderCaptcha", { showSliderCaptcha: "show" }),
    // -----------------------------------------------------------------------
    login() {
      this.$refs.loginForm.validate().then(async (success) => {
        if (success) {
          this.isLoading = true;
          // ReCaptcha v3
          // (optional) Wait until recaptcha has been loaded.
          await this.$recaptchaLoaded();
          // Execute reCAPTCHA with action "login".
          const token = await this.$recaptcha("login");
          if (!token) {
            this.isLoading = false;
            return;
          }

          this.$store
            .dispatch("auth/login", {
              email: this.userEmail,
              password: this.password,
              token,
            })
            .then((response) => {
              if (response.data.is_otp_active) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: response.data.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                this.$router.push({
                  name: "otp-login",
                  query: { email: this.userEmail },
                });

                return;
              }

              if (response.data.data.token) {
                const { user, token } = response.data.data;
                localstorageService.setJwtToken(token);
                if (user && user.roles) {
                  let permissions = [
                    ...initialAbility,
                    ...(user.permissions || [])
                      .map((e) => {
                        return { subject: e.resource, action: e.action };
                      })
                      .filter((e) => e.subject !== "all"),
                  ];
                  delete user.permissions;
                  localstorageService.setUserData({
                    ...user,
                    ability: permissions,
                  });
                  localstorageService.setUserSignature(user.signature);
                }
                // redirect user to the page he was looking for before he was redirect to login page. If none, redirect to /
                // const pathToRedirectToAfterLogin =
                // localstorageService.getRouteToRedirectToAfterLogin();
                // return
                // window.location = pathToRedirectToAfterLogin || '/';

                // console.log("user::: ⭕️", user.roles);
                const first_role = user.roles[0];
                console.log("user.roles::: ", user.roles);
                console.log("first_role.alias::: ⭕️", first_role.alias);
                // return;
                setTimeout(() => {
                  this.$router.go(); // reload page
                }, 200);
                switch (first_role.alias) {
                  case "A":
                    this.$router.replace({
                      name: "dashboard",
                    });
                    break;
                  case "UAT":
                    this.$router.replace({
                      name: "dashboard",
                    });
                    break;
                  case "UEH":
                    this.$router.replace({
                      name: "formulaires-list-ue",
                    });
                    break;
                  case "UEAV":
                    this.$router.replace({
                      name: "formulaires-list-ue",
                    });
                    break;
                  case "UEGT":
                    this.$router.replace({
                      name: "formulaires-list-ue",
                    });
                    break;
                  case "UER":
                    this.$router.replace({
                      name: "formulaires-list-ue",
                    });
                    break;
                  default: // reload page
                    // this.$router.go();
                    break;
                }
              } else {
                this.isLoading = false;
                const customError = {
                  email: ["Identifiants incorrects. Veuillez réessayer."],
                };
                this.$refs.loginForm.setErrors(customError);
              }
            })
            .catch((error) => {
              console.log("error::: ", error);
              this.isLoading = false;
              // console.log("error::: 🔴", error, error.config, error.response);
              if (error.response.data && error.response.data.length !== 0) {
                this.error = error.response.data;
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response && 
           error.response.data && 
           error.response.data.email && 
           error.response.data.email[0] 
           ? error.response.data.email[0] 
           : "Identifiants incorrects. Veuillez réessayer.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    onRecaptchaChange(recaptchaEvent) {
      if (recaptchaEvent && recaptchaEvent.type === "success") {
        this.recaptchaResponse = recaptchaEvent;
      } else {
        this.recaptchaResponse = null;
      }
    },
    updateAbility() {
      const { can, rules } = new AbilityBuilder();

      if (user.role === "admin") {
        can("manage", "all");
      } else {
        can("read", "all");
      }

      this.$ability.update(rules);
    },
  },
};
</script>

<style scoped>
.contact {
  position: relative;
  width: 100%;
  min-height: 100vh;
}
.contact-wrapper {
  margin: 0 auto;
  max-width: 500px;
  height: 100%;
  min-height: 100vh;

  padding: 3em 1em;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 3em;
}
.contact .back-image {
  position: absolute;
  inset: 0;
  z-index: -20;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact .filigrane-color {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: -10;

  background-color: #06250fb2;
}

.left-infos {
  color: #fff;
  text-shadow: 2px 2px 1px #00000090;
}
.left-infos h1 {
  color: #fff;
  font-weight: bold;
  font-size: 35px;
}
.left-infos h3 {
  color: #fff;
}
.left-infos p {
  margin: 1em 0;
  line-height: 30px;
  color: #fff;
  font-size: 16px;
}

.contact-form {
  border-radius: 1em;
  padding: 2em;
  background-color: #fff;
  min-width: 330px;
  /* max-width: 1300px; */
}
.contact-form .logo {
  max-width: 190px;
  display: block;
}
.contact-form .logo img {
  width: 190px;
}
.contact-form p {
  margin: 1em auto;
  font-size: 16px;
  text-align: center;
}
.contact-names-email {
  display: grid;
  gap: 0 2em;
}
/* MEDIA QUERIES */
@media (min-width: 576px) {
  .contact-wrapper {
    flex-direction: row;
    padding: 4em 3em;
  }
  .contact-form {
    padding: 3em;
  }
  .contact-form {
    width: 100%;
    max-width: 650px;
  }
  .left-infos {
    width: 55%;
  }
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .left-infos h1 {
    font-size: 55px;
  }
  .left-infos p {
    font-size: 25px;
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}
</style>
