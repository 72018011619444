const statuses = [
  {
    status: "on_hold",
    etape: "ENREGISTRÉ, EN ATTENTE DE TRAITEMENT",
    action_label: "TRAITER LA DEMANDE",
  },
  {
    status: "treatment_start",
    etape: "TRAITETEMENT DÉBUTÉ",
    action_label: "COMMENCER LE TRAITEMENT DE LA DEMANDE",
    class: "relief-dark",
  },
  {
    status: "form_control",
    etape: "VÉRIFICATION DE LA FORME",
    action_label: "VALIDER LA FORME DE LA DEMANDE",
    class: "relief-secondary",
  },
  {
    status: "in_process_technical_control",
    etape: "PROCESSUS CONTRÔL TECHNIQUE",
    action_label: "LANCER LE PROCESSUS D'AUTORISATION D'EXPLOITATION",
    class: "relief-secondary",
    next_decisions_options_count: 2,
    treatment_section: true,
  },

  // ----- decisions
  {
    status: "rejected_registration",
    etape: "AUTORISATION D'EXPLOITATION REJETÉE",
    action_label: "REJETER L'AUTORISATION D'EXPLOITATION",
    class: "relief-danger",
    decision: true,
    final_state: true,
  },
  {
    status: "accepted_registration",
    etape: "AUTORISATION D'EXPLOITATION VALIDÉE",
    action_label: "ACCEPTER  L'AUTORISATION D'EXPLOITATION",
    class: "relief-primary",
    decision: true,
  },
  // ---------------------------------------------
  {
    status: "in_process_audit_control",
    etape: "PROCESSUS D'AUDIT",
    action_label: "LANCER LE PROCESSUS DE L'AUDIT DE CLASSEMENT",
    next_decisions_options_count: 2,
    class: "relief-secondary",
    treatment_section: true,
  },

  // ----- decisions
  {
    status: "rejected_classification",
    etape: "DEMANDE DE CLASSEMENT REJETÉE",
    action_label: "REJETER LA DEMANDE DE CLASSEMENT",
    class: "relief-danger",
    decision: true,
    final_state: true,
  },
  {
    status: "accepted_classification",
    etape: "DEMANDE DE CLASSEMENT VALIDÉE",
    action_label: "ACEPTER LA DEMANDE DE CLASSEMENT",
    class: "relief-primary",
    decision: true,
    final_state: true,
  },
  // ---------------------------------------------
];
export default statuses;
