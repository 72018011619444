import manuelUtilisationsService from '@/services/http/manuel-utilisations.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    async createManuelUtilisation({ rootState }, data) {
      const res = await manuelUtilisationsService.createManuelUtilisation(data)
      return res
    },

    async fetchManuelUtilisations({ rootState }, data) {
      const res = await manuelUtilisationsService.fetchManuelUtilisations(data)
      return res
    },

    async getManuelUtilisation({ rootState }, data) {
      const res = await manuelUtilisationsService.getManuelUtilisation(data)
      return res
    },

    async updateManuelUtilisation({ rootState }, data) {
      const res = await manuelUtilisationsService.updateManuelUtilisation(data)
      return res
    },

    async deleteManuelUtilisation({ rootState }, data) {
      const res = await manuelUtilisationsService.deleteManuelUtilisation(data)
      return res
    },
  },
}
