export default [
  // {
  //   path: '/users/search',
  //   name: 'users-search',
  //   component: () => import('@/views/pages/users/users-search/UserList.vue'),
  // },
  {
    path: "/users/list",
    name: "users-list",
    component: () =>
      import("@/views/apps/utilisateurs/administration/ListUtilisateurs.vue"),
    meta: {
      resource: "utilisateurs",
      action: "administrate",
    },
  },
  {
    path: "/users/:userId",
    name: "users-details",
    component: () =>
      import("@/views/apps/utilisateurs/administration/DetailsUtilisateur.vue"),
    meta: {
      resource: "utilisateurs",
      action: "administrate",
    },
  },



  //investor mails

  {
    path: "/investor/mails",
    name: "config-invertors-mails-list",
    component: () =>
      import("@/views/configs/mails/Index.vue"),
    meta: {
      resource: "utilisateurs",
      action: "administrate",
    },
  },
  {
    path: "/investor/mails/:userId",
    name: "config-invertors-mails-details",
    component: () =>
      import("@/views/configs/mails/Details.vue"), 
    meta: {
      resource: "utilisateurs",
      action: "administrate",
    },
  },

  // {
  //   path: '/users/search',
  //   name: 'users-create',
  //   component: () => import('@/views/pages/users/users-create/UserCreate.vue'),
  // },
];
