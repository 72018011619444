import utilsService from "@/services/http/utils.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async checkNPI({ rootState }, data) {
      const res = await utilsService.checkNPI(data);
      return res;
    },
    async checkNPI_code({ rootState }, data) {
      const res = await utilsService.checkNPI_code(data);
      return res;
    },
    async checkRCCM({ rootState }, data) {
      const res = await utilsService.checkRCCM(data);
      return res;
    },
    async sendMail({ rootState }, data) {
      const res = await utilsService.sendMail(data);
      return res;
    },
    async sso_send_code_to_log({ rootState }, data) {
      const res = await utilsService.sso_send_code_to_log(data);
      return res;
    },
  },
};
