import plaintesService from '@/services/http/plaintes.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    async searchPlainte({ rootState }, data) {
      const res = await plaintesService.searchPlainte(data)
      return res
    },

    async createPlainte({ rootState }, data) {
      const res = await plaintesService.createPlainte(data)
      return res
    },

    async treatePlainte({ rootState }, data) {
      const res = await plaintesService.treatePlainte(data)
      return res
    },

    async fetchPlaintes({ rootState }, data) {
      const res = await plaintesService.fetchPlaintes(data)
      return res
    },

    async getPlainte({ rootState }, data) {
      const res = await plaintesService.getPlainte(data)
      return res
    },

    async updatePlainte({ rootState }, data) {
      const res = await plaintesService.updatePlainte(data)
      return res
    },

    async deletePlainte({ rootState }, data) {
      const res = await plaintesService.deletePlainte(data)
      return res
    },
  },
}
