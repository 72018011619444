import { BaseService } from "./base.service";
// anip npi
// const url_anip = "URL_DU_POINT_DE_TERMINAISON";
// const xmlData_anip = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" ...>
//     <!-- Votre requête SOAP ici -->
// </soapenv:Envelope>`;
// sso
// const basepath_sso = "http://pns-ss01.xroad.bj:8081/restapi/";
// const configRequests_sso = {
//   headers: {
//     // Authorization: `Bearer ${token}`,
//     "Content-Type": "application/x-www-form-urlencoded",
//   },
// };
// rccm
export class UtilsService extends BaseService {
  constructor() {
    super();
  }

  sso_send_code_to_log({ code }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/login/sso", { token: code })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  checkRCCM({ numeroRccm }) {
    // RB/COT/23 A 94736
    // const basepath_rccm = "http://pns-ss01.xroad.bj:8081/restapi?nameOrRccm=";
    // const configRequests_rccm = {
    //   headers: {
    //     Authorization: `Bearer CFLWVXfGtDbTsl1zv/o=`,
    //     "Uxp-Client": "BJ/GOV/MTCA/e-tourisme",
    //     "Uxp-Service": "BJ/GOV/APIEx/MONENTREPRISE/monEntrepriseTourisme/v1",
    //   },
    //   // proxy: {
    //   //   protocol: "http",
    //   //   host: "10.212.134.100",
    //   //   port: 10443,
    //   //   // username: "MCOAGOSSOU&23?",
    //   //   // password: "Pwrd23%&$$",
    //   // },
    // };
    // const final_path_rccm = basepath_rccm + numeroRccm;
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/check-rccm?rccm=${numeroRccm}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  checkNPI({ numeroIfu }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/check-npi?npi=${numeroIfu}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  // checkApiStatus() {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(basepathFacture, configRequests)
  //       .then(response => resolve(response))
  //       .catch(error => reject(error))
  //   })
  // }
  checkNPI_code({ codeIfu }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`https://ifubackend.impots.bj/api/default/searchByIFU/${codeIfu}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  sendMail(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/mails/send", data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}

export default new UtilsService();
