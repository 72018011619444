import { Ability } from '@casl/ability'
import utilsService from '@/services/utils/utils.service'
import { initialAbility } from './config'
import localstorageService from '@/services/localstorage/localstorage.service'

const { permissions } = utilsService.currentUserUtils()

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
// const userData = localstorageService.getUserData()
// const existingAbility = userData ? userData.ability : null

export default new Ability(permissions || initialAbility)
