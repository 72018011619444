import { BaseService } from './base.service'

export class ManuelUtilisationsService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super()
  }

  createManuelUtilisation(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/manuel-utilisations', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchManuelUtilisations(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/manuel-utilisations', { data })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getManuelUtilisation(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/manuel-utilisations/${id}`, {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updateManuelUtilisation(object) {
    const { id, data } = object
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/manuel-utilisations/${id}`, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  deleteManuelUtilisation(id) {
    // console.log('id :>> ', id)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`/manuel-utilisations/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new ManuelUtilisationsService()
