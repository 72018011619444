export default [
  // Liste des manuels d'utilisations
  {
    path: '/manuel-utilisations/list',
    name: 'manuel-utilisation-list',
    component: () => import('@/views/pages/manuel-utilisation/Index.vue'),
    meta: {
      action: 'list',
      resource: 'manuel_utilisation',
    },
  },
  // Création d'un manuel d'utilisation
  {
    path: '/manuel-utilisation/create',
    name: 'manuel-utilisation-create',
    component: () => import('@/views/pages/manuel-utilisation/Create.vue'),
    meta: {
      action: 'create',
      resource: 'manuel_utilisation',
    },
  },
  // Détails d'un manuel d'utilisation
  {
    path: '/manuel-utilisation/details/:id?',
    name: 'manuel-utilisation-details',
    component: () => import('@/views/pages/manuel-utilisation/Details.vue'),
    meta: {
      action: 'show',
      resource: 'manuel_utilisation',
    },
    props: route => ({
      detailsId: parseInt(route.params.id),
    }),
  },
  // Modification d'un manuel d'utilisation
  {
    path: '/manuel-utilisation/update/:id?',
    name: 'manuel-utilisation-update',
    component: () => import('@/views/pages/manuel-utilisation/Update.vue'),
    meta: {
      action: 'edit',
      resource: 'manuel_utilisation',
    },
    props: route => ({
      updateId: parseInt(route.params.id),
    }),
  },
]
