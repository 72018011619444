<template>
  <!-- <b-card
    border-variant="warning"
    header-bg-variant="transparent"
    align="center"
    class="cursor-pointer rounded-full"
  >
    <b-card-text>FEDAPAY</b-card-text>
  </b-card> -->
  <div>
    <!-- {{ initialsDatas }} -->
    <b-img
      thumbnail
      rounded="circle"
      :src="require('@/assets/images/logo/feda.jpg')"
      height="100"
      width="100"
      alt=""
    />
  </div>

</template>

<script>
import {
  BButton, BCard,
  BCardText,
  BImg,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BCardText,
    ToastificationContent,
    BImg,

  },
  props: {
    initialsDatas: {
      type: Object,
      default: () => ({
        amount: 0,
        public_key: null,
      }),
    },
    startPayment: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    startPayment: {
      deep: true,
      handler(val, old) {
        // if (val === true) this.openPaymentWidget()
      },
    },
  },
  mounted() {
    window.addEventListener('message', evt => {
      // console.log('evt :>> ', evt)
    })
  },
  methods: {
    openPaymentWidget() {
      let {
        amount, description, public_key, customer,
      } = this.initialsDatas

      amount = parseInt(amount, 10)

      if (Number.isNaN(amount) || amount === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Le montant doit être supérieur à 0',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      console.log('this.fedaPayPaymentInfos :>> ', this.fedaPayPaymentInfos)
      const { FedaPay } = window
      // eslint-disable-next-line camelcase

      const fedaPaymentInstance = FedaPay.init({
        public_key,
        transaction: {
          amount,
          description,
        },
        customer,
        onComplete: this.onPaymentComplete,
      })

      fedaPaymentInstance.open()
    },

    onPaymentComplete({ reason, transaction }) {
      console.log('reason :>> ', reason)
      console.log('transaction :>> ', transaction)
      const { status, transaction_key, id } = transaction

      if (reason === 'DIALOG DISMISSED') {
        this.$emit('onClosed', {})
        return
      }

      const isSuccess = reason === 'CHECKOUT COMPLETE' && status === 'approved'

      if (isSuccess) {
        const paymentResult = { transactionId: id }
        this.$emit('onPaymentSuccess', transaction)
      }
    },
  },
}
</script>

<style>

</style>
