export default [
  // profil utilisateur
  {
    path: "/users-profile",
    name: "users-profile",
    component: () => import("@/views/pages/profil/Index.vue"),
    meta: {
      action: "read",
      resource: "Public",
    },
  },
];
