export default [
  // Manuel d'utlisation
  {
    path: '/old-faq',
    name: 'old-faq',
    component: () => import('@/views/pages/faq/Faq.vue'),
    meta: {
      resource: 'Public',
    },
  },

  // ---------------------------------------------
  // Nouvelle liste de faq
  {
    path: '/faqs-list',
    name: 'faqs-list',
    component: () => import('@/views/pages/faq/faqs/index.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/faq-create',
    name: 'faq-create',
    component: () => import('@/views/pages/faq/faqs/create.vue'),
    meta: {
      resource: 'Public',
      action: 'create',
    },
  },
  {
    path: '/faq-details',
    name: 'faq-details',
    component: () => import('@/views/pages/faq/faqs/details.vue'),
    meta: {
      resource: 'Public',
      action: 'create',
    },
  },
  // ---------------------------------------------
  // Grand public - Description de l'utilisation
  // {
  //   path: '/faq/grandPublic/descriptionUtilisation',
  //   name: 'faq-grandPublic-description-utilisation',
  //   component: () => import('@/views/pages/faq/grand-public/DescriptionUtilisation.vue'),
  //   meta: {
  //     resource: 'Public',
  //   },
  // },

  // Grand public - Inscription
  // {
  //   path: '/faq/grandPublic/inscription',
  //   name: 'faq-grandPublic-inscription',
  //   component: () => import('@/views/pages/faq/grand-public/Inscription.vue'),
  //   meta: {
  //     resource: 'Public',
  //   },
  // },

  // Promoteur détablissement - Description de l'utilisation
  {
    path: '/manuel-utilisation/promoteurEtablissement/inscription',
    name: 'manuel-utilisation-promoteurEtablissement-inscription',
    component: () => import('@/views/pages/faq/promoteur-etablissement/Inscription.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/promoteurEtablissement/compte-agent-etablissement',
    name: 'manuel-utilisation-promoteurEtablissement-compte-agent-etablissement',
    component: () => import('@/views/pages/faq/promoteur-etablissement/CompteAgentEtablissement.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/promoteurEtablissement/enregistrement-client-hotel',
    name: 'manuel-utilisation-promoteurEtablissement-enregistrement-client-hotel',
    component: () => import('@/views/pages/faq/promoteur-etablissement/EnregistrementClientHotel.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/promoteurEtablissement/ficheEnregistrementClientHotel',
    name: 'manuel-utilisation-promoteurEtablissement-ficheEnregistrementClientHotel',
    component: () => import('@/views/pages/faq/promoteur-etablissement/FicheEnregistrementClientHotel.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/promoteurEtablissement/ficheStatistiqueExploitationHotel',
    name: 'manuel-utilisation-promoteurEtablissement-ficheStatistiqueExploitationHotel',
    component: () => import('@/views/pages/faq/promoteur-etablissement/FicheStatistiqueExploitationHotel.vue'),
    meta: {
      resource: 'Public',
    },
  },

  {
    path: '/manuel-utilisation/promoteurEtablissement/ficheStatistiqueAgenceVoyage',
    name: 'manuel-utilisation-promoteurEtablissement-ficheStatistiqueAgenceVoyage',
    component: () => import('@/views/pages/faq/promoteur-etablissement/FicheStatistiqueAgenceVoyage.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/promoteurEtablissement/ficheStatistiqueAgenceTransportVoyageur',
    name: 'manuel-utilisation-promoteurEtablissement-ficheStatistiqueAgenceTransportVoyageur',
    component: () => import('@/views/pages/faq/promoteur-etablissement/FicheStatistiqueAgenceTransportVoyageur.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/promoteurEtablissement/ficheStatistiqueAgenceLocationVehiculeSansOperateur',
    name: 'manuel-utilisation-promoteurEtablissement-ficheStatistiqueAgenceLocationVehiculeSansOperateur',
    component: () => import('@/views/pages/faq/promoteur-etablissement/FicheStatistiqueAgenceLocationVehiculeSansOperateur.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/promoteurEtablissement/ficheStatistiqueEtablissementRestauBarDebit',
    name: 'manuel-utilisation-promoteurEtablissement-ficheStatistiqueEtablissementRestauBarDebit',
    component: () => import('@/views/pages/faq/promoteur-etablissement/FicheStatistiqueEtablissementRestauBarDebit.vue'),
    meta: {
      resource: 'Public',
    },
  },

  // Commissaire
  {
    path: '/manuel-utilisation/commissaire/inscription',
    name: 'manuel-utilisation-commissaire-inscription',
    component: () => import('@/views/pages/faq/commissaire/Inscription.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/commissaire/ficheEnregistrementEntreeFrontiereTerrestre',
    name: 'manuel-utilisation-commissaire-fiche-enregistrement-entree-frontiere-terrestre',
    component: () => import('@/views/pages/faq/commissaire/FicheEnregistrementEntreeFrontiereTerrestre.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/commissaire/ficheEnregistrementSortieFrontiereTerrestre',
    name: 'manuel-utilisation-commissaire-fiche-enregistrement-sortie-frontiere-terrestre',
    component: () => import('@/views/pages/faq/commissaire/FicheEnregistrementSortieFrontiereTerrestre.vue'),
    meta: {
      resource: 'Public',
    },
  },

  // Commissaire
  {
    path: '/manuel-utilisation/agent-police/ficheEnregistrementEntreeFrontiereTerrestre',
    name: 'manuel-utilisation-agent-police-fiche-enregistrement-entree-frontiere-terrestre',
    component: () => import('@/views/pages/faq/agent-police/FicheEnregistrementEntreeFrontiereTerrestre.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/agent-police/ficheEnregistrementSortieFrontiereTerrestre',
    name: 'manuel-utilisation-agent-police-fiche-enregistrement-sortie-frontiere-terrestre',
    component: () => import('@/views/pages/faq/agent-police/FicheEnregistrementSortieFrontiereTerrestre.vue'),
    meta: {
      resource: 'Public',
    },
  },

  // Administrateur
  {
    path: '/manuel-utilisation/adminstrateur/compteAgentMtca',
    name: 'manuel-utilisation-adminstrateur-compte-agent-mtca',
    component: () => import('@/views/pages/faq/administrateur/CompteAgentMtca.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/adminstrateur/compteCommissariat',
    name: 'manuel-utilisation-adminstrateur-compte-commissariat',
    component: () => import('@/views/pages/faq/administrateur/CompteCommissariat.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/adminstrateur/compteAdministrateur',
    name: 'manuel-utilisation-adminstrateur-compte-administrateur',
    component: () => import('@/views/pages/faq/administrateur/CompteAdministrateur.vue'),
    meta: {
      resource: 'Public',
    },
  },

  // Agent MTCA
  {
    path: '/manuel-utilisation/agent-mtca/donneeStatistique',
    name: 'manuel-utilisation-agent-mtca-donnee-statistique',
    component: () => import('@/views/pages/faq/agent-mtca/DonneeStatistique.vue'),
    meta: {
      resource: 'Public',
    },
  },

  // Agent Etablissement
  {
    path: '/manuel-utilisation/agent-etablissement/ficheEnregistrementClientHotel',
    name: 'manuel-utilisation-agent-etablissement-ficheEnregistrementClientHotel',
    component: () => import('@/views/pages/faq/agent-etablissement/FicheEnregistrementClientHotel.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/agent-etablissement/ficheStatistiqueExploitationHotel',
    name: 'manuel-utilisation-agent-etablissement-ficheStatistiqueExploitationHotel',
    component: () => import('@/views/pages/faq/agent-etablissement/FicheStatistiqueExploitationHotel.vue'),
    meta: {
      resource: 'Public',
    },
  },

  {
    path: '/manuel-utilisation/agent-etablissement/ficheStatistiqueAgenceVoyage',
    name: 'manuel-utilisation-agent-etablissement-ficheStatistiqueAgenceVoyage',
    component: () => import('@/views/pages/faq/agent-etablissement/FicheStatistiqueAgenceVoyage.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/agent-etablissement/ficheStatistiqueAgenceTransportVoyageur',
    name: 'manuel-utilisation-agent-etablissement-ficheStatistiqueAgenceTransportVoyageur',
    component: () => import('@/views/pages/faq/agent-etablissement/FicheStatistiqueAgenceTransportVoyageur.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/agent-etablissement/ficheStatistiqueAgenceLocationVehiculeSansOperateur',
    name: 'manuel-utilisation-agent-etablissement-ficheStatistiqueAgenceLocationVehiculeSansOperateur',
    component: () => import('@/views/pages/faq/agent-etablissement/FicheStatistiqueAgenceLocationVehiculeSansOperateur.vue'),
    meta: {
      resource: 'Public',
    },
  },
  {
    path: '/manuel-utilisation/agent-etablissement/ficheStatistiqueEtablissementRestauBarDebit',
    name: 'manuel-utilisation-agent-etablissement-ficheStatistiqueEtablissementRestauBarDebit',
    component: () => import('@/views/pages/faq/agent-etablissement/FicheStatistiqueEtablissementRestauBarDebit.vue'),
    meta: {
      resource: 'Public',
    },
  },

  {
    path: '/faqList',
    name: 'faq-list',
    component: () => import('@/views/pages/faq/FaqList.vue'),
    meta: {
      resource: 'Public',
    },
  },

  // {
  //   path: '/faqCreate',
  //   name: 'faq-create',
  //   component: () => import('@/views/pages/faq/FaqCreate.vue'),
  //   meta: {
  //     resource: 'faq',
  //     action: 'create',
  //   },
  // },

  // {
  //   path: '/faqEdit',
  //   name: 'faq-edit',
  //   component: () => import('@/views/pages/faq/FaqEdit.vue'),
  //   meta: {
  //     resource: 'Public',
  //   },
  // },

  {
    path: '/faqDetail/:faqId',
    name: 'faq-detail',
    component: () => import('@/views/pages/faq/FaqDetail.vue'),
    meta: {
      resource: 'Public',
    },
  },

  {
    path: '/faqEdit/:faqId',
    name: 'faq-edit',
    component: () => import('@/views/pages/faq/FaqEdit.vue'),
    meta: {
      resource: 'Public',
    },
  },
]
