export default [
  {
    path: "/dashboard/analytics",
    name: "dashboard-analytics",
    component: () => import("@/views/dashboard/analytics/Analytics.vue"),
    meta: {
      resource: "dashbord",
      action: "access",
    },
  },
  // {
  //   path: '/dashboard/ecommerce',
  //   name: 'dashboard-ecommerce',
  //   component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  // },
  // {
  //   path: "/dashboard",
  //   name: "dashboard",
  //   component: () => import("@/views/dashboard/ecommerce/Ecommerce.vue"),
  //   meta: {
  //     resource: "Public",
  //     layout: "default",
  //   },
  // },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/dashboard/Index.vue"),
    meta: {
      resource: "dashboard",
      action: "access",
    },
  },
];