import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

import { Can } from '@casl/vue'
import i18n from '@/libs/i18n'
import VueProgressBar from 'vue-progressbar'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/toastification'
import '@/libs/vue-select'

import 'remixicon/fonts/remixicon.css'
import '@/assets/lineawesome/css/line-awesome.min.css'

import 'animate.css'
import * as VeeValidate from 'vee-validate'

import flatpickr from 'flatpickr'
import { French } from 'flatpickr/dist/l10n/fr'
import { exportArrayAsCsv } from '@/services/utils/csv.utils'
import moment from 'moment'
import VueMeta from 'vue-meta'
import { initialAbility } from '@/libs/acl/config'
import statuses from '@/views/apps/formulaires/requests_states.js'

/* import VueTelInput from "vue-tel-input";
//import "vue-tel-input/vue-tel-input.css";
Vue.use(VueTelInput); */

// ReCaptcha v3
import { VueReCaptcha } from 'vue-recaptcha-v3'

import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'
import dotenv from 'dotenv'
import localstorageService from '@/services/localstorage/localstorage.service'
// import usersStoreModule from '@/store/users'
// import { registerStoreModule, unregisterStoreModule } from '@/helpers/vuex-utils'
// import { mapActions, mapMutations } from 'vuex'
import VueSweetalert2 from 'vue-sweetalert2'
import store from './store'
import router from './router'
import App from './App.vue'

// Importer SweetAlert2 et son plugin Vue
import 'sweetalert2/dist/sweetalert2.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

dotenv.config()
// Vue.use(VueReCaptcha, { siteKey: '6LfZ4KgkAAAAAP7zmXyXLuV4RQaG60en_NQCD9b1' });
Vue.use(VueReCaptcha, { siteKey: '6LepulEoAAAAAGhM_GFYeUvb95nDX8f5lrXoHpeW' })

Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
})
dayjs.extend(relativeTime)
dayjs.locale('fr')
Vue.prototype.$dayjs = dayjs

// Utiliser le plugin VueSweetalert2
Vue.use(VueSweetalert2)

// moment js
// vue flatpick configs
flatpickr.setDefaults({
  dateFormat: 'd-m-Y', // default options here
})
flatpickr.localize(French)

const vueProgressBarOptions = {
  color: '#FCCD5D',
  failedColor: '#F3706A',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
}

Vue.use(VueProgressBar, vueProgressBarOptions)

// CASL
Vue.component('Can', Can)

// Composition API
Vue.use(VueCompositionAPI)

const dictionary = {
  en: {
    messages: {
      alpha: () => 'Some English Message',
      required: () => 'The field is required !!!',
    },
  },
  fr: {
    messages: {
      required: field => 'Ce champ est requis',
      email: field => 'Ce champ requiert un email',
      alpha: field => 'Ce champ ne peut contenir que des lettres',
      strictlyNumber: field => 'Ce champ ne doit contenir que des nombres',
      strictlyString: field => 'Ce champ ne doit contenir que des lettres',
      beforeOrToday: field => 'La date ne peut pas dépasser la date du jour',
      afterToday: field => 'La date doit etre ultérieur à la date du jour',
      valideBirthdate: field => "L'age est compris entre 0 et 150 ans",
      length: (field, { length, max }) => {
        if (max) {
          return `Ce champs doit avoir entre ${length} et ${max} caratères`
        }
        return `Ce champs doit avoir ${length} caratères`
      },
      between: (field, { min, max }) => `Le valeur est comprise entre ${min} et ${max}`,
    },
  },
}
VeeValidate.localize('fr', dictionary.fr)

// custom rules VeeValidate -----------------------------------
VeeValidate.extend('strictlyNumber', {
  // getMessage: field => 'Ce champs ne doit contenir que des nombres',
  validate: value => Number.isInteger(value),
})
VeeValidate.extend('strictlyString', {
  validate: value => !/\d/.test(value),
})
VeeValidate.extend('beforeOrToday', {
  validate: value => {
    const myMomentDate = moment(value, 'YYYY-MM-DD')
    const myMomentToday = moment()
    return !myMomentDate.isAfter(myMomentToday)
  },
})
VeeValidate.extend('afterToday', {
  validate: value => {
    const myMomentDate = moment(value, 'YYYY-MM-DD')
    const myMomentToday = moment()
    return myMomentDate.isAfter(myMomentToday, 'day')
  },
})
VeeValidate.extend('valideBirthdate', {
  validate: value => {
    const myMomentDate = moment(value, 'YYYY-MM-DD')
    const myMomentToday = moment()
    const duration = moment.duration(myMomentToday.diff(myMomentDate))
    const years = duration.asYears()
    if (years < 0 || years > 150) return false
    return true
  },
})
VeeValidate.extend('date_between', {
  computesRequired: true,
  validate: (value, params) => {
    const [start, end, strictCheck] = params
    value = new Date(value).getTime()
    if (start && end) {
      return (
        new Date(start).getTime() < value && value < new Date(end).getTime()
      )
    }
    if (start) {
      if (!strictCheck) {
        return new Date(start).getTime() <= value
      }
      return new Date(start).getTime() < value
    }
    if (end) {
      if (!strictCheck) {
        return value <= new Date(end).getTime()
      }
      return value < new Date(end).getTime()
    }
  },
  message: (field, params) => {
    const { 0: start, 1: end } = params
    const toLocaleDateString = date => new Date(date).toLocaleDateString({
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })
    if (start && end) {
      return `La date doit être comprise entre ${toLocaleDateString(
        start,
      )} et ${toLocaleDateString(end)}`
    }
    if (start) {
      return `La date doit être ultérieur au ${toLocaleDateString(start)}`
    }
    if (end) {
      return `La date ne doit pas dépasser le ${toLocaleDateString(end)}`
    }
  },
})
// ------------------------------------------------------------

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

const mixins = {
  // setup() {
  //   const requiredStoreModules = [
  //     { path: 'users', module: usersStoreModule },
  //   ]
  //   registerStoreModule(requiredStoreModules) // Register module
  //   return { requiredStoreModules }
  // },
  components: {
    ToastificationContent,
  },
  data() {
    return {
      todayDate: new Date().getTime(),
      $showModalControl: {
        modalPayment: false,
      },
    }
  },
  methods: {
    // ...mapActions('users', {
    //   action_searchUsers: 'searchUsers',
    // }),
    // ------------------------------------------------------
    copyToClipboard(text) {
      const el = document.createElement('textarea')
      el.value = text
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Élément copié : ${text}`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    donwload_file_by_url(url) {
      console.log('donwload_file_by_url url::: ', url);
      // Vérifie si l'URL commence par http:// ou https://
      if (!/^https?:\/\//i.test(url)) {
          url = 'http://' + url; // Ajoute http:// si absent
      }
      console.log('donwload_file_by_url url::: ', url);
      const a = document.createElement('a');
      a.href = url;
      a.download = ''; // Laisser vide pour utiliser le nom de fichier par défaut de l'URL

      // Ajoute l'élément <a> au DOM
      document.body.appendChild(a)

      // Déclenche un clic programmatique sur l'élément <a>
      a.click()

      // Supprime l'élément <a> du DOM
      document.body.removeChild(a)
    },
    get_item_by_id(list, object_id) {
      // const params = {
      //   roles: ['TA', 'TAD', 'AUAT', 'UAT', 'AUD'],
      // }
      // this.action_searchUsers(params)
      //   .then(async response => {
      //     if (response.status === 200) {
      //       response.data.data.forEach(user => {
      //         user.label = `${user.lastname} ${user.firstname}, ${user.email}`
      //       })
      //       // return response.data.data
      //       for (let index = 0; index < response.data.length; index++) {
      //         const user = response.data[index]
      //         if (user.id === user_id) {
      //           user.fullname = `${user.lastname} ${user.firstname}, ${user.email}`
      //           return user
      //         }
      //       }
      //     }
      //   })
      //   .catch(err => {
      //     console.log('err::: ', err)
      //   })
    },
    whichTypeOfBlog(str) {
      if (str === 'rules') return 'Règlementations'
      if (str === 'stats') return 'Chiffres et tendances'
      if (str === 'concours') return 'Concours'
      if (str === 'blog') return 'Actualités'
      if (str === 'investors') return 'Investisseurs'
    },
    currentDate() {
      // Récupérer la date courante
      const today = new Date()
      // Formater la date
      const formattedDate = today.toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
      return formattedDate
    },
    convertDateToLocalString(date) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }
      let { locale } = i18n
      locale += `-${locale.toUpperCase()}`
      return new Date(date).toLocaleDateString(locale, options)
    },
    reverseDate(date, separtor) {
      if (date) {
        const array = date.split(separtor || '-')
        array.reverse()
        return array.join(separtor || '-')
      }
      return null
    },
    replaceSlashByDash(date) {
      const array = date.split('/')
      return array.join('-')
    },
    replaceDashBySlash(date) {
      const array = date.split('-')
      return array.join('/')
    },
    formatCurrency(value) {
      // Create our number formatter.
      const formatter = new Intl.NumberFormat(i18n.locale, {
        style: 'currency',
        currency: 'XOF',
        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      })
      return formatter.format(value)
    },
    customCloneDeep(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    normalizeNumericValue(val) {
      if (!val) return 0
      if (Number.isNaN(parseInt(val))) return 0
      return Number.isNaN(val) ? 0 : val
    },
    removeinvalidMonth(monthList) {
      const currentMonth = moment().month()
      const newList = []
      monthList.forEach((item, index) => {
        if (index <= currentMonth) {
          newList.push(item)
        }
      })
      return newList
    },
    async logout() {
      await this.$store.dispatch('auth/logout', {}).then(res => {
        this.$ability.update(initialAbility)

        // this.$router.replace({ name: "home-public" });
        this.$router.replace({ name: 'auth-login' })
        // setTimeout(() => {
        //   this.$router.go(); // reload page
        // }, 1000);
      })
    },
    parseConfig(config) {
      const config_type = config.type
      switch (config_type) {
        case 'array':
          if (typeof config.value === 'string') {
            config.value = config.value.split(',')
          } else config.value
          break
        case 'boolean':
          config.value === '1' ? (config.value = true) : (config.value = false)
          break
        case 'number':
          config.value == parseInt(config.value)
          break
        case 'object':
          config.value == JSON.parse(config.value)
          break
        default:
          break
      }
      /**
       * ? IF NONE OF THE CONDITION IS VALID THEN the config.type is a STRING
       */
      return config
    },
    goBack() {
      router.go(-1)
    },
    statusMatching(process_steps, code) {
      if (!process_steps || !code) return
      let stat = null // Declare stat outside the map function
      for (let index = 0; index < process_steps.length; index++) {
        const status = process_steps[index]
        if (status.status === code) {
          stat = status.etape
          // return true; // Stop the iteration once a matching status is found
          break
        }
      }
      return stat
    },
    get_current_step(steps, curr_step_name, reverse) {
      if (!steps || !curr_step_name) return
      let new_steps = JSON.parse(JSON.stringify(steps))
      if (reverse) new_steps = new_steps.reverse()
      for (let index = 0; index < new_steps.length; index++) {
        if (new_steps[index].status === curr_step_name) {
          return new_steps[index]
        }
      }
    },
    get_next_nodes(steps, current_state) {
      let next_state = null
      let curr_decisions = []
      // next is'nt decisions nodes
      if (!current_state.next_decisions_options_count) {
        const step = steps.filter(
          obj => obj.status === current_state.next_step,
        )
        next_state = step[0]
        return next_state
      }
      // next is decisions nodes
      curr_decisions = []
      const { next_decisions_options_count } = current_state
      const index = steps.findIndex(
        object => object.status === current_state.status,
      )
      // load decisions
      let next_index = index
      for (let index = 0; index < next_decisions_options_count; index++) {
        next_index += 1
        curr_decisions.push(steps[next_index])
      }
      return curr_decisions
    },
    get_specific_nodes(steps, attribut, base_status, offset) {
      let index = steps.findIndex(
        object => object[attribut] === base_status,
      )
      if (index === -1) return -1
      if (offset) index -= offset
      if (index === -1) return -1
      return steps[index]
    },
    find_correct_form(data, id_type_hebergement) {
      const form = data.formulaires.filter(form => {
        if (form.type_etablissement_id === parseInt(id_type_hebergement)) {
          return form
        }
      })
      if (form && form[0]) return form[0]
    },
    findMoreBigId(tableau) {
      if (tableau.length === 0) {
        // Si le tableau est vide, retournez une valeur par défaut, par exemple -1.
        return -1
      }

      // Utilisez la méthode reduce pour obtenir la plus grande valeur de l'attribut "id".
      const plusGrandId = tableau.reduce(
        (max, element) => (element.id > max ? element.id : max),
        tableau[0].id,
      ) // Initialisez la valeur maximale avec le premier élément.

      return plusGrandId
    },
    time_spent_in_hours(startDateTime) {
      const date = this.reverseDate(startDateTime.split(' ')[0])
      const time = startDateTime.split(' ')[1]
      const startDate = this.$dayjs(`${date}T${time}`)
      const hoursPassed = this.$dayjs().diff(startDate, 'hour')
      return hoursPassed
    },
    time_spent_in_days(startDateTime) {
      const date = this.reverseDate(startDateTime.split(' ')[0])
      const time = startDateTime.split(' ')[1]
      const startDate = this.$dayjs(`${date}T${time}`)
      const currentDate = dayjs() // Date actuelle
      const daysPassed = currentDate.diff(startDate, 'day') // Calcule la différence en jours
      return daysPassed
    },
    calculate_passed_time_in_hours(startDateTime) {
      const date = this.reverseDate(startDateTime.split(' ')[0])
      const time = startDateTime.split(' ')[1]
      const startDate = this.$dayjs(`${date}T${time}`)
      const hoursPassed = this.$dayjs().diff(startDate, 'hour')
      return hoursPassed
    },
    calculate_passed_time_in_days(startDateTime) {
      if (startDateTime) {
        const date = this.reverseDate(startDateTime.split(' ')[0])
        const time = startDateTime.split(' ')[1]
        const startDate = this.$dayjs(`${date}T${time}`)
        const daysPassed = this.$dayjs().diff(startDate, 'day')
        return daysPassed
      } return null
    },
    // requests control methods ------------
    checkAllTrue(obj) {
      for (const key in obj) {
        if (!obj[key]) {
          return false
        }
      }
      return true
    },
    countAttributes(obj) {
      return Object.keys(obj).length
    },
    countTrueAttributes(obj) {
      let fillCount = 0
      let totalCount = 0

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          totalCount++
          if (obj[key] !== null) {
            fillCount++
          }
          if (obj[key] === false) {
            fillCount--
            console.log('fillCount:', fillCount)
          }
        }
      }
      return { fillCount, totalCount }
    },
    removecheck_object_attributes(obj) {
      const attributesToRemove = [
        'created_at',
        'status',
        'reference_paiement',
        'reference',
        'numero_demande',
        'montant',
        'attachments_url',
        'date_paiement',
        'mode_paiement',
        'date_demande',
        'identity',
        'code_demande',

        'adresse',
        'commune',
        'demande',
        'departement',
        'email',
        // 'files',
        'nom_promoteur',
        'prenom_promoteur',
        'raison_sociale',
        'rccm',
        'site_web',
        'telephone',
        'null',
      ]
      const newObj = { ...obj } // Crée une copie de l'objet d'origine
      attributesToRemove.forEach(attribute => {
        if (newObj.hasOwnProperty(attribute)) {
          delete newObj[attribute] // Supprime l'attribut spécifié s'il existe dans l'objet
        }
      })
      return newObj
    },
    extractAttributes(obj, attributeList) {
      const extractedObj = {}
      attributeList.forEach(attr => {
        if (obj.hasOwnProperty(attr)) {
          extractedObj[attr] = obj[attr]
        }
      })
      return extractedObj
    },
    // ------------------------------------
    should_have_auto_eval_control(code) {
      const types_code_list = [
        'UEH_autorisation_premier_classement',
        'UEH_reclassement',
      ]
      let status = false
      types_code_list.forEach(type_code => {
        if (type_code === code) status = true
      })
      return status
    },
    user_can_update_request(statuses) {
      const userData = localstorageService.getUserData()
      const user_connected_id = userData.id
      if (statuses.length > 1) {
        const item = statuses.filter(item => item.name === 'treatment_start')
        if (item[0] && item[0].user_id) {
          const status_auteur_id = item[0].user_id
          if (user_connected_id == status_auteur_id) {
            return true
          }
        } else {
          return false
        }
      }
      return false
    },
    can_set_ministerial_decree_number(cur_step) {
      if (cur_step === 'acknowledgement_of_receipt_minister_decisions') return true
      return false
    },
    is_user_connected(user_id) {
      const userData = localstorageService.getUserData()
      const { id } = userData
      return user_id === id
    },
    can_process_to_treatment(
      request_have_audit,
      should_have_auto_eval,
      request_have_auto_eval,
    ) {
      // return true;
      if (should_have_auto_eval) {
        if (request_have_audit && request_have_auto_eval) {
          return true
        }
        return false
      }
      return false
    },
    isValidSignatureFile(file) {
      if (file && file.size && file.size > 5000000) {
        return false
      }
      return true
    },
    // ------------------------------------ file functions
    async downloadFileAndConvertToFileObject(url) {
      if (url) {
        try {
          const response = await fetch(url)
          const blob = await response.blob()
          const filename = this.extractFilenameFromURL(url)
          return new File([blob], filename, { type: blob.type })
        } catch (error) {
          console.error(
            "Une erreur s'est produite lors du téléchargement du fichier :",
            error,
          )
          return null
        }
      } else {
        return null
      }
    },
    extractFilenameFromURL(url) {
      const array = url.split('/')
      return array[array.length - 1]
      // Implémentez la logique pour extraire le nom du fichier de l'URL
      // Par exemple, en utilisant des expressions régulières ou des méthodes de manipulation d'URL
      // Retournez le nom du fichier extrait
    },
    async convertReadableStreamToBlob(readableStream) {
      if (readableStream.locked) {
        console.error(
          'Le ReadableStream est déjà verrouillé par un autre lecteur.',
        )
        return null
      }
      const reader = readableStream.getReader()
      const { value, done } = await reader.read()
      if (done) {
        return new Blob()
      }
      return new Blob([value])
    },
    getMatchingPercentage(str1, str2) {
      // Convertissez les chaînes de caractères en tableaux de caractères
      const chars1 = str1.split('')
      const chars2 = str2.split('')

      // Calcul du nombre de caractères correspondants
      let matchingCharacters = 0
      for (let i = 0; i < chars1.length; i++) {
        if (chars1[i] === chars2[i]) {
          matchingCharacters++
        }
      }
      // Calcul du pourcentage de matching
      const pourcent = (matchingCharacters / chars1.length) * 100
      return {
        pourcent,
        result: pourcent > 80,
        same_length: Math.abs(chars1.length - chars2.length),
      }
    },

    // Utilisation de la fonction pour télécharger le fichier et obtenir un objet de type File
  },
  computed: {},
}

Vue.mixin(mixins)
// custom rules VeeValidate -----------------------------------
// create custom durective for stats tables exports
Vue.directive('append-export-button', {
  bind(el, binding, vnode) {
    const onClickOnExportButtpn = data => {
      const computedWatchers = vnode.context._computedWatchers
      const parentData = vnode.context._data

      const tableColumns = vnode.context.tableColumns || []
      const tableData = (computedWatchers.getTableData || { value: [] }).value
      const columnsKeys = tableColumns.map(column => column.key)
      const columnsLabels = tableColumns.map(column => column.label)
      const AOA = []
      tableData.map(e => {
        const row = []
        columnsKeys.forEach((k, i) => {
          row[i] = e[k]
        })
        AOA.push(row)
      })

      AOA.unshift(columnsLabels)
      exportArrayAsCsv(AOA, 'export')
    }
    const exportComponent = document.createElement('div')
    const exportButton = document.createElement('button')
    exportButton.type = 'button'
    exportButton.setAttribute('class', 'btn float-right btn-primary')
    exportButton.innerText = 'Exporter en csv'
    exportButton.addEventListener('click', e => {
      onClickOnExportButtpn(e)
    })

    exportComponent.appendChild(exportButton)

    el.style.display = 'inline-block'
    el.style.width = '100%'
    el.style.textAlign = 'left'
    el.insertBefore(exportComponent, el.firstChild)
  },
})
// directive for number input field
Vue.directive('digits-only', {
  bind(el, binding, vnode) {
    const { length } = binding.value || {}
    const isNumeric = function (inputVal) {
      return /^[0-9]+$/.test(inputVal)
    }
    el.addEventListener('keydown', e => {
      const allowKey = key => [
        'Backspace',
        'Enter',
        'Shift',
        'Tab',
        'ArrowLeft',
        'ArrowRight',
      ].includes(key)
      if (!allowKey(e.key) && !isNumeric(e.key)) {
        e.preventDefault()
      }
      if (!allowKey(e.key) && length && e.target.value.length >= length) {
        e.preventDefault()
      }
    })
    el.addEventListener('keyup', e => {
      e.target.value = `${e.target.value}`
    })
  },
  update(el, binding, vnode, prevVnode) { },
})
// directive for phone number input field
Vue.directive('phonenumber-field', {
  bind(el, binding, vnode) {
    const { length } = binding.value || {}
    const isNumeric = function (inputVal) {
      return /^[\\(\\)\\+0-9]+$/.test(inputVal)
    }
    el.addEventListener('keydown', e => {
      const allowKey = key => [
        'Backspace',
        'Enter',
        'Shift',
        'Tab',
        'ArrowLeft',
        'ArrowRight',
      ].includes(key)
      if (!allowKey(e.key) && !isNumeric(e.key)) {
        e.preventDefault()
      }
      if (!allowKey(e.key) && length && e.target.value.length >= length) {
        e.preventDefault()
      }
    })
    el.addEventListener('keyup', e => {
      e.target.value = `${e.target.value}`
    })
  },
  update(el, binding, vnode, prevVnode) { },
})
// Vue.directive("rccm-field", {
//   bind(el, binding, vnode) {
//     const { length } = binding.value || {};
//     const isAlphanumericSpecial = function (inputVal) {
//       // return /^[a-zA-Z0-9\/-]+$/.test(inputVal);
//       return /^[A-Z0-9\/-]+$/.test(inputVal);
//     };

//     el.addEventListener("keydown", (e) => {
//       const allowKey = (key) =>
//         [
//           "Backspace",
//           "Enter",
//           "Shift",
//           "Tab",
//           "ArrowLeft",
//           "ArrowRight",
//         ].includes(key);

//       if (!allowKey(e.key) && !isAlphanumericSpecial(e.key)) {
//         e.preventDefault();
//       }

//       if (!allowKey(e.key) && length && e.target.value.length >= length) {
//         e.preventDefault();
//       }
//     });

//     el.addEventListener("keyup", (e) => {
//       e.target.value = `${e.target.value}`;
//     });
//   },
//   update(el, binding, vnode, prevVnode) {},
// });
Vue.directive('rccm-field', {
  bind(el, binding, vnode) {
    const isRccmValid = function (inputVal) {
      // return /^[A-Z]{2}\/[A-Z]{3}\/[\w\s]+$/.test(inputVal);
      // return /^[A-Z]{2}\/[A-Z]{3}\/[A-Za-z0-9\s]+$/.test(inputVal);
      return /^[A-Z]{2}\/[A-Z]{3}\/[A-Z0-9\s]+$/.test(inputVal)
    }

    el.addEventListener('input', e => {
      const inputValue = e.target.value
      if (!isRccmValid(inputValue)) {
        e.target.setCustomValidity("Le format du RCCM n'est pas valide")
      } else {
        e.target.setCustomValidity('')
      }
    })
  },
  update(el, binding, vnode, prevVnode) { },
})
// ------------------------------------------------------------

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
