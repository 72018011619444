export default [
  // auto - evaluations
  {
    path: '/auto-evaluation/list',
    name: 'auto-evaluation-list',
    component: () => import('@/views/pages/auto_evaluations/Index.vue'),
    meta: {
      resource: 'auto_evaluation',
      action: 'list',
    },
  },
  // Passer/Repasser d'une evaluation
  {
    path: '/auto-evaluation/:id',
    name: 'evaluation-procedure',
    component: () => import('@/views/pages/auto_evaluations/Details.vue'),
    meta: {
      resource: 'auto_evaluation',
      action: 'list',
    },
  },

  // evaluations
  {
    path: '/evaluations/list',
    name: 'evaluations-list',
    component: () => import('@/views/pages/evaluations/Index.vue'),
    meta: {
      resource: 'evaluation',
      action: 'list',
    },
  },
];
