import { BaseService } from './base.service';

export class ParamsService extends BaseService {
  constructor() {
    super();
  }

  fetchIndicators(params) {
    // console.log('+++++++++ ++++ ====== +++ domaine, id: ', params.domaine, params.id)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/statistics/public/' + params.domaine, {
          filter: params.filterData,
          id: params.id,
          codeTypeEtab: params.codeTypeEtab ? params.codeTypeEtab : null,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  fetchRoles() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/api/v1/roles/')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fetchCategorieClient() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/api/v1/categorie-clients/')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fetchModeReglementss() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/api/v1/mode-reglements/')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fetchModeFacturations() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/api/v1/mode-facturations/')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fetchOrigineClients() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/api/v1/origine-clients/')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fetchCategorieFornisseurs() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/api/v1/categorie-fournisseurs/')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fetchEquipeCommerciales() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/api/v1/equipe-commerciales/')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fetchRegleCommissionnements() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/api/v1/regle-commissionnements/')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fetchTypeCoordonnees() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/api/v1/type-coordonnees/')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fetchPhoneNetworks() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/api/v1/phone-networks/')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fetchPays() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/api/v1/pays/')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fetchDevises() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/api/v1/devises/')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fetchCommercials() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/api/v1/commercials/')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fetchFormeJuridiques() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/api/v1/forme-juridiques/')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fetchTypesRoles() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/roles/list')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  sendMailNousContacter(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/mails', data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  checkIfTokenValid() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/check-token')
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default new ParamsService();
