import rolesService from '@/services/http/roles.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchRoles({ rootState }, data) {
      const res = await rolesService.fetchRoles(data)
      return res
    },
    async addRoles({ rootState }, data) {
      const res = await rolesService.addRoles(data)
      return res
    },
    async getRoles({ rootState }, data) {
      const res = await rolesService.getRoles(data)
      return res
    },
    async searchRolePermissions({ rootState }, data) {
      const res = await rolesService.searchRolePermissions(data)
      return res
    },
  },
}
