import { BaseService } from "./base.service";

export class UserService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  fetchUsers(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get("/users", { params: data })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  fetchAgentsEtab(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get("/inscriptions/list-agents", { params: data })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  fetchCommissariats(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get("/admins/list-commissariats", { params: data })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  fetchAgentsPolice(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get("/admins/list-agents-police", { params: data })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  fetchUser({ id }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/api/v1/users/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  addUser(userData) {
    console.log("----- userData: ------ >    ", userData);
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/users", userData)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  updateUser({ id, data }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/users/${id}`, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  updateUserFormData({ id, data }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/users/${id}?_method=PUT`, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  resetPasswordUser(userData) {
    console.log("--- userData: --- ", userData);
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/forgot-password", userData)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  archiveUser(data) {
    const { id } = data;
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`/users/${id}`, {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  searchUsers(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/users/search", data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  exportUsers(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/users/search", data, {responseType: 'blob'})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  getUser(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/users/${id}`, {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}

export default new UserService();
