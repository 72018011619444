<template>
  <!-- <b-card
    border-variant="warning"
    header-bg-variant="transparent"
    align="center"
    class="cursor-pointer"
    @click="openPaymentWidget"
  > -->
  <div>
    <b-img
      thumbnail
      rounded="circle"
      :src="require('@/assets/images/logo/kkiapay.jpeg')"
      height="100"
      width="100"
      alt=""
    />
  </div>

  <!-- <b-card-text>KKIAPAY</b-card-text> -->
  <!-- </b-img></b-card> -->
</template>

<script>
import {
  BButton, BCard,
  BCardText,
  BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BCard,
    BImg,
    BCardText,
    ToastificationContent,
  },
  props: {
    initialsDatas: {
      type: Object,
      default: () => ({
        amount: 0,
        public_key: null,
      }),
    },
    startPayment: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submitted: false,
      isLoading: false,
      paymentIsSuccess: false,
      paymentInfos: {},
      paymentResponse: {},
    }
  },
  watch: {
    initialsDatas: {
      deep: true,
      handler(val, old) {
        // this.paymentInfos = val
        // console.log('val :>> ', val)
      },
    },
    startPayment: {
      deep: true,
      handler(val, old) {
        // if (val === true) this.openPaymentWidget()
      },
    },
  },

  mounted() {
    window.addEventListener('message', evt => {
      // console.log('evt :>> ', evt)
    })
  },
  methods: {
    openPaymentWidget() {
      console.log('this.kkiaPayPaymentInfos :>> ', this.initialsDatas)
      let { amount } = this.initialsDatas
      const { public_key, phone, email, name } = this.initialsDatas
      amount = parseInt(amount, 10)
      if (Number.isNaN(amount) || amount === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Le montant doit être supérieur à 0',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      window.openKkiapayWidget({
        amount,
        position: 'center',
        callback: '',
        data: {},
        theme: 'green',
        key: public_key,
        sandbox: true,
        phone,
        email,
        name,
      })

      window.addSuccessListener(response => {
        console.log('addSuccessListener response::: ', response);
        // if (this.paymentIsSuccess) return
        // this.paymentIsSuccess = true
        // this.$emit('onPaymentSuccess', response)
      })

      window.addEventListener('message', event => {
        const { origin, isTrusted } = event
        const { name, data: paymentResponse } = event.data
        const kkiaEvents = {
          PAYMENT_INIT: 'PAYMENT_INIT',
          PENDING_PAYMENT: 'PENDING_PAYMENT',
          ON_USER_FEEDBACK: 'ON_USER_FEEDBACK',
          PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
          PAYMENT_END: 'PAYMENT_END',
          WIDGET_SUCCESSFULLY_DESTROYED: 'WIDGET_SUCCESSFULLY_DESTROYED',
          CLOSE_WIDGET: 'CLOSE_WIDGET',
        }

        const kkia_widget_origin = 'https://widget-v3.kkiapay.me'

        const isSuccess = isTrusted && name === kkiaEvents.PAYMENT_SUCCESS
        console.log('isSuccess::: ', isSuccess)

        if (isSuccess) {
          if (this.paymentIsSuccess) return
          this.paymentIsSuccess = true

          if (this.isLoading) return
          this.submitted = true
          this.isLoading = false
          this.paymentResponse = paymentResponse
          this.$emit('onPaymentSuccess', paymentResponse)
        }

        const isClosed = name === kkiaEvents.PAYMENT_END || name === kkiaEvents.WIDGET_SUCCESSFULLY_DESTROYED || name === kkiaEvents.CLOSE_WIDGET
        if (isClosed) {
          this.$emit('onClosed', { ...this.paymentResponse, isSuccess: this.paymentIsSuccess })
          if (this.submitted || this.isLoading) {
            this.isLoading = false
            this.submitted = false
          }
        }
        // console.log(`Message reçu: ${event.data.name}`, event)
      })

      // this.showPaymentSuccessModal = true;
    },
  },
}
</script>

<style>

</style>
