import { BaseService } from './base.service'

export class PlaintesService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super()
  }

  searchPlainte(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/complaints/search', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  createPlainte(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/complaints', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  treatePlainte(object) {
    const { complaint, data } = object
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/complaints/treat/${complaint}`, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchPlaintes(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/complaints', { data })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getPlainte(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/complaints/${id}`, {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updatePlainte(object) {
    const { id, data } = object
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/complaints/${id}`, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  deletePlainte(id) {
    // console.log('id :>> ', id)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`/complaints/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new PlaintesService()
