import permissionsService from '@/services/http/permissions.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchPermissions({ rootState }, data) {
      const res = await permissionsService.fetchPermissions(data)
      return res
    },
    async searchPermissions({ rootState }, data) {
      const res = await permissionsService.searchPermissions(data)
      return res
    },
    async addPermissions({ rootState }, data) {
      const res = await permissionsService.addPermissions(data)
      return res
    },
    async updatePermissionStatus({ rootState }, data) {
      const res = await permissionsService.updatePermissionStatus(data)
      return res
    },
  },
}
