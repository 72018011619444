import statusService from '@/services/http/status.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchStatus({ rootState }, data) {
      const res = await statusService.fetchStatus(data)
      return res
    },
  },
}
