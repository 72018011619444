<template>
  <div>
    <div
      id="app"
      class="screenHeight"
      :class="[skinClasses]"
    >
      <vue-progress-bar />
      <component :is="layout">
        <router-view />
      </component>

      <!-- <buy-now /> -->
      <scroll-to-top v-if="enableScrollToTop" />
    </div>
    <!-- <ColorBandGouvBj v-if="showGouvColorBand" /> -->

    <!-- Auto logout modal -->
    <b-modal
      :ref="autoLogoutWarningModalRef"
      content-class="shadow"
      :title="$t('warning')"
      centered
      ok-only
      modal-class="modal-primary"
      ok-title="OK"
    >
      <span>{{ $t('disconnect.warning') }}</span>
    </b-modal>
    <b-modal
      :ref="autoLogoutNotificationModalRef"
      content-class="shadow"
      :title="$t('disconnection')"
      centered
      ok-only
      modal-class="modal-primary"
      ok-title="OK"
    >
      <span>{{  $t('disconnect.alert') }}</span>
    </b-modal>
    <b-modal
      :ref="callToRegisterModalRef"
      content-class="shadow"
      title="Inscription"
      hide-footer
      ok-only
      modal-class="modal-primary"
      @hide="hideCallToRegisterModal"
      @close="hideCallToRegisterModal"
    >
      <RegisterDefault
        skip-internal-processes-after-registration-request-response
        :input-datas="callToRegister && callToRegister.data"
      />
    </b-modal>

    <!-- Slidercaptcha -->
    <ImagePuzzleSliderCaptcha v-if="getter_showSliderCaptcha" />

    <!-- Floatting icon for SVA -->
    <div>
      <div
        :v-b-tooltip.hover.top="$t('contact')"
        href="#"
        class="float"
        @click="$router.push({ name: 'contacts' })"
      >
        <i class="las la-comment-alt my-float iconBig" />
      </div>
    </div>

    <!-- PaymentModal -->
    <b-modal
      :ref="paymentModalRef"
      no-close-on-backdrop
      hide-footer
      centered
      @close="
        () => {
          clickClosePaymentModal();
        }
      "
    >
      <PaymentWidget
        :initials-datas="getter_paymentInitialsDatas"
        @onPaymentComplete="clickClosePaymentModal()"
      />
    </b-modal>
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import { BModal, BButton, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { AbilityBuilder } from '@casl/ability'
import utilsService from '@/services/utils/utils.service'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import ColorBandGouvBj from '@/components/ColorBandGouvBj.vue'

import RegisterDefault from '@/views/pages/authentication/registration/RegisterDefault.vue'

import store from '@/store'

import { initialAbility } from '@/libs/acl/config'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import ImagePuzzleSliderCaptcha from '@/components/ImagePuzzleSliderCaptcha.vue'
import PaymentWidget from '@/components/PaymentWidget.vue'

import localstorageService from '@/services/localstorage/localstorage.service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import paramsStoreModule from '@/store/params'
import paymentsStroreModule from '@/store/payments'
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

const LayoutDefault = () => import('@/layouts/default/LayoutDefault.vue')
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const { appName, appLogoImage } = $themeConfig.app

const paymentModalRef = 'paymentModalRef'

export default {
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} | ${appName}` : appName
    },
  },
  components: {
    BModal,
    BButton,
    ImagePuzzleSliderCaptcha,
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    LayoutDefault,

    ScrollToTop,
    // BuyNow,
    ColorBandGouvBj,
    RegisterDefault,
    PaymentWidget,
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.activityChecker()
        this.listenToNavBarHeightChange()
        if (this.callToRegister === true) {
          this.hideCallToRegisterModal()
        }
        // this.checkTokenValidity();
      },
    },
    isAutoLoggedOut: {
      handler(val, old) {
        this.hideAutoLogoutWarningModal()
        this.showAutoLogoutNotificationModal()
      },
    },
    callToRegister: {
      immediate: true,
      deep: true,
      handler(val, old) {},
    },
    getter_showPaymentModal: {
      immediate: false,
      deep: false,
      handler(val, old) {
        if (val === true) {
          this.showPaymentModal()
        } else {
          this.hidePaymentModal()
        }
      },
    },
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters('payments', {
      getter_showPaymentModal: 'getter_show',
      getter_paymentInitialsDatas: 'getter_paymentInitialsDatas',
      getter_isCompleted: 'getter_isCompleted',
    }),
    ...mapGetters('auth', {
      isAuthenticated: 'getter_isAuthenticated',
      isAutoLoggedOut: 'getter_isAutoLoggedOut',
      callToRegister: 'getter_callToRegister',
    }),
    ...mapGetters('imagePuzzleSliderCaptcha', {
      getter_showSliderCaptcha: 'getter_show',
    }),
    // --------------------------------------------------------------------------------
    layout() {
      if (this.$route.meta.layout === 'default') return 'layout-default'
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return 'layout-default'
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    showGouvColorBand() {
      const currentRouteName = this.$router.currentRoute.name
      const currentRoutePath = this.$router.currentRoute.path
      const whitelistByName = ['auth-login']
      const whitelistByPath = ['auth-login']
      const isDefaultLayout = this.layout === 'layout-default'
      const canShow = whitelistByName.includes(currentRouteName)
        || whitelistByPath.includes(currentRoutePath)
        || isDefaultLayout
      return !canShow
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement,
      ).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement,
        ).value.slice(0, -2),
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: false,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    const requiredStoreModules = [
      { path: 'params', module: paramsStoreModule },
      { path: 'payments', module: paymentsStroreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return {
      skinClasses,
      enableScrollToTop,

      requiredStoreModules,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      autoLogoutWarningModalRef: 'auto-logout-warning-modal',
      autoLogoutNotificationModalRef: 'auto-logout-notification-modal',
      callToRegisterModalRef: 'call-to-register-modal',

      showAlertTokenExpired: true,
      paymentModalRef,
    }
  },
  beforeDestroy() {
    const eventsToWatch = ['mousemove', 'click', 'scroll', 'keypress']
    eventsToWatch.forEach(item => {
      document.removeEventListener(item, e => {})
    })
    window.removeEventListener('resize', e => {})
  },
  mounted() {
    if (!localStorage.getItem('local_language')) localStorage.setItem('local_language', 'fr')
    this.reloadUserAbilities()
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish()
  },
  created() {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }
      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from) => {
      //  finish the progress bar
      this.$Progress.finish()
    })
  },
  methods: {
    ...mapMutations('payments', {
      setter_showPaymentModal: 'setter_show',
    }),
    ...mapMutations('auth', {
      callUserToRegister: 'setter_callToRegister',
    }),
    ...mapActions('params', {
      action_checkIfTokenValid: 'checkIfTokenValid',
    }),
    // ---------------------------------------------------------
    showAutoLogoutWarningModal() {
      this.$refs[this.autoLogoutWarningModalRef].show()
    },
    hideAutoLogoutWarningModal() {
      this.$refs[this.autoLogoutWarningModalRef].hide()
    },
    showAutoLogoutNotificationModal() {
      this.$refs[this.autoLogoutNotificationModalRef].show()
    },
    hideAutoLogoutNotificationModal() {
      this.$refs[this.autoLogoutNotificationModalRef].hide()
    },
    showCallToRegisterModal() {
      this.$refs[this.callToRegisterModalRef].show()
    },
    hideCallToRegisterModal() {
      try {
        this.$refs[this.callToRegisterModalRef].hide()
      } catch (error) {}
    },
    activityChecker() {
      if (!this.isAuthenticated) return
      const inactivityTimeOut = 30 * 60 * 1000
      let timer = 0
      let thereIsActivityOnThepage = false
      const eventsToWatch = ['mousemove', 'click', 'scroll', 'keypress']
      eventsToWatch.forEach(item => {
        document.addEventListener(item, e => {
          thereIsActivityOnThepage = true
          handleActivityTImer(true)
        })
      })

      const handleActivityTImer = reset => {
        if (!this.isAuthenticated) return
        if (reset) {
          timer = 0
          return
        }
        if (timer === inactivityTimeOut / 2) {
          this.showAutoLogoutWarningModal()
        }
        if (timer === inactivityTimeOut) {
          this.logout()
          return
        }
        setTimeout(() => {
          timer += 1000
          handleActivityTImer()
        }, 1000)
      }
      handleActivityTImer()
    },
    listenToNavBarHeightChange() {
      const resizer = () => {
        const navbarHtmlEl = document.querySelector(
          '.navbar-container.main-menu-content',
        )
        const appContentEls = document.querySelectorAll('.app-content.content')
        if (navbarHtmlEl && appContentEls) {
          const navBarHeight = navbarHtmlEl.getBoundingClientRect().height
          appContentEls.forEach(element => {
            element.style.paddingTop = `${73 + navBarHeight}px`
          })
        }
      }
      setTimeout(() => {
        resizer()
      }, 200)
      window.addEventListener('resize', e => {
        resizer()
      })
    },
    async logout() {
      await this.$store
        .dispatch('auth/logout', { autoLogout: true })
        .then(res => {
          this.$ability.update(initialAbility)
          // Redirect to login page
          this.$router.replace({ name: 'home-public' })
        })
        .catch(err => {
          this.$ability.update(initialAbility)
          this.$router.replace({ name: 'home-public' })
        })
    },
    reloadUserAbilities() {
      const { permissions } = utilsService.currentUserUtils()
      const { can, rules } = new AbilityBuilder()
      permissions.forEach(el => {
        can(el.action, el.subject)
      })
      this.$ability.update(rules)
    },
    checkTokenValidity() {
      if (localstorageService.getJwtToken()) {
        this.action_checkIfTokenValid()
          .then(response => {
            if (response.status === 401) {
            }
          })
          .catch(err => {
            if (err.statusCode === 401) {
              localStorage.clear()
              this.$router.replace({ name: 'auth-login' })

              if (this.showAlertTokenExpired) {
                this.showAlertTokenExpired = !this.showAlertTokenExpired
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title:
                      'Votre session a expiré. Veuillez vous connecter pour continuer',
                    icon: 'CheckIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
      }
    },
    // modals meethods ---------------------------------------
    showPaymentModal() {
      this.$refs[this.paymentModalRef].show()
    },
    hidePaymentModal() {
      this.$refs[this.paymentModalRef].hide()
    },
    clickClosePaymentModal() {
      this.setter_showPaymentModal(false)
    },
  },
}
</script>

<style lang="scss">
// @import '~@core/scss/base/bootstrap-extended/include';
// @import '~@core/scss/base/components/variables-dark';

.content-wrapper {
  min-height: calc(100vh - calc(72px) - 64px - calc(60.88px) - 23px);
}

.float {
  //margin: 0 0px 0 0;
  position: fixed;
  z-index: 100;
  width: 45px;
  height: 45px;
  bottom: 10px;
  right: 15px;
  background-color: #0e6158;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
}
.float:hover {
  background-color: #F2A356;
  color: #fff;
}

.my-float {
  margin: 15% 0 0 0;
}
.screenHeight {
  min-height: 100vh;
}
.added-padding {
  padding-top: 103.39px;
}
@media (min-width: 1200px) {
  // .added-padding {
  //   padding-top: 147.36px;
  // }
  .added-padding {
    padding-top: 145.36px;
  }
}
.added-padding-noauth {
  // padding-top: 14.39px;
  padding-top: 0.39px;
}
@media (min-width: 1200px) {
  .added-padding-noauth {
    padding-top: 57.36px;
  }
}
::v-deep .tableRes thead {
  display: none !important;
}
.small-margin {
  margin: 8px 4px;
}
.noclickable {
  user-select: none !important;
  pointer-events: none !important;
}
#modal-end-evaluation button.close {
  display: none;
}
#modal-is-downloading button.close {
  display: none;
}
// .autoeval-process {
// }
.autoeval-process .nav-tabs {
  padding: 1em 2em 1.5em 2em !important;
  background-color: #2094662c !important;
}
.bg-light-custom {
  background-color: #fff;
}
</style>
