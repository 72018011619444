import { BaseService } from './base.service'

export class RolesService extends BaseService {
  constructor() {
    super()
  }

  fetchRoles(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/roles', { data })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  addRoles(role) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/roles/create', role)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getRoles(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/roles/${id}`, {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  searchRolePermissions(params) {
    const { id, data } = params
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/roles/${id}/permissions`, {data: data, editables: true})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new RolesService()
