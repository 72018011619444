export default [
  // Liste des plaintes
  {
    path: '/appeal/list',
    name: 'appeal-list',
    component: () => import('@/views/pages/appeals/IndexUser.vue'),
    meta: {
      resource: 'appeal',
      action: 'list',
    },
  },
  {
    path: '/appeal/admin-list',
    name: 'appeal-admin-list',
    component: () => import('@/views/pages/appeals/index.vue'),
    meta: {
      resource: 'appeal',
      action: 'list',
    },
  },
  // Création d'une plainte
  {
    path: '/appeal/create',
    name: 'appeal-create',
    component: () => import('@/views/pages/appeals/create.vue'),
    meta: {
      resource: 'appeal',
      action: 'create',
    },
  },
  // Détails d'une plainte
  {
    path: '/appeal/details/:id?',
    name: 'appeal-details',
    component: () => import('@/views/pages/appeals/Details.vue'),
    meta: {
      resource: 'appeal',
      action: 'show',
    },
  },
  // Modification d'une plainte
  // {
  //   path: '/reclamation/update/:id?',
  //   name: 'plainte-update',
  //   component: () => import('@/views/pages/plainte/Update.vue'),
  //   meta: {
  //     resource: 'complaint',
  //     action: 'edit',
  //   },
  // },
];
