<template>

  <div>
    <!-- {{ initialsDatas }} -->
    <div class="d-flex align-items-center justify-content-center gap-10 flex-wrap">
      <div
        @click="setSelectedPortal(paymentPortalKey_fedapay)"
      >
        <FedaPayComponent
          :ref="paymentPortalKey_fedapay"
          class="payment-portal"
          :class="{'selected': isSeletedPortal(paymentPortalKey_fedapay)}"
          :start-payment="isSeletedPortal(paymentPortalKey_fedapay)"
          :initials-datas="fedaPayPaymentInfos"
          @onPaymentSuccess="onPaymentSuccess"
          @onClosed="onWidgetClosed"
        />
      </div>

      <div
        @click="setSelectedPortal(paymentPortalKey_kkiapay)"
      >
        <KkiaPayComponent
          :ref="paymentPortalKey_kkiapay"
          class="payment-portal"
          :class="{'selected': isSeletedPortal(paymentPortalKey_kkiapay)}"
          :start-payment="isSeletedPortal(paymentPortalKey_kkiapay)"
          :initials-datas="kkiaPayPaymentInfos"
          @onPaymentSuccess="onPaymentSuccess"
          @onClosed="onWidgetClosed"
        />
      </div>
    <!-- </div> -->

    </div>
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BCardBody, BFormInput, BFormGroup, BCardText,
  BLink, BButton,

  VBModal, VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import Ripple from 'vue-ripple-directive'
// import components
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// store modules and vuex utilities
// eslint-disable-next-line import/no-cycle
// import payslipsStroreModule from '@/store/payslips'

// eslint-disable-next-line import/no-cycle
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import FedaPayComponent from '@/components/FedaPayComponent.vue'
import KkiaPayComponent from '@/components/KkiaPayComponent.vue'

// eslint-disable-next-line camelcase
const paymentPortalKey_fedapay = 'fedapay'
// eslint-disable-next-line camelcase
const paymentPortalKey_kkiapay = 'kkiapay'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
    BFormInput,
    BFormGroup,
    BCardText,
    BLink,
    BButton,

    FedaPayComponent,
    KkiaPayComponent,

    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    initialsDatas: {
      type: Object,
      default: () => ({
        amount: 0,
        api_keys: {},
      }),
    },
  },
  setup() {
    const requiredStoreModules = [
      // { path: 'payslips', module: payslipsStroreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return {
      requiredStoreModules,
    }
  },

  data() {
    return {
      amount: 0,
      loading: false,
      selectedPortal: null,
      paymentPortalKey_fedapay,
      paymentPortalKey_kkiapay,
      paymentPortals: [
        {
          key: paymentPortalKey_fedapay,
          component: 'FedaPayComponent',
        },
        {
          key: paymentPortalKey_kkiapay,
          component: 'KkiaPayComponent',
        },
      ],
    }
  },
  computed: {
    fedaPayPaymentInfos() {
      const {
        fedapay_public_api_key,
      } = this.initialsDatas.api_keys
      const {
        amount, phone, email, lastname,
        firstname,
        fullname,
      } = this.initialsDatas
      return {
        amount,
        description: 'Description',
        public_key: fedapay_public_api_key,
        customer: {
          email,
          lastname,
          firstname,
          phone_number: {
            number: phone,
            country: 'bj',
          },
        },
      }
    },
    kkiaPayPaymentInfos() {
      const {
        kkiapay_public_api_key,
      } = this.initialsDatas.api_keys
      const {
        amount, phone, email, 
        lastname, firstname, fullname,
      } = this.initialsDatas
      return {
        amount,
        public_key: kkiapay_public_api_key,
        phone,
        email,
        name: fullname,
      }
    },
    // getPaymentInitialsDatas() {
    //   // console.log('this.selectedPortal :>> ', this.selectedPortal)
    //   switch (this.selectedPortal) {
    //     case this.paymentPortalKey_fedapay:
    //       return this.fedaPayPaymentInfos
    //     case this.paymentPortalKey_kkiapay:
    //       return this.kkiaPayPaymentInfos
    //     default:
    //       return {}
    //   }
    // },
    // canStartPayment(){
    //  return this.isSeletedPortal(key)
    // }

  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  mounted() {
    console.log('this.initialsDatas::: 🔵', this.initialsDatas)
  },
  methods: {
    ...mapActions('payments', {
      action_hidePaymentWidget: 'hide',
      action_onSuccess: 'onSuccess',
      action_onError: 'onError',

    }),
    ...mapMutations('payments', {
      setter_paymentIsComplete: 'onPaymentComplete',
    }),

    initPayement() {

    },
    onPaymentSuccess(success) {
      console.log('success :>> ', success)
      this.setter_paymentIsComplete({ ...success, isSuccess: true })
      this.$emit('onPaymentComplete', success)
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Paiement effectué avec succès',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    onWidgetClosed(closed) {
      console.log('closed_evt :>> ', closed)
      this.$emit('onPaymentComplete', { closed })
    },

    isSeletedPortal(portal_key) {
      return this.selectedPortal === portal_key
    },
    setSelectedPortal(portal_key) {
      this.selectedPortal = portal_key
      // console.log('this.getPaymentInitialsDatas() :>> ', this.getPaymentInitialsDatas);

      const getComponentRef = arr => (Array.isArray(arr) ? arr[0] : arr)

      const componentRef = getComponentRef(this.$refs[portal_key])

      if (!componentRef) return

      const initialisationFunc = 'openPaymentWidget'

      componentRef[initialisationFunc]()
    },

  },
}
</script>

  <style lang="scss" scoped>
  ::v-deep .payment-portal.selected{
    img{
      border: 3px solid black;
    }
  }
  </style>
