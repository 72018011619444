/**
 * Author:  Aristide Legba
 */

import store from "@/store";

/**
 * registerStoreModule
 * @param {*} input
 * * The 'input' params contains information about module to be registered.
 * ! It should be an Array of string (list of store modules )  Eg: [{ path: 'users', module: usersStoreModule },]
 *
 * @returns
 */
export const registerStoreModule = (input) => {
  [...input].forEach((item) => {
    const { path, module } = item;

    // * mount module only if it isnt mounted yet
    if (!store.hasModule(path)) {
      store.registerModule(path, module);
    }
  });
};

/**
 * unregisterStoreModule
 * @param {*} input
 * * The 'input' params contains information about module to be unmounted.
 * ! It should be an Array of string (list of store modules path) Eg: [{ path: 'users', module: usersStoreModule },]
 *
 * @returns
 */
const withlist = ["etablissements", "payments", "type-demandes", "formulaire"];
export const unregisterStoreModule = (input) => {
  [...input].forEach((item) => {
    const { path, module } = item;
    // * unmount only if module is already mounted
    if (store.hasModule(path) && !withlist.includes(path)) {
      store.unregisterModule(path);
    }
  });
};
