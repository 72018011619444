<template>
  <!-- password -->
  <b-form-group
    label-for="register-password"
    :label="passedLabel"
    class="password-field"
  >
    <b-input-group
      class="input-group-merge"
      :class="$v.form.password.$error ? 'is-invalid' : null"
    >
      <b-form-input
        id="register-password"
        v-model="form.password"
        class="form-control-merge"
        :type="passwordFieldType"
        :state="$v.form.password.$error ? false : null"
        name="register-password"
        placeholder="············"
        @focus="passwordFieldIsFocused = true"
        @blur="passwordFieldIsFocused = false"
      />
      <b-input-group-append is-text>
        <feather-icon
          :icon="passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'"
          class="cursor-pointer"
          @click="togglePasswordInputType"
        />
      </b-input-group-append>
    </b-input-group>
    <div
      v-if="passwordFieldIsFocused || $v.form.password.$error"
      class="invalid-feedback password-rules"
    >
      <!-- <small
                  v-if="$v.form.password.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small> -->
      <small v-if="passwordFieldIsFocused || $v.form.password.isValid.$invalid">
        <span class="label"> Le mot de passe doit contenir :</span>
        <ul class="list-style-none password-validators-errors">
          <li
            :class="{
              valid: passwordValidators(form.password).atLeastOneCapLetter,
            }"
          >
            <div class="icons">
              <i
                v-if="!passwordValidators(form.password).atLeastOneCapLetter"
                class="las la-times-circle"
              />
              <i
                v-if="passwordValidators(form.password).atLeastOneCapLetter"
                class="las la-check-circle"
              />
            </div>
            au moins une lettre majuscule
          </li>
          <li
            :class="{
              valid: passwordValidators(form.password).atLeastOneMinusLetter,
            }"
          >
            <div class="icons">
              <i
                v-if="!passwordValidators(form.password).atLeastOneMinusLetter"
                class="las la-times-circle"
              />
              <i
                v-if="passwordValidators(form.password).atLeastOneMinusLetter"
                class="las la-check-circle"
              />
            </div>
            au moins une lettre minuscule
          </li>
          <li
            :class="{
              valid: passwordValidators(form.password).atLeastOneDigits,
            }"
          >
            <div class="icons">
              <i
                v-if="!passwordValidators(form.password).atLeastOneDigits"
                class="las la-times-circle"
              />
              <i
                v-if="passwordValidators(form.password).atLeastOneDigits"
                class="las la-check-circle"
              />
            </div>
            au moins un chiffre
          </li>
          <li
            :class="{
              valid: passwordValidators(form.password).atLeastOneSpecialChar,
            }"
          >
            <div class="icons">
              <i
                v-if="!passwordValidators(form.password).atLeastOneSpecialChar"
                class="las la-times-circle"
              />
              <i
                v-if="passwordValidators(form.password).atLeastOneSpecialChar"
                class="las la-check-circle"
              />
            </div>
            au moins un charatere special (@#$%^&+=)
          </li>
          <li
            :class="{
              valid: passwordValidators(form.password).minimumLength,
            }"
          >
            <div class="icons">
              <i
                v-if="!passwordValidators(form.password).minimumLength"
                class="las la-times-circle"
              />
              <i
                v-if="passwordValidators(form.password).minimumLength"
                class="las la-check-circle"
              />
            </div>
            au moins 8 caractères
          </li>
        </ul>
      </small>
    </div>
  </b-form-group>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import passwordValidators from '@/helpers/password-validators';

export default {
  setup: () => ({ passwordValidators, $v: useVuelidate({ $lazy: true }) }),
  components: {
    // BSV
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    // validations

    BRow,
    BCol,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        password: '',
        // confirmPassword: "",
      },
      passwordFieldType: 'password',
      isLoading: false,
      passwordFieldIsFocused: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  props: {
    passedLabel: {
      type: String,
      default: 'Mot de passe',
    },
  },
  watch: {
    passwordFieldIsFocused: {
      handler(val, old) {
        this.$v.form.password.$validate();
      },
    },
    'form.password': {
      handler(val, old) {
        this.$emit('input', val);
      },
    },
  },

  validations() {
    return {
      form: {
        password: {
          required,
          isValid(value) {
            return this.passwordValidators(value).isValid;
          },
        },
        // confirmPassword: {
        //   isValid: val => val && val === this.form.password,
        // },
      },
    };
  },
  mounted() {},
  methods: {
    togglePasswordInputType() {
      // eslint-disable-next-line no-unused-expressions
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    async submitForm() {},
  },
};
</script>

<style lang="scss"></style>
