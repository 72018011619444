import Vue from 'vue';
import Vuex, { Store } from 'vuex';

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import permissions from './permissions';
import secteurs from './secteurs';
import auth from './auth';
import utils from './utils';
import imagePuzzleSliderCaptcha from './image-puzzle-slider-captcha';
import params from './params';
import users from './users';
// import agentGouvenement from './agent-gouvenement'
// import commissaire from './commissaire'
import roles from './roles';
// import formulaire from './formulaire'
// import etablissements from './etablissements'
// import statistics from './statistics'
   import notifications from './notifications'
// import faq from './faq'
import manuelUtilisations from './manuel-utilisations';
import plaintes from './plaintes';
import status from './status';

Vue.use(Vuex);

const isDevEnv = process.env.Node_ENV !== 'production';
const state = {
  serverUrl: isDevEnv ? 'http://localhost:8000' : 'http://localhost:8000',
};

const actions = {};
export default new Vuex.Store({
  modules: {
    'app-ecommerce': ecommerceStoreModule,
    app,
    appConfig,
    verticalMenu,
    imagePuzzleSliderCaptcha,
    permissions,
    auth,
    utils,
    secteurs,
    params,
    users,
    // agentGouvenement,
    // commissaire,
    // formulaire,
    roles,
    // statistics,
    // etablissements,
    // notifications,
    // faq,
    manuelUtilisations,
    plaintes,

    status,
  },
  state,
  actions,
  strict: process.env.DEV,
});
