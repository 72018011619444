import Vue from "vue";
import VueI18n from "vue-i18n";
import { extend } from "vee-validate";
import { required, length, max } from "@validations";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
export default new VueI18n({
  locale: "fr",
  fallbackLocale: "fr",
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
});

// extend('required', {
//     ...required,
//     message: 'Ce champ est requis.',
// })

// extend('length', {
//     ...length,
//     length: (field, [length, max]) => {
//         if (max) {
//           return `Le taille du champs ${field} devrait être entre ${length} et ${max}`;
//         }
//         return `Le taille du champs ${field} devrait être ${length}`;
//       },
// })

// extend(('length',[length, max]), {
//     ...length,
//     message: max ? `Le taille du champs ${field} devrait être entre ${length} et ${max}` : `Le taille du champs ${field} devrait être ${length}`
//     // length: (field, [length, max]) => {
//     //     if (max) {
//     //       return `Le taille du champs ${field} devrait être entre ${length} et ${max}`;
//     //     }
//     //     return `Le taille du champs ${field} devrait être ${length}`;
//     //   },
// })
