import { BaseService } from "./base.service";
import localstorageService from "../localstorage/localstorage.service";

class AuthService extends BaseService {
  loginEndpoint = "/login";

  registerEndpoint = "/register";

  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  login(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(this.loginEndpoint, data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  sendOTP(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/otp", data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  register(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/register", data)
        .then((result) => {
          // console.log('register', result)
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  loginByOtp(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/login/otp', data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  registerByOtp(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/otp', data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  registerEtablissement(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/inscriptions/register-file", data)
        .then((result) => {
          // console.log('register', result)
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    return new Promise((resolve) => {
      localstorageService.removeJwtToken();
      localstorageService.removeJwtRefreshToken();

      // Remove userData from localStorage
      localstorageService.removeUserData();
      resolve(true);
    });
  }

  forgetPassword(data) {
    // console.log('forgetPassword', data)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/forgot-password", data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  codeValidation(data) {
    // console.log('codeValidation', data)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/admins/check-reset-code", data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  resetPassword(data) {
    // console.log('resetPassword', data)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/reset-password", data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setPasswordAgent(data) {
    // console.log('resetPassword', data)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/admins/set-password", data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default new AuthService();
