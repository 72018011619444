import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate, canNavigateToForm } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'

import utilsService from '@/services/utils/utils.service'
import { canFillForm as isFormFillingPeriod } from '@/helpers/manage-form-filling-period'
import localstorageService from '@/services/localstorage/localstorage.service'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import pages from './routes/pages'

import tourisme from './routes/tourisme'

import users from './routes/users'
import formsTourisme from './routes/forms-tourisme'
import notifications from './routes/notifications'
import faq from './routes/faq'
import manuelUtilisations from './routes/manuel-utilisations'
import configs from './routes/configs'
import plaintes from './routes/plaintes'
import information_client from './routes/information-client'
import user_profil from './routes/user-profil'
import tourism_jobs from './routes/tourism-jobs'
import auto_evaluations from './routes/auto_evaluations'
import appeals from './routes/appeals'
import monEtablis from './routes/mon-etablis'

// remove remove push redirect errors
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } if (to.hash) {
      return { selector: to.hash, offset: { x: 0, y: 140 } }
    }
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home-public',
      component: () => import(
        /* webpackPrefetch: true */ /* webpackChunkName: 'Home' */ '@/views/pages/home/Home.vue'
      ),
      meta: {
        layout: 'default',
        resource: 'Public',
      },
    },
    // SSO CALLBACK
    {
      path: '/sso-callback/:code?',
      name: 'sso-callback',
      component: () => import('@/views/pages/authentication/CallbackSSO.vue'),
      meta: {
        layout: 'full',
        resource: 'Public',
      },
    },
    {
      path: '/apropos',
      name: 'a-propos',
      component: () => import(
        /* webpackPrefetch: true */ /* webpackChunkName: 'Apropos' */ '@/views/pages/home/Apropos.vue'
      ),
      meta: {
        layout: 'default',
        resource: 'Public',
      },
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import(
        /* webpackPrefetch: true */ /* webpackChunkName: 'Apropos' */ '@/views/pages/home/Faq.vue'
      ),
      meta: {
        layout: 'default',
        resource: 'Public',
      },
    },
    {
      path: '/blog',
      name: 'blog-list',
      component: () => import('@/views/pages/blog/Blog.vue'),
      meta: {
        layout: 'default',
        resource: 'Public',
      },
    },
    {
      path: '/blog/:id',
      name: 'blog-detail',
      component: () => import('@/views/pages/blog/BlogDetail.vue'),
      meta: {
        layout: 'default',
        resource: 'Public',
      },
    },
    // BLOG REGLEMENTATION
    {
      path: '/blog/reglementations/1',
      name: 'blog-reglementations-hebergement',
      component: () => import('@/views/pages/blog/BlogRegHebergement.vue'),
      meta: {
        layout: 'default',
        resource: 'Public',
      },
    },
    {
      path: '/blog/reglementations/2',
      name: 'blog-reglementations-guide',
      component: () => import('@/views/pages/blog/BlogRegGuide.vue'),
      meta: {
        layout: 'default',
        resource: 'Public',
      },
    },
    {
      path: '/blog/reglementations/3',
      name: 'blog-reglementations-agence',
      component: () => import('@/views/pages/blog/BlogRegAgence.vue'),
      meta: {
        layout: 'default',
        resource: 'Public',
      },
    },
    {
      path: '/blog/reglementations/4',
      name: 'blog-reglementations-restaurant',
      component: () => import('@/views/pages/blog/BlogRegRestaurant.vue'),
      meta: {
        layout: 'default',
        resource: 'Public',
      },
    },
    // BLOG CHIFFRE TENDANCES
    {
      path: '/blog/chiffres-tendances/1',
      name: 'blog-chiffres-tendances-hebergement',
      component: () => import('@/views/pages/blog/BlogCTHebergement.vue'),
      meta: {
        layout: 'default',
        resource: 'Public',
      },
    },
    {
      path: '/blog/chiffres-tendances/2',
      name: 'blog-chiffres-tendances-guide',
      component: () => import('@/views/pages/blog/BlogCTGuide.vue'),
      meta: {
        layout: 'default',
        resource: 'Public',
      },
    },
    {
      path: '/blog/chiffres-tendances/3',
      name: 'blog-chiffres-tendances-agence',
      component: () => import('@/views/pages/blog/BlogCTAgence.vue'),
      meta: {
        layout: 'default',
        resource: 'Public',
      },
    },
    {
      path: '/blog/chiffres-tendances/4',
      name: 'blog-chiffres-tendances-restaurant',
      component: () => import('@/views/pages/blog/BlogCTRestaurant.vue'),
      meta: {
        layout: 'default',
        resource: 'Public',
      },
    },
    // {
    //   path: "/:failed_check_mail",
    //   name: "home-public",
    //   component: () =>
    //     import(
    //       /* webpackPrefetch: true */ /* webpackChunkName: 'Home' */ "@/views/pages/home/Home.vue"
    //     ),
    //   meta: {
    //     layout: "default",
    //     resource: "Public",
    //   },
    // },
    {
      path: '/mentions-legales',
      name: 'mentions-legales',
      component: () => import(
        /* webpackPrefetch: true */ '@/views/pages/home/MentionsLegales.vue'
      ),
      meta: {
        resource: 'Public',
        layout: 'default',
      },
    },
    ...users,
    ...tourisme,
    ...apps,
    ...dashboard,
    ...pages,
    ...formsTourisme,
    ...notifications,
    ...faq,
    ...manuelUtilisations,
    ...configs,
    ...plaintes,
    ...auto_evaluations,
    ...information_client,
    ...user_profil,
    ...tourism_jobs,
    ...appeals,
    ...monEtablis,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// import store from "./store";

router.beforeEach((to, _, next) => {
  // console.log("to::: ⭕️⭕️⭕️", to);

  const isLoggedIn = isUserLoggedIn()
  const {
    isFormlaireRoute,
    action: routeAction,
    checkFormFillingPeriod,
  } = to.meta
  if (isFormlaireRoute && to.name === 'formulaires-list-ue') {
    return next()
  }

  // const { slug, model } = to.params;
  // const selectedForm = slug
  //   ? utilsService.getFormulaireBySlug(slug)
  //   : utilsService.getFormulaireByModel(model);
  // let formulaireRoute = {
  //   ...to,
  // };

  // if (isFormlaireRoute && !selectedForm) {
  //   if (to.name !== "home-public") return next({ name: "home-public" });
  // }

  // if (selectedForm)
  //   formulaireRoute = {
  //     ...to,
  //     action: routeAction,
  //     resource: selectedForm.resource || "Public",
  //   };

  // Redirect to login if not logged in
  if (!isLoggedIn) {
    if (to.name !== 'auth-login') {
      if (to.meta.resource !== 'Public') {
        localstorageService.setRouteToRedirectToAfterLogin(to.path)
        return next({ name: 'auth-login' })
      }
    }
  }
  // if (
  //   (isFormlaireRoute && !canNavigateToForm(formulaireRoute)) ||
  //   (!isFormlaireRoute && !canNavigate(to))
  // ) {

  //   // If logged in => not authorized. Abilities need to come from the backend.
  //   if (to.name !== "home-public") return next({ name: "home-public" });
  //   // next({ name: 'misc-not-authorized' })
  //   // next({ name: 'auth-login' })
  // }

  // check form filling period
  // if (isFormlaireRoute && checkFormFillingPeriod && !isFormFillingPeriod) {
  //   if (to.name !== "home-public") {
  //     return next({
  //       name: "home-public",
  //       params: {
  //         redirectMotive: "OutOfFillingPeriod",
  //       },
  //     });
  //   }
  // }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  const isLoggedIn = isUserLoggedIn()

  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  if (isLoggedIn) {
    localstorageService.removeRouteToRedirectToAfterLogin()
  }
})

export default router
