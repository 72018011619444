import localstorageService from '@/services/localstorage/localstorage.service'

export default {
  namespaced: true,
  state: {
    show: false,
    isSuccess: false,
    isCompleted: false,
    paymentInitialsDatas: {
      amount: 100,
      description: '',
      api_keys: {
        // fedapay_public_api_key: 'pk_live_NfqfnXRoCeMpYnAuvHjaazky',
        fedapay_public_api_key: 'pk_sandbox_EJkumcgGjjEPF6WaGNQKkNgm',
        // kkiapay_public_api_key: '970b8c10699611ec9f5205a1aca9c042',
        kkiapay_public_api_key: 'd84a24205e8d11ec961391121972f828',
      },
      email: '',
      phone: '',
      lastname: '',
      firstname: '',
      fullname: '',
    },
    paymentResponse: { isSuccess: false },
  },
  getters: {
    getter_show(state) {
      return state.show
    },
    getter_paymentInitialsDatas(state) {
      return state.paymentInitialsDatas
    },
    getter_isCompleted(state) {
      return state.isCompleted
    },
    getter_paymentResponse(state) {
      return state.paymentResponse
    },
  },
  mutations: {
    setter_show(state, data) {
      state.show = data
    },
    setter_isSolved(state, data) {
      state.isSolved = data
    },
    setter_isCompleted(state, data) {
      state.isCompleted = data
    },
    setter_paymentInitialsDatas(state, data) {
      state.paymentInitialsDatas = data
    },
    onPaymentComplete(state, data) {
      console.log('data :>> ', data);
      const { transactionId, isSuccess } = data
      state.paymentResponse = { transactionId, data }
      state.isCompleted = true
      state.isSuccess = isSuccess
    },
    clearState(state, data) {
      state.paymentResponse = { isSuccess: false }
      state.isCompleted = false
      state.isSuccess = false
    },
  },
  actions: {
    async show({ commit, state, rootState }, data) {
      commit('clearState', {}) 
      commit('setter_show', true)
      const { paymentInitialsDatas } = data
      commit('setter_paymentInitialsDatas', paymentInitialsDatas)

      return new Promise(resolve => {
        const looper = () => {
          const {
            isCompleted, isSuccess, show, paymentResponse,
          } = state
          console.log('isCompleted, show :>> ', isCompleted, show,);
          setTimeout(() => {
            if (!show) resolve(paymentResponse)
            if (isCompleted) {
              commit('setter_show', false)
              resolve(paymentResponse)
            } else {
              looper()
            }
          }, 0)
        }
        looper()
      })
    },
    async hide({ commit, rootState }, data) {
      commit('clearState', {})
      commit('setter_show', false)
    },
    async onSuccess({ commit, rootState }, data) {
      commit('setter_isCompleted', true)
      commit('setter_isSolved', true)
    },
    async onError({ commit, rootState }, data) {
      commit('setter_isCompleted', false)
      commit('setter_isSolved', false)
    },
  },
}
