import { BaseService } from './base.service'

export class PermissionService extends BaseService {
  constructor() {
    super()
  }

  fetchPermissions({ params }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/permissions/list', { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  searchPermissions(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/permissions/search', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  addPermissions(permission) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/permissions/create', permission)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updatePermissionStatus(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/roles/permissions/manage', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new PermissionService()
