import { BaseService } from './base.service'

export class StatusService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super()
  }

  fetchStatus(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/complaints/statuses', { data })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new StatusService()
