export default [
  {
    path: '/contenu-informationnel/list',
    name: 'contenu-informationnel-list',
    component: () => import('@/views/configs/blog/index.vue'),
    meta: {
      action: "access",
      resource: 'info_client',
    },
  },
  {
    path: '/contenu-informationnel/create',
    name: 'contenu-informationnel-create',
    component: () => import('@/views/configs/blog/create.vue'),
    meta: {
      action: "access",
      resource: 'info_client',
    },
  },
  {
    path: '/contenu-informationnel/details/:id',
    name: 'contenu-informationnel-details',
    component: () => import('@/views/configs/blog/details.vue'),
    meta: {
      action: "access",
      resource: 'info_client',
    },
  },
  {
    path: '/contenu-informationnel/edit/:id',
    name: 'contenu-informationnel-edit',
    component: () => import('@/views/configs/blog/update.vue'),
    meta: {
      action: "access",
      resource: 'info_client',
    },
  },
]
