export default [
  {
    path: "/tourism-jobs",
    name: "tourism-jobs",
    component: () => import("@/views/configs/tourism-jobs/Index.vue"),
    meta: {
      resource: "tourism_jobs",
      action: "access",
    },
  },
  {
    path: "/stats",
    name: "stats",
    component: () => import("@/views/dashboard/stats.vue"),
    meta: {
      resource: "tourism_jobs",
      action: "access",
    },
  },
  {
    path: "/all-requests",
    name: "all-requests",
    component: () => import("@/views/dashboard/ListDemandes.vue"),
    meta: {
      resource: "demandes",
      action: "access",
    },
  },
];