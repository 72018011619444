// import Contact from '@/views/pages/home/Contacts.vue'
// import RemplirFormulaire from '@/views/apps/formulaires/RemplirFormulaire.vue'
export default [
  // {
  //   path: '/',
  //   name: 'home-public',
  //   component: () => import('@/views/pages/home/Home.vue'),
  //   meta: {
  //     layout: 'default',
  //     resource: 'Public',
  //   },
  // },
  {
    path: '/contacts',
    name: 'contacts',
    component: () =>
      import(/* webpackPrefetch: true */ '@/views/pages/home/Contacts.vue'),
    meta: {
      resource: 'Public',
      layout: 'default',
    },
  },
  {
    path: '/espaceInvestisseur',
    name: 'espace-investisseur',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/pages/home/EspaceInvestisseur.vue'
      ),
    meta: {
      resource: 'Public',
      layout: 'default',
    },
  },
  {
    path: '/operateurs/:operateur_id/details',
    name: 'public-operateurs-details',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/apps/utilisateurs/operateurs/PublicDetailsOperateurs.vue'
      ),
    meta: {
      resource: 'Public',
      layout: 'default',
    },
  },
  {
    path: '/chiffresEtTendances',
    name: 'chiffres-tendances',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/pages/blog/ChiffresTendances.vue'
      ),
    meta: {
      resource: 'Public',
      layout: 'default',
    },
  },
  {
    path: '/reglementations',
    name: 'reglementations',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/pages/blog/Reglementations.vue'
      ),
    meta: {
      resource: 'Public',
      layout: 'default',
    },
  },
  {
    path: '/repertoire-operateurs-touristiques',
    name: 'repertoire-operateurs-touristiques',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/pages/blog/RepertoireOperateur.vue'
      ),
    meta: {
      resource: 'Public',
      layout: 'default',
    },
  },
  {
    path: '/operateurTouristique',
    name: 'operateur-touristique',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/pages/home/operateurTouristique.vue'
      ),
    meta: {
      resource: 'Public',
      layout: 'default',
    },
  },
  {
    path: '/etablissements/create',
    name: 'etablissement-create',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/pages/home/operateurTouristique.vue'
      ),
    meta: {
      resource: 'Public',
      layout: 'default',
    },
  },
  {
    path: '/depot-reclamation',
    name: 'depot-reclamation',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/pages/complaints/complaints.vue'
      ),
    meta: {
      resource: 'Public',
      layout: 'default',
    },
  },
  {
    path: '/formulaires/list/ue',
    name: 'formulaires-list-ue',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/apps/formulaires/ListFormulaireUE.vue'
      ),
    meta: {
      resource: 'mes_demandes',
      action: 'list',
      isFormlaireRoute: true,
    },
  },
  {
    path: '/formulaires/list/:slug?',
    name: 'formulaires-list',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/apps/formulaires/ListFormulaire.vue'
      ),
    meta: {
      resource: 'demandes',
      action: 'list',
      isFormlaireRoute: true,
      // slug: null,
    },
    // props: (route) => ({ slug: route.params.slug }),
    // props: (route) => ({ slug: route.slug }),
  },
  {
    path: '/formulaires/create/:slug?',
    name: 'formulaires-create',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/apps/formulaires/RemplirFormulaire.vue'
      ),
    // component: RemplirFormulaire,
    meta: {
      resource: 'demandes',
      action: 'create',
      isFormlaireRoute: true,
      checkFormFillingPeriod: true,
    },
  },
  {
    path: '/formulaires/preview/:slug?',
    name: 'formulaires-preview',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/apps/formulaires/PreviewFormulaire.vue'
      ),
    meta: {
      resource: 'demandes',
      action: 'create',
      isFormlaireRoute: true,
    },
  },
  {
    path: '/formulaires/:slug/:id?/a',
    name: 'formulaires-details',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/apps/formulaires/DetailsFormulaire.vue'
      ),
    meta: {
      resource: 'demandes',
      action: 'read',
      isFormlaireRoute: true,
    },
  },
  {
    path: '/formulaires/:slug/:id?/op',
    name: 'formulaires-details-op',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/apps/formulaires/DetailsFormulaireOp.vue'
      ),
    meta: {
      resource: 'demandes',
      action: 'read',
      isFormlaireRoute: true,
    },
  },
  {
    path: '/rdv/:slug/:user_id?',
    name: 'formulaires-rdv',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/apps/formulaires/RDV_Calendar.vue'
      ),
  },
  {
    path: '/arretes/list',
    name: 'arretes-list',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/apps/arretes/ArretesList.vue'
      ),
    meta: {
      // resource: "mes_demandes",
      // action: "list",
      // isFormlaireRoute: true,
    },
  },
  {
    path: '/arretes/add',
    name: 'arretes-add',
    component: () =>
      import(/* webpackPrefetch: true */ '@/views/apps/arretes/ArretesAdd.vue'),
    meta: {},
  },
  {
    path: '/arretes/:id',
    name: 'arretes-details',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/apps/arretes/ArretesDetails.vue'
      ),
    meta: {},
  },
  {
    path: '/inscription/grand-public',
    name: 'register-default',
    component: () =>
      import('@/views/pages/authentication/registration/RegisterDefault.vue'),
    meta: {
      resource: 'Public',
      layout: 'default',
    },
  },
  // {
  //   path: '/inscription/etablissement-et-agence',
  //   name: 'register-etablissement',
  //   component: () => import(
  //     '@/views/pages/authentication/registration/RegisterEtablissement.vue'
  //   ),
  //   meta: {
  //     resource: 'Public',
  //     // layout: 'full',
  //   },
  // },
  {
    path: '/inscriptions',
    name: 'list-registration-type',
    component: () =>
      import(
        '@/views/pages/authentication/registration/ListRegistrationTypes.vue'
      ),
    meta: {
      resource: 'Public',
      layout: 'full',
    },
  },

  // operateurs
  {
    path: '/operateurs/new-list',
    name: 'operateurs-list',
    component: () =>
      import('@/views/apps/utilisateurs/operateurs/ListOperateurs.vue'),
    meta: {
      resource: 'operateurs',
      action: 'administrate',
    },
  },
  {
    path: '/operateurs/old-list',
    name: 'operateurs-old-list',
    component: () =>
      import('@/views/apps/utilisateurs/operateurs/OldOperateursList.vue'),
    meta: {
      resource: 'operateurs',
      action: 'administrate',
    },
  },
  {
    path: '/verify-email',
    name: 'etablissements-verify-email',
    component: () =>
      import(
        '@/views/apps/utilisateurs/operateurs/VerifyEmailEtablissement.vue'
      ),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/operateurs/create',
    name: 'operateurs-create',
    component: () =>
      import('@/views/apps/utilisateurs/operateurs/CreateOperateurs.vue'),
    meta: {
      resource: 'Public',
    },
  },
  // Add by me
  {
    path: '/etablissements/agents/create-password-agent',
    name: 'etablissements-agents-create-password-agent',
    component: () =>
      import(
        '@/views/apps/utilisateurs/operateurs/agents/components/CreatePasswordAgent.vue'
      ),
    meta: {
      layout: 'full',
      resource: 'Public',
      redirectIfLoggedIn: true,
      resource: 'Auth',
    },
  },
  {
    path: '/etablissements/:etablissementId/ajouter-un-agent',
    name: 'etablissements-agents-create',
    component: () =>
      import('@/views/apps/utilisateurs/operateurs/agents/CreateAgent.vue'),
    meta: {
      action: 'create',
      resource: 'agentEtablissement',
    },
  },
  {
    path: '/etablissements/agents/:agentId',
    name: 'etablissements-agents-details',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/apps/utilisateurs/operateurs/agents/DetailsAgent.vue'
      ),
    meta: {
      action: 'read',
      resource: 'agentEtablissement',
    },
  },
  {
    path: '/etablissements/agents/editer/:agentId',
    name: 'etablissements-agent-edit',
    component: () =>
      import('@/views/apps/utilisateurs/operateurs/agents/EditAgent.vue'),
    meta: {
      action: 'update',
      resource: 'agentEtablissement',
    },
  },
  {
    path: '/etablissements/:etablissementId/liste-des-agents',
    name: 'etablissements-agents-list',
    component: () =>
      import('@/views/apps/utilisateurs/operateurs/agents/ListAgents.vue'),
    meta: {
      action: 'administrate',
      resource: 'operateurs',
    },
  },
  {
    path: '/operateurs/:operateur_id',
    name: 'operateurs-details',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/apps/utilisateurs/operateurs/DetailsOperateurs.vue'
      ),
    meta: {
      action: 'administrate',
      resource: 'operateurs',
    },
  },
  {
    path: '/utilisateurs/parametres',
    name: 'account-settings',
    component: () => import('@/views/apps/utilisateurs/parametres/index.vue'),
    meta: {},
  },

  // Statistics
  {
    path: '/statistiques/etablissements',
    name: 'statistiques-etablissements',
    component: () => import('@/views/statistics/etablissements/Index.vue'),
    meta: {
      resource: 'statsTest',
    },
  },
  {
    path: '/statistiques/agences-transport',
    name: 'stats-agences-transport',
    component: () => import('@/views/statistics/agences-transport/Index.vue'),
    meta: {
      resource: 'statsTest',
    },
  },
  {
    path: '/statistiques/agences-location-sans-operateur',
    name: 'stats-agences-location-sans-operateur',
    component: () =>
      import('@/views/statistics/agences-location-sans-operateur/Index.vue'),
    meta: {
      resource: 'statsTest',
    },
  },
  {
    path: '/statistiques/etablissement-de-restauration',
    name: 'stats-etablissements-restauration',
    component: () =>
      import('@/views/statistics/agences-restauration/Index.vue'),
    meta: {
      resource: 'statsTest',
    },
  },
  {
    path: '/statistiques-agence-voyage',
    name: 'stats-agences-voyage',
    component: () => import('@/views/statistics/agences-voyage/Index.vue'),
    meta: {
      resource: 'statsTest',
    },
  },
  {
    path: '/stats-industries-touristiques',
    name: 'stats-industries-touristiques',
    component: () =>
      import('@/views/statistics/industries-touristiques/Index.vue'),
    meta: {
      resource: 'statsTest',
    },
  },
  {
    path: '/statistiques-etablissement-hebergement',
    name: 'stats-etablissement-hebergement',
    component: () =>
      import('@/views/statistics/etablissements-hebergement/Index.vue'),
    meta: {
      resource: 'statsTest',
    },
  },
  {
    path: '/statistiques/frontieres-terrestres',
    name: 'stats-frontieres-terrestres',
    component: () =>
      import('@/views/statistics/frontieres-terrestres/Index.vue'),
    meta: {
      resource: 'statsTest',
    },
  },
  // {
  //   path: '/statistiques/aeroport',
  //   name: 'statistiques-aeroport',
  //   component: () => import('@/views/statistics/aeroports/Index.vue'),
  //   meta: {
  //     resource: 'Public',
  //   },
  // },

  // Agents MTCA
  {
    path: '/agents/create',
    name: 'agents-create',
    component: () =>
      import('@/views/apps/utilisateurs/agentMtca/CreateAgent.vue'),
    meta: {
      action: 'create',
      resource: 'agentMtca',
    },
  },
  {
    path: '/agents/listes-des-agents',
    name: 'agents-listes-des-agents',
    component: () =>
      import('@/views/apps/utilisateurs/agentMtca/ListAgents.vue'),
    meta: {
      action: 'read',
      resource: 'agentMtca',
    },
  },
  {
    path: '/agents/:agentId',
    name: 'agents-details',
    component: () =>
      import(
        /* webpackPrefetch: true */ '@/views/apps/utilisateurs/agentMtca/DetailsAgent.vue'
      ),
    meta: {
      action: 'read',
      resource: 'agentMtca',
    },
  },

  // Commissaires
  {
    path: '/commissariats/create',
    name: 'commissariats-create',
    component: () =>
      import('@/views/apps/utilisateurs/commissariat/CreateCommissariat.vue'),
    meta: {
      action: 'create',
      resource: 'commissaire',
    },
  },
  {
    path: '/commissariats/listes-des-agents',
    name: 'commissariats-list',
    component: () =>
      import('@/views/apps/utilisateurs/commissariat/ListCommissariat.vue'),
    meta: {
      action: 'read',
      resource: 'commissaire',
    },
  },
  {
    path: '/commissariats/:commissaireId',
    name: 'commissariats-details',
    component: () =>
      import('@/views/apps/utilisateurs/commissariat/DetailsCommissariat.vue'),
    meta: {
      action: 'read',
      resource: 'commissaire',
    },
  },
];
