export default [
  // auto - evaluations
  {
    path: '/etablissement',
    name: 'etablissement',
    component: () => import('@/views/pages/etablissement/Index.vue'),
    meta: {
      resource: 'profile',
      action: 'access',
    },
  },

];
