/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */

import { initialAbility } from '@/libs/acl/config'
import localstorageService from '../localstorage/localstorage.service'

import {
  countries,
  arrondissements,
  communes,
  departements,
  quartiers,
} from './constants'

const generateSlug = title => title
  .toLowerCase()
  .replace(':', '-')
  .replace('/', '-')
  .replace(/[\s|-]+/gi, '-')

// const countries = require('./countries-en.json')
// eslint-disable-next-line camelcase
const role_admin_etablissement = 'promo_etablissement'
// eslint-disable-next-line camelcase
const role_agent_etablissement = 'agent_etablissement'

// eslint-disable-next-line camelcase
const role_commissaire = 'commissaire'
// eslint-disable-next-line camelcase
const role_agent_police = 'agent_police'

// eslint-disable-next-line camelcase
const role_agent_mtca = 'agent_mtca'

// eslint-disable-next-line camelcase
const role_super_admin = 'super_admin'
export class UtilsService {
  countries = countries;

  communes = communes;

  departements = departements;

  arrondissements = arrondissements;

  quartiers = quartiers;

  formulaires = [
    // UEH -------------------------------------------------------------------------------------------
    {
      title: 'requests.autorisation.title',
      // title: 'Demande d’autorisation d’exploitation et de premier classement',
      code_demande: 'UEH_autorisation_premier_classement',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        details: 'DetailsUEH_expolitation_classement',
        pv: 'PV_UEH_exploitation_classement',
        checklist: 'CheckList_UEH_exploitation_classement',
      },
      code_client: '1234567851',
      sector: 'UEH',
      sector_label: 'Etablissement d\'hébergement',
      resource: 'demandes',
      // description:
      //   "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      montant_demande: 100,
      demande_link: 'https://pprod.service-public.bj/public/services/service/PS01279',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      step_before_ot_decisions: 'deliberation_session_check',
      step_before_minister_decisions: 'transfert_pv_deliberation_to_minister',

      step_before_ot_decisions_classification: 'classification_deliberation_session_check',
      step_before_minister_decisions_classification: 'transfert_all_documentation_to_minister',
      process_steps: [ // UEH_autorisation_premier_classement
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          next_step: 'treatment_start',
          class: 'relief-dark',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME DE LA DEMANDE ÉFFECTUÉE',
          action_label: 'TERMINER LE CONTRÔLE DE LA FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          request_update_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_process_technical_control',
          show_result: 'have_autorisation_preliminaries',
        },
        // ---------------------------------------------
        {
          status: 'in_process_technical_control',
          etape: 'PROCESSUS CONTRÔLE TECHNIQUE',
          action_label: "LANCER LE PROCESSUS DE CONTRÔLE TECHNIQUE",
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          visite_autorization: true,
          show_result: 'have_autorisation_visit',
          auto_validate_pv: 'can_validate_pv_technical_control',
          is_ot_admin_validation: true,
          next_step: 'technical_control_check',
          is_ot_validation: true,
        },

        {
          status: 'technical_control_check',
          etape: 'VISITE TECHNIQUE ÉFFECTUÉE',
          action_label: 'CONFIRMER LA RÉALISATION DE LA VISITE TECHNIQUE',
          class: 'relief-dark',
          parent_process: 'in_process_technical_control',
          treatment_section: true,
          next_step: 'deliberation_session_check',
          is_ot_admin_validation: true,
          is_ot_validation: true,
        },
        {
          status: 'deliberation_session_check',
          etape: 'SESSION DE DÉLIBÉRATION TENUE',
          action_label: 'LANCER LE PROCESSUS DE DÉLIBÉRATION',
          class: 'relief-dark',
          parent_process: 'in_process_technical_control',
          treatment_section: true,
          next_decisions_options_count: 3,
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ----- decisions OT
        {
          status: 'unfavourable_agreement',
          etape: 'ACCORD DÉFAVORABLE DE L\'ORGANE TECHNIQUE',
          action_label: "REFUSER LA DEMANDE",
          class: 'relief-danger',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'favourable_agreement_with_conditions',
          etape: 'ACCORD FAVORABLE AVEC RÉSERVES DE L\'ORGANE TECHNIQUE',
          action_label: "ACCEPTER LA DEMANDE SOUS RÉSERVE",
          class: 'relief-warning',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'favourable_agreement',
          etape: 'ACCORD FAVORABLE DE L\'ORGANE TECHNIQUE',
          action_label: "ACCEPTER LA DEMANDE ",
          class: 'relief-primary',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ---------------------------------------------
        {
          status: 'transfert_pv_deliberation_to_minister',
          etape: 'TRANSFÉRER LE PV DE DÉLIBÉRATION AU MINISTRE',
          action_label: 'TRANSFÉRER LES RÉSULTATS AU MINISTRE',
          class: 'relief-dark',
          duration: 5,
          next_decisions_options_count: 3,
          treatment_section: true,
          ministerial_decree_step: true,
          hide_on_ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ----- decisions minister
        {
          status: 'minister_unfavourable_agreement',
          etape: 'ACCORD DÉFAVORABLE DU MINISTRE ',
          action_label: 'DÉCISION DÉFAVORABLE',
          class: 'relief-danger',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_unfavourable_agreement',
          is_minister_validation: true,
        },
        {
          status: 'minister_favourable_agreement_with_conditions',
          etape: 'ACCORD FAVORABLE DU MINISTRE SOUS RÉSERVES',
          action_label: 'DÉCISION FAVORABLE SOUS RÉSERVE',
          class: 'relief-warning',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_favourable_agreement_with_conditions',
          is_minister_validation: true,
        },
        {
          status: 'minister_favourable_agreement',
          etape: 'ACCORD FAVORABLE DU MINISTRE',
          action_label: 'DÉCISION FAVORABLE',
          class: 'relief-primary',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_favourable_agreement',
          is_minister_validation: true,
        },
        // ---------------------------------------------
        { // accusé reception décisions du ministre
          status: 'acknowledgement_of_receipt_minister_decisions',
          etape: 'ACCUSÉ DE RÉCEPTION DES DÉCISIONS DU MINISTRE',
          action_label: 'ACCUSER RÉCEPTION DES DÉCISIONS DU MINISTRE',
          class: 'relief-dark',
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          go_to: 'transfert_pv_deliberation_to_minister',
          offset: 1,
        },

        { // notification accord défavorable ministre
          status: 'user_notification_unfavourable_agreement',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          parent_process: 'acknowledgement_of_receipt_minister_unfavourable_agreement',
          treatment_section: true,
          final_state: true,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          send_mail: true,
        },
        { // notification accord favorable ministre suivant intégration correction
          status: 'user_notification_favourable_agreement_with_conditions',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          next_step: 'corrections',
          next_step_is_client_validation: 'corrections',
          send_mail: true,
        },
        { // notification accord favorable ministre
          status: 'user_notification_favourable_agreement',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          treatment_section: true,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          next_step_is_client_validation: 'opening',
          next_step: 'opening',
          send_mail: true,
        },

        { // validation prise en compte corrections par client
          status: 'corrections',
          etape: 'PRISE EN COMPTE DES RECOMMANDATIONS DU COMMITÉ TECHNIQUE',
          action_label: 'CONFIRMER LA PRISE EN COMPTE DES RECOMMANDATIONS DU COMMITÉ TECHNIQUE',
          class: 'relief-dark',
          treatment_section: true,
          duration: 90,
          next_decisions_options_count: 2,
          is_applicant_validation: true,
          next_step: 'technical_control_recommendation_check',
        },
        {
          status: 'technical_control_recommendation_check',
          etape: 'VISITE TECHNIQUE DE CONTRÔLE DE PRISE EN COMPTE DES RECOMMANDATIONS ÉFFECTUÉE',
          action_label: 'CONFIRMER LA RÉALISATION DE LA VISITE TECHNIQUE DE CONTRÔLE DE PRISE EN COMPTE DES RECOMMANDATIONS',
          class: 'relief-dark',
          is_ot_admin_validation: true,
          next_decisions_options_count: 2,
          show_result: 'have_recommandation_control_visit'
        },

        // ----- decisions sur intégration des corrections
        {
          status: 'rejection_integration_recommendations',
          etape:
            'REJET DE LA PRISE EN COMPTE DES RECOMMANDATIONS PAR LE DEMANDEUR ET NOTIFICATION AU REQUÉRANT',
          action_label:
            'CONFIRMER LA NON PRISE EN COMPTE DES RECOMMANDATIONS PAR LE DEMANDEUR',
          class: 'relief-danger',
          decision: true,
          final_state: true,
        },
        {
          status: 'validation_integration_recommendations',
          etape: 'VALIDATION DE LA PRISE EN COMPTE DES RECOMMANDATIONS PAR LE DEMANDEUR',
          action_label: 'CONFIRMER LA PRISE EN COMPTE DES RECOMMANDATIONS PAR LE DEMANDEUR ET NOTIFICATION AU REQUÉRANT AVEC DÉLIVRANCE DU DOCUMENT FINAL',
          class: 'relief-primary',
          decision: true,
          next_step: 'opening',
          next_step_is_client_validation: 'opening',
        },
        // ----------------------------------------------------------------

        { // validation ouverture etab par client
          status: 'opening',
          etape: "OUVERTURE DE L'ÉTABLISSEMENT",
          action_label: "CONFIRMER L'OUVERTURE DE L'ÉTABLILSSEMENT",
          class: 'relief-dark',
          treatment_section: true,
          duration: 90,
          next_decisions_options_count: 2,
          is_applicant_validation: true,
        },
        // ----- decisions sur ouverture
        {
          status: 'rejection_opening',
          etape: "NON CONFIRMATION DE L'OUVERTURE DE L'ÉTABLISSEMENT",
          action_label: "INFIRMER L'OUVERTURE DE L'ÉTABLISSEMENT",
          class: 'relief-danger',
          decision: true,
          final_state: true,
        },
        {
          status: 'validation_opening',
          etape: "CONFIRMATION DE L'OUVERTURE DE L'ÉTABLISSEMENT",
          action_label: "CONFIRMER L'OUVERTURE DE L'ÉTABLISSEMENT",
          class: 'relief-primary',
          decision: true,
          next_step: 'in_classification_process',
          send_mail: true,
          stop: ["Meublé de tourisme", "Chambre d’hôtes"],
          show_result: 'have_classification_preliminaries',
        },
        // -----------------------------------------------------------------------------------------------------

        // classement UEH------------------------------------------------------------------------------------------
        {
          status: 'in_classification_process',
          etape: 'PROCESSUS DE CLASSEMENT',
          action_label: 'LANCER LE PROCESSUS DE CLASSEMENT',
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_step: 'classification_repport_check',
          visite_classification: true,
          show_result: 'have_classification_visit',
          auto_validate_pv: 'can_validate_pv_classification',
          audit_step: true,
          is_ot_validation: true,
        },
        {
          status: 'classification_repport_check',
          etape: 'DÉPOT DU PV DE LA VISITE DE CLASSEMENT',
          action_label: 'CONFIRMER LE DEPÔT DU PV DE LA VISITE DE CLASSEMENT',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          next_decisions_options_count: 2,
          audit_step: true,
          is_ot_validation: true,
        },
        // ----- decisions make audit or not
        {
          status: 'do_not_make_mystery_audit',
          etape: "SAUT DE L'AUDIT MYSTÈRE",
          action_label: 'NE PAS MANDATER UN AUDIT MYSTÈRE',
          class: 'relief-warning',
          decision: true,
          next_step: 'classification_deliberation_session_check',
          audit_step: true,
          is_ot_validation: true,
        },
        {
          status: 'make_mystery_audit',
          etape: "RÉALISATION DE L'AUDIT MYSTÈRE",
          action_label: 'MANDATER UN AUDIT MYSTÈRE',
          class: 'relief-warning',
          duration: 5,
          treatment_section: true,
          decision: true,
          audit_step: true,
          next_step: 'in_audit_process',
          is_ot_validation: true,
          show_result:'have_exam_preliminaries',
        },
        // ---------------------------------------------

        // --------------------------------------------- audit
        {
          status: 'in_audit_process',
          etape: 'DÉBUT PROCESSUS D\'AUDIT',
          action_label: 'LANCER LE PROCESSUS D\'AUDIT MYSTÈRE',
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_step: 'audit_repport_check',
          audit_classification: true,
          show_result: 'have_exam_visit',
          audit_step: true,
          is_ot_validation: true,
          auto_validate_pv: 'can_validate_pv_exam',
        },
        {
          status: 'audit_repport_check',
          etape: 'DÉPOT DU PV DE LA VISITE D\'AUDIT',
          action_label: 'CONFIRMER LE DEPÔT DU PV DE LA VISITE D\'AUDIT MYSTÈRE',
          class: 'relief-dark',
          parent_process: 'in_audit_process',
          treatment_section: true,
          next_step: 'classification_deliberation_session_check',
          audit_step: true,
          is_ot_validation: true,
        },
        // --------------------------------------------- /audit

        // gestion commission examen ------------
        {
          status: 'classification_deliberation_session_check',
          etape: 'SESSION DE DÉLIBÉRATION TENUE',
          action_label: 'LANCER LE PROCESSUS DE DÉLIBÉRATION',
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_decisions_options_count: 2,
          ministerial_decree_step: true,
          audit_step: true,
          is_ot_validation: true,
        },
        // ----- decisions OT
        {
          status: 'classification_unfavourable_agreement',
          etape: 'ACCORD DÉFAVORABLE DE L\'ORGANE TECHNIQUE',
          action_label: 'REFUSER LA CLASSIFICATION SOUHAITÉE',
          class: 'relief-danger',
          decision: true,
          next_step: 'transfert_all_documentation_to_minister',
          ministerial_decree_step: true,
          audit_step: true,
          is_ot_validation: true,
        },
        {
          status: 'classification_favourable_agreement',
          etape: 'ACCORD FAVORABLE  DE L\'ORGANE TECHNIQUE',
          action_label: 'ACCEPTER LA CLASSIFICATION SOUHAITÉE',
          class: 'relief-primary',
          decision: true,
          next_step: 'transfert_all_documentation_to_minister',
          ministerial_decree_step: true,
          audit_step: true,
          is_ot_validation: true,
        },
        // --------------------------------

        {
          status: 'transfert_all_documentation_to_minister',
          etape: 'TRANSFERT DES RÉSULTATS AU MINISTRE POUR DÉCISION FINALE',
          action_label: 'CONFIRMER LE TRANSFERT DES RÉSULTATS AU MINISTRE POUR DÉCISION FINALE',
          class: 'relief-dark',
          treatment_section: true,
          next_decisions_options_count: 2,
          audit_step: true,
          hide_on_ministerial_decree_step: true,
          is_ot_validation: true,
          ministerial_decree_step: true,
        },

        // ----- decisions d'attribution de classement du ministre
        {
          status: 'minister_reject_classsification',
          etape: 'REJET DE LA DEMANDE DE CLASSEMENT',
          action_label: 'NE PAS ACCORDER LE CLASSEMENT AU REQUÉRANT',
          class: 'relief-danger',
          decision: true,
          next_step: 'user_notification_final_classification_fail',
          is_minister_validation: true,
          audit_step: true,
          ministerial_decree_step: true,
        },
        {
          status: 'minister_accept_classsification',
          etape: 'ATTRIBUTION DE LA DEMANDE DE CLASSEMENT',
          action_label: 'ACCORDER LE CLASSEMENT AU REQUÉRANT',
          class: 'relief-primary',
          decision: true,
          next_step: 'user_notification_final_classification_success',
          is_minister_validation: true,
          audit_step: true,
          ministerial_decree_step: true,
        },
        // --------------------------------
        { // accusé reception décisions du ministre
          status: 'acknowledgement_of_receipt_minister_decisions',
          etape: 'ACCUSÉ DE RÉCEPTION DES DÉCISIONS DU MINISTRE',
          action_label: 'ACCUSER RÉCEPTION DES DÉCISIONS DU MINISTRE',
          class: 'relief-dark',
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          go_to: 'transfert_all_documentation_to_minister',
          offset: 1,
          audit_step: true,
        },

        { // notification AU REQUÉRANT de la decision de classement fail
          status: 'user_notification_final_classification_fail',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION DE CLASSEMENT',
          action_label: 'NOTIFIER AU REQUÉRANT LA DÉCISION DE CLASSEMENT',
          class: 'relief-dark',
          send_mail: true,
          final_state: true,
          audit_step: true,
          ministerial_decree_step: true,
        },
        { // notification AU REQUÉRANT de la decision de classement success
          status: 'user_notification_final_classification_success',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION DE CLASSEMENT',
          action_label: 'NOTIFIER AU REQUÉRANT LA DÉCISION DE CLASSEMENT',
          class: 'relief-dark',
          treatment_section: true,
          send_mail: true,
          audit_step: true,
          next_step: 'applicant_get_banner',
          ministerial_decree_step: true,
          applicant_submit_document: 'du retrait du panonceau',
        },
        // end NOTIFICATION
        {
          status: 'applicant_get_banner',
          etape: 'RETRAIT DU PANNONCEAU PAR LE DEMANDEUR',
          action_label: 'CONFIRMER LE RETRAIT DU PONNONCEAU PAR LE DEMANDEUR ',
          class: 'relief-primary',
          final_state: true,
          audit_step: true,
        },
      ],
      have_auto_evaluation: true,

      have_autorisation_preliminaries: true,
      have_autorisation_visit: true,
      can_validate_pv_technical_control: true,

      have_recommandation_control_preliminaries: true,
      have_recommandation_control_visit: true,

      have_classification_preliminaries: true,
      have_classification_visit: true,
      can_validate_pv_classification: true,

      have_exam_preliminaries: true,
      have_exam_visit: true,
      can_validate_pv_exam: true,

      // have_many_requests_in_one: true,
      applicant_submit_document: true,
    },
    {
      title: 'requests.reclassification.title',
      code_demande: 'UEH_reclassement',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        details: 'DetailsUEH_reclassement',
        pv: 'PV_UEH_reclassement',
        checklist: 'CheckList_UEH_reclassement',
      },
      code_client: '3456789012',
      sector: 'UEH',
      sector_label: 'Etablissement d\'hébergement',
      resource: 'demandes',

      // description:
      //   "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      montant_demande: 100,
      demande_link: 'https://pprod.service-public.bj/public/services/service/PS01285',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      step_before_ot_decisions: 'deliberation_session_check',
      step_before_minister_decisions: 'transfert_pv_deliberation_to_minister',
      process_steps: [ // UEH_reclassement
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          next_step: 'treatment_start',
          class: 'relief-dark',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITETEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME DE LA DEMANDE ÉFFECTUÉE',
          action_label: 'TERMINER LE CONTRÔLE DE LA FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          request_update_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_process_technical_control',
        },
        // ---------------------------------------------
        // reclassement --------------------------------------------------------------
        {
          status: 'in_process_technical_control',
          etape: 'PROCESSUS DE RECLASSEMENT',
          action_label: 'LANCER LE PROCESSUS DE RECLASSEMENT',
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_step: 'reclassification_repport_check',
          show_result: 'have_classification_visit',
          visite_classification: true,
          is_ot_validation: true,
        },
        {
          status: 'reclassification_repport_check',
          etape: 'DÉPOT DU PV DE LA VISITE DE RECLASSEMENT',
          action_label: 'CONFIRMER LE DEPÔT DU PV DE LA VISITE DE RECLASSEMENT',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          next_decisions_options_count: 2,
          is_ot_validation: true,
        },
        // ----- decisions make audit or not
        {
          status: 'do_not_make_mystery_audit',
          etape: 'RÉALISATION DE L\'AUDIT MYSTÈRE',
          action_label: 'NE PAS MANDATER UN AUDIT MYSTÈRE',
          class: 'relief-warning',
          decision: true,
          next_step: 'deliberation_session_check',
          is_ot_validation: true,
        },
        {
          status: 'make_mystery_audit',
          etape: 'RÉALISATION DE L\'AUDIT MYSTÈRE',
          action_label: 'MANDATER UN AUDIT MYSTÈRE',
          class: 'relief-warning',
          duration: 5,
          treatment_section: true,
          decision: true,
          next_step: 'in_audit_process',
          is_ot_validation: true,
        },
        // ---------------------------------------------

        // --------------------------------------------- audit
        {
          status: 'in_audit_process',
          etape: 'PROCESSUS D\'AUDIT',
          action_label: 'LANCER LE PROCESSUS D\'AUDIT MYSTÈRE',
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_step: 'audit_repport_check',
          show_result: 'have_exam_visit',
          audit_classification: true,
          is_ot_validation: true,
        },
        {
          status: 'audit_repport_check',
          etape: 'DÉPOT DU PV DE LA VISITE D\'AUDIT',
          action_label: 'CONFIRMER LE DEPÔT DU PV DE LA VISITE D\'AUDIT MYSTÈRE',
          class: 'relief-dark',
          parent_process: 'in_audit_process',
          treatment_section: true,
          next_step: 'deliberation_session_check',
          is_ot_validation: true,
        },
        // --------------------------------------------- /audit

        // gestion commission examen ------------
        {
          status: 'deliberation_session_check',
          etape: 'SESSION DE DÉLIBÉRATION TENUE',
          action_label: 'LANCER LE PROCESSUS DE DÉLIBÉRATION',
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_decisions_options_count: 2,
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ----- decisions OT
        {
          status: 'reclassification_unfavourable_agreement',
          etape: 'ACCORD DÉFAVORABLE DE L\'ORGANE TECHNIQUE',
          action_label: 'REFUSER LA RECLASSIFICATION SOUHAITÉE',
          class: 'relief-danger',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'reclassification_favourable_agreement',
          etape: 'ACCORD FAVORABLE  DE L\'ORGANE TECHNIQUE',
          action_label: 'ACCEPTER LA RECLASSIFICATION SOUHAITÉE',
          class: 'relief-primary',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // --------------------------------

        {
          status: 'transfert_pv_deliberation_to_minister',
          etape: 'TRANSFERT DES RÉSULTATS AU MINISTRE POUR DÉCISION FINALE',
          action_label: 'CONFIRMER LE TRANSFERT DES RÉSULTATS AU MINISTRE POUR DÉCISION FINALE',
          class: 'relief-dark',
          treatment_section: true,
          next_decisions_options_count: 2,
          is_ot_validation: true,
          ministerial_decree_step: true,
        },

        // ----- decisions d'attribution de reclassement du ministre
        {
          status: 'minister_reject_reclasssification',
          etape: 'REJET DE LA DEMANDE DE RECLASSEMENT',
          action_label: 'NE PAS ACCORDER LE RECLASSEMENT AU REQUÉRANT',
          class: 'relief-danger',
          decision: true,
          next_step: 'user_notification_final_reclassification_fail',
          is_minister_validation: true,
          ministerial_decree_step: true,
        },
        {
          status: 'minister_accept_classsification',
          etape: 'ATTRIBUTION DE LA DEMANDE DE RECLASSEMENT',
          action_label: 'ACCORDER LE RECLASSEMENT AU REQUÉRANT',
          class: 'relief-primary',
          decision: true,
          next_step: 'user_notification_final_reclassification_success',
          is_minister_validation: true,
          ministerial_decree_step: true,
        },
        // --------------------------------

        { // accusé reception décisions du ministre
          status: 'acknowledgement_of_receipt_minister_decisions',
          etape: 'ACCUSÉ DE RÉCEPTION DES DÉCISIONS DU MINISTRE',
          action_label: 'ACCUSER RÉCEPTION DES DÉCISIONS DU MINISTRE',
          class: 'relief-dark',
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          go_to: 'transfert_pv_deliberation_to_minister',
          offset: 1,
        },

        { // notification AU REQUÉRANT de la decision de classement fail
          status: 'user_notification_final_reclassification_fail',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION DE RECLASSEMENT',
          action_label: 'NOTIFIER AU REQUÉRANT LA DÉCISION DE RECLASSEMENT',
          class: 'relief-dark',
          send_mail: true,
          final_state: true,
          ministerial_decree_step: true,
        },
        { // notification AU REQUÉRANT de la decision de classement success
          status: 'user_notification_final_reclassification_success',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION DE RECLASSEMENT',
          action_label: 'NOTIFIER AU REQUÉRANT LA DÉCISION DE RECLASSEMENT',
          class: 'relief-dark',
          treatment_section: true,
          next_step: 'applicant_get_banner',
          send_mail: true,
          ministerial_decree_step: true,
          applicant_submit_document: 'du retrait du nouveau panonceau',
        },
        // end NOTIFICATION
        {
          status: 'applicant_get_banner',
          etape: 'RETRAIT DU PANNONCEAU PAR LE DEMANDEUR',
          action_label: 'CONFIRMER LE RETRAIT DU PONNONCEAU PAR LE DEMANDEUR ',
          class: 'relief-primary',
          final_state: true,
        },
      ],
      have_classification_visit: true,
      have_exam_visit: true,
      applicant_submit_document: true,
    },
    {
      title: "Notifications diverses des établissement d'hébergement",
      code_demande: 'UEH_notification',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        // form: "FormEntreesFrontieresTerrestres",
        // preview: "PreviewEntreesFrontieresTerrestres",
        details: 'DetailsUEH_notification_classement',
        pv: 'PV_UEH_notification_classement',
        checklist: 'CheckList_UEH_notification_classement',
      },
      code_client: '5678901234',
      sector: 'UEH',
      sector_label: 'Etablissement d\'hébergement',
      resource: 'demandes',

      // description:
      //   "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      montant_demande: 100,
      demande_link: 'https://pprod.service-public.bj/public/services/service/PS01286',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      process_steps: [ // UEH_notification
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          next_step: 'treatment_start',
          class: 'relief-dark',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITETEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME DE LA DEMANDE ÉFFECTUÉE',
          action_label: 'TERMINER LE CONTRÔLE DE LA FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          request_update_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_notification_process',
        },

        // notification ------------------------------------------------------------------------------------------
        {
          status: 'in_notification_process',
          etape: 'PROCESSUS D\'ENREGISTREMENT DE LA NOTIFICATION',
          action_label: 'LANCER L\'ENREGISTREMENT DE LA NOTIFICATION',
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions about information
        {
          status: 'infirm_information',
          etape: 'INFIRMATION LA VÉRACITÉ DES INFORMATIONS',
          action_label: 'INFIRMER LA VÉRACITÉ DES INFORMATIONS',
          class: 'relief-danger',
          decision: true,
          next_step: 'user_notification_notfication_rejected',
        },
        {
          status: 'confirm_information',
          etape: 'CONFIRMATION LA VÉRACITÉ DES INFORMATIONS',
          action_label: 'CONFIRMER LA VÉRACITÉ DES INFORMATIONS',
          class: 'relief-primary',
          duration: 5,
          treatment_section: true,
          decision: true,
          next_step: 'update_registration',
        },
        // ---------------------------------------------
        {
          status: 'user_notification_notfication_rejected',
          etape: 'NOTIFICATION REJET DE LA NOTIFICATION',
          action_label: 'NOTIFIER AU REQUÉRANT LE REJET DE LA NOTIFICATION',
          class: 'relief-dark',
          final_state: true,
        },
        {
          status: 'update_registration',
          etape: 'MODIFICATION DES RÉGISTRES',
          action_label: 'CONFIRMER LA MODIFICATION DES RÉGISTRES',
          class: 'relief-dark',
          next_step: 'user_notification_notfication_accepted',

        },
        {
          status: 'user_notification_notfication_accepted',
          etape: 'NOTIFICATION ACCEPTATION DE LA NOTIFICATION',
          action_label: 'NOTIFIER AU REQUÉRANT LA PRISE EN COMPTE DE LA NOTIFICATION',
          class: 'relief-dark',
          final_state: true,
        },
      ],
    },
    // UEAV -------------------------------------------------------------------------------------------
    {
      title: 'requests.travel_agency.title',
      code_demande: 'UEAV_licence',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        // form: "FormEntreesFrontieresTerrestres",
        // preview: "PreviewEntreesFrontieresTerrestres",
        details: 'DetailsUEAV_licence',
        pv: 'PV_UEAV_licence',
        checklist: 'CheckList_UEAV_licence',
      },
      resource: 'demandes',
      code_client: '6789012345',
      sector: 'UEAV',
      sector_label: 'Agence de voyage et de tourisme',
      // description:
      //   "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      montant_demande: 100,
      demande_link:
        'https://pprod.service-public.bj/public/services/service/PS01287',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      step_before_ot_decisions: 'deliberation_session_check',
      step_before_minister_decisions: 'transfert_pv_deliberation_to_minister',
      process_steps: [ // UEAV_licence
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          next_step: 'treatment_start',
          class: 'relief-dark',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITETEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME DE LA DEMANDE ÉFFECTUÉE',
          action_label: 'TERMINER LE CONTRÔLE DE LA FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_process_technical_control',
        },
        // ---------------------------------------------
        {
          status: 'in_process_technical_control',
          etape: 'PROCESSUS  D\'ATTRIBUTION DE LICENCE',
          action_label: "LANCER LE PROCESSUS D'ATTRIBUTION DE LICENCE",
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          metting_with_applicant: true,
          next_decisions_options_count: 2,
          is_ot_validation: true,
        },
        // ----- decisions OT SUR AUTORISATION AGRÉMENT
        {
          status: 'application_inadmissible',
          etape: "ACCORD DÉFAVORABLE APRÈS EXAMEN DES PIÈCES PHYSIQUES",
          action_label: "DONNER UN ACCORD DÉFAVORABLE SUR EXAMEN DES PIÈCES PHYSIQUES",
          class: 'relief-danger',
          decision: true,
          final_state: true,
          is_ot_validation: true,
        },
        {
          status: 'application_admissible',
          etape: "ACCORD FAVORABLE APRÈS EXAMEN DES PIÈCES PHYSIQUES",
          action_label: "DONNER UN ACCORD FAVORABLE SUR EXAMEN DES PIÈCES PHYSIQUES",
          class: 'relief-primary',
          decision: true,
          next_decisions_options_count: 2,
          is_ot_validation: true,
        },
        // ---------------------------------------------

        // ----- decisions sur la réalisation du contôl technique 
        {
          status: 'go_to_ot_deliberation',
          etape: "ÉTAPE DU CONTRÔLE TECHNIQUE SAUTÉ",
          action_label: "PASSER L'ÉTAPE DE LA DÉLIBÉRATION",
          class: 'relief-warning',
          next_step: 'deliberation_session_check',
          is_ot_validation: true,
        },
        {
          status: 'make_technical_control',
          etape: "RÉALISATION D'UN CONTRÔLE TECHNIQUE",
          action_label: "RÉALISER LE CONTRÔLE TECHNIQUE",
          class: 'relief-warning',
          duration: 10,
          treatment_section: true,
          show_result: 'have_autorisation_visit',
          is_ot_validation: true,
          next_step: 'technical_control_check',
          show_result: 'have_autorisation_preliminaries',
          auto_validate_pv: 'can_validate_pv_technical_control',
        },
        // ---------------------------------------------

        { // notification accord défavorable AU REQUÉRANT
          status: 'user_notification_unfavourable_agreement',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION DÉFAVORABLE',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION FAVORABLE',
          class: 'relief-dark',
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          final: true,
          is_ot_validation: true,
        },

        // process du control technique -------------------------
        {
          status: 'technical_control_check',
          etape: 'VISITE TECHNIQUE ÉFFECTUÉE',
          action_label: 'CONFIRMER LA RÉALISATION DE LA VISITE TECHNIQUE',
          class: 'relief-dark',
          next_step: 'deliberation_session_check',
          is_ot_validation: true,
        },
        // fin process du control technique -------------------------

        {
          status: 'deliberation_session_check',
          etape: 'SESSION DE DÉLIBÉRATION TENUE',
          action_label: 'LANCER LE PROCESSUS DE DÉLIBÉRATION',
          class: 'relief-dark',
          treatment_section: true,
          ministerial_decree_step: true,
          next_decisions_options_count: 3,
          is_ot_validation: true,
        },
        // ----- decisions OT
        {
          status: 'unfavourable_agreement',
          etape: 'ACCRÉDITATION DU PROJET DÉFAVORABLE DE L\'ORGANE TECHNIQUE',
          action_label: "REFUSER L'ACCRÉDITATION DU PROJET",
          class: 'relief-danger',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'favourable_agreement_with_conditions',
          etape: 'ACCRÉDITATION DU PROJET FAVORABLE DE L\'ORGANE TECHNIQUE SOUS RÉSERVE DE PRISE EN COMPTE DES RECOMMANDATIONS',
          action_label: "ACCORDER L'ACCRÉDITATION DU PROJET SOUS RÉSERVE DE PRISE EN COMPTE DES RECOMMANDATIONS",
          class: 'relief-warning',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'favourable_agreement',
          etape: "ACCRÉDITATION DU PROJET FAVORABLE DE L'ORGANE TECHNIQUE",
          action_label: "ACCORDER L'ACCRÉDITATION DU PROJET",
          class: 'relief-primary',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ---------------------------------------------
        {
          status: 'transfert_pv_deliberation_to_minister',
          etape: 'TRANSFÉRER LE PV DE DÉLIBÉRATION AU MINISTRE',
          action_label: 'TRANSFÉRER LES RÉSULTATS AU MINISTRE',
          class: 'relief-dark',
          duration: 3,
          next_decisions_options_count: 3,
          treatment_section: true,
          ministerial_decree_step: true,
          hide_on_ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ----- decisions minister
        {
          status: 'minister_unfavourable_agreement',
          etape: 'ACCRÉDITATION DU PROJET DÉFAVORABLE DU MINISTRE',
          action_label: "REFUSER L'ACCRÉDITATION DU PROJET",
          class: 'relief-danger',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_unfavourable_agreement_minister',
          is_minister_validation: true,
        },
        {
          status: 'minister_favourable_agreement_with_conditions',
          etape: 'ACCRÉDITATION DU PROJET FAVORABLE DU MINISTRE AVEC PRISE EN COMPTES DES RECOMMANDATIONS',
          action_label: "ACCORDER L'ACCRÉDITATION DU PROJET SOUS RÉSERVE DE PRISE EN COMPTE DES RECOMMANDATIONS",
          class: 'relief-warning',
          decision: true,
          next_step: 'user_notification_favourable_agreement_minister',
          ministerial_decree_step: true,
        },
        {
          status: 'minister_favourable_agreement',
          etape: "ACCRÉDITATION DU PROJET FAVORABLE DU MINISTRE",
          action_label: "ACCORDER L'ACCRÉDITATION DU PROJET",
          class: 'relief-primary',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_favourable_agreement_minister',
          is_minister_validation: true,
        },
        // /decisions minister ------------------------------


        { // accusé reception décisions du ministre
          status: 'acknowledgement_of_receipt_minister_decisions',
          etape: 'ACCUSÉ DE RÉCEPTION DES DÉCISIONS DU MINISTRE',
          action_label: 'ACCUSER RÉCEPTION DES DÉCISIONS DU MINISTRE',
          class: 'relief-dark',
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          go_to: 'transfert_pv_deliberation_to_minister',
          offset: 1,
        },

        { // notification accord défavorable ministre
          status: 'user_notification_unfavourable_agreement_minister',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AU REQUÉRANT LA DECISION',
          class: 'relief-dark',
          parent_process: 'acknowledgement_of_receipt_minister_unfavourable_agreement',
          treatment_section: true,
          duration: 5,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          final_state: true,
        },
        { // notification accord favorable ministre
          status: 'user_notification_favourable_agreement_minister',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION ET COMPLÉTION DE SON DOSSIER',
          action_label: 'NOTIFIER AU REQUÉRANT DE LA DECISION ET LE BESOIN DE COMPLÉTION DU DOSSIER',
          class: 'relief-dark',
          treatment_section: true,
          duration: 5,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          next_step: 'procedure_completion_by_applicant',
          next_step_is_client_validation: ' ',
          upload_files: [ // application/pdf, image/jpeg, image/png
            { label: 'Quittance de dépôt de la caution', key: 'quittance', type: 'application/pdf' },
            { label: 'Justificatif de l\'assurance de l\'agence', key: 'assurance', type: 'application/pdf' },
          ]
        },

        // ------------------------------------------------------------------------------------
        { // validation de la complétion du document
          status: 'procedure_completion_by_applicant',
          etape: 'COMPLÉTION DU DOSSIER PAR LE REQUÉRANT EFFECTIVE',
          action_label: 'CONFIRMER LA COMPLÉTION DU DOSSIER',
          class: 'relief-dark',
          treatment_section: true,
          duration: 3,
          next_decisions_options_count: 2,
          is_applicant_validation: true,
        },
        // ----- decisions sur complétion du dossier
        {
          status: 'rejection_completion_of_the_procedure',
          etape: 'REJET DE LA COMPLÉTION DU DOSSIER PAR LE DEMANDEUR',
          action_label: 'REJETER LA COMPLÉTION DU DOSSIER PAR LE DEMANDEUR',
          class: 'relief-danger',
          decision: true,
          final_state: true,
        },
        {
          status: 'validation_completion_of_the_procedure',
          etape: 'VALIDATION DE LA COMPLÉTION DU DOSSIER PAR LE REQUÉRANT ET DILIVRANCE DE LA LICENCE',
          action_label: 'CONFIRMER LA COMPLÉTION DU DOSSIER PAR LE REQUÉRANT ET DÉLIVRER LA LICENCE',
          class: 'relief-primary',
          decision: true,
          final_state: true,
        },
      ],
      have_autorisation_preliminaries: true,
      have_autorisation_visit: true,
      can_validate_pv_technical_control: true,

    },
    {
      title: 'requests.agrement_branch.title',
      code_demande: 'UEAV_agrement',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        // form: "FormEntreesFrontieresTerrestres",
        // preview: "PreviewEntreesFrontieresTerrestres",
        details: 'DetailsUEAV_succursale',
        pv: 'PV_UEAV_succursale',
        checklist: 'CheckList_UEAV_succursale',
      },
      code_client: '7890123452',
      sector: 'UEAV',
      sector_label: 'Agence de voyage et de tourisme',
      resource: 'demandes',

      // description:
      //   "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      montant_demande: 100,
      demande_link: 'https://pprod.service-public.bj/public/services/service/PS01288',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      step_before_ot_decisions: 'deliberation_session_check',
      step_before_minister_decisions: 'transfert_pv_deliberation_to_minister',
      process_steps: [ // UEAV_agrement
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          next_step: 'treatment_start',
          class: 'relief-dark',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITETEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME DE LA DEMANDE ÉFFECTUÉE',
          action_label: 'TERMINER LE CONTRÔLE DE LA FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          request_update_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_process_technical_control',
        },
        // ---------------------------------------------
        {
          status: 'in_process_technical_control',
          etape: "PROCESSUS DE DÉLIVRANCE D'AGRÉMENT DE SUCCURSALE",
          action_label: "LANCER LE PROCESSUS DE DÉLIVRANCE D'AGRÉMENT DE SUCCURSALE",
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_decisions_options_count: 2,
          is_ot_validation: true,
        },
        // ----- decisions about  SUCCURSALE
        {
          status: 'reject_succursale_opening',
          etape: "ACCOURD DÉFAVORABLE POUR L'OUVERTURE DE LA SUCCURSALE",
          action_label: "REJETER L'OUVERTURE DE LA SUCCURSALE",
          class: 'relief-danger',
          duration: 5,
          treatment_section: true,
          next_step: 'user_notification_opening_rejected',
          decision: true,
          is_ot_validation: true,
        },
        {
          status: 'accept_succursale_opening',
          etape: "ACCOURD FAVORABLE POUR L'OUVERTURE DE LA SUCCURSALE",
          action_label: "ACCEPTER L'OUVERTURE DE LA SUCCURSALE",
          next_step: 'user_notification_opening_accepted',
          class: 'relief-primary',
          duration: 5,
          treatment_section: true,
          decision: true,
          is_ot_validation: true,
        },
        // ---------------------------------------------
        {
          status: 'user_notification_opening_rejected',
          etape: 'NOTIFICATION REJET DE LA NOTIFICATION',
          action_label: "NOTIFIER AU REQUÉRANT L'ACCORD DÉFAVORABLE D'OUVERTURE DE SUCCURSALE",
          class: 'relief-dark',
          is_ot_validation: true,
          final_state: true,
        },
        {
          status: 'user_notification_opening_accepted',
          etape: 'NOTIFICATION ACCEPTATION DE LA NOTIFICATION',
          action_label: "NOTIFIER AU REQUÉRANT L'ACCORD FAVORABLE D'OUVERTURE DE SUCCURSALE",
          class: 'relief-dark',
          is_ot_validation: true,
          final_state: true,
        },
        // ---------------------------------------------
      ],
    },
    {
      title: 'requests.renewal_request.title',
      code_demande: 'UEAV_renew_agrement_licence',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        // form: "FormEntreesFrontieresTerrestres",
        // preview: "PreviewEntreesFrontieresTerrestres",
        details: 'DetailsUEAV_renouvellement_licence',
        pv: 'PV_UEAV_renouvellement_licence',
        checklist: 'CheckList_UEAV_renouvellement_licence',
      },
      code_client: '1204765419',
      sector: 'UEAV',
      sector_label: 'Agence de voyage et de tourisme',
      resource: 'demandes',

      // description:
      //   "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      montant_demande: 100,
      demande_link: 'https://pprod.service-public.bj/public/services/service/PS01289',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      step_before_ot_decisions: 'deliberation_session_check',
      step_before_minister_decisions: 'transfert_pv_deliberation_to_minister',
      process_steps: [ // UEAV_renew_agrement_licence
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          next_step: 'treatment_start',
          class: 'relief-dark',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITETEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME DE LA DEMANDE ÉFFECTUÉE',
          action_label: 'TERMINER LE CONTRÔLE DE LA FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          request_update_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_process_technical_control',
        },
        // ---------------------------------------------

        {
          status: 'in_process_technical_control',
          etape: 'PROCESSUS DE RENOUVELLEMENT DE LICENCE',
          action_label: "LANCER LE PROCESSUS DE RENOUVELLEMENT DE LA LICENCE",
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          metting_with_applicant: true,
          next_step: 'make_meet_with_appliant',
          is_ot_validation: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions OT SUR AUTORISATION AGRÉMENT
        {
          status: 'application_inadmissible',
          etape: "ACCORD DÉFAVORABLE APRÈS EXAMEN DES PIÈCES PHYSIQUES",
          action_label: "DONNER UN ACCORD DÉFAVORABLE SUR EXAMEN DES PIÈCES PHYSIQUES",
          class: 'relief-danger',
          decision: true,
          final_state: true,
          is_ot_validation: true,
        },
        {
          status: 'application_admissible',
          etape: "ACCORD FAVORABLE APRÈS EXAMEN DES PIÈCES PHYSIQUES",
          action_label: "DONNER UN ACCORD FAVORABLE SUR EXAMEN DES PIÈCES PHYSIQUES",
          class: 'relief-primary',
          decision: true,
          next_decisions_options_count: 2,
          is_ot_validation: true,
        },
        // ---------------------------------------------
        // ----- decisions sur la réalisation du contôl technique 
        {
          status: 'go_to_ot_deliberation',
          etape: "ÉTAPE D'UN CONTRÔLE TECHNIQUE SAUTÉ",
          action_label: "PASSER L'ÉTAPE DU CONTRÔLE TECHNIQUE",
          class: 'relief-warning',
          next_step: 'deliberation_session_check',
          is_ot_validation: true,
        },
        {
          status: 'make_technical_control',
          etape: "RÉALISATION D'UN CONTRÔLE TECHNIQUE",
          action_label: "RÉALISER LE CONTRÔLE TECHNIQUE",
          class: 'relief-warning',
          duration: 10,
          treatment_section: true,
          show_result: 'have_autorisation_visit',
          next_step: 'technical_control_check',
          is_ot_validation: true,
        },
        // ---------------------------------------------
        {
          status: 'user_notification_unfavourable_agreement',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION DÉFAVORABLE',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION FAVORABLE',
          class: 'relief-dark',
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          final: true,
          is_ot_validation: true,
        },

        // process du control technique -------------------------
        {
          status: 'technical_control_check',
          etape: 'VISITE TECHNIQUE ÉFFECTUÉE',
          action_label: 'CONFIRMER LA RÉALISATION DE LA VISITE TECHNIQUE',
          class: 'relief-dark',
          next_step: 'deliberation_session_check',
          is_ot_validation: true,
        },
        // fin process du control technique -------------------------

        {
          status: 'deliberation_session_check',
          etape: 'SESSION DE DÉLIBÉRATION TENUE',
          action_label: 'LANCER LE PROCESSUS DE DÉLIBÉRATION',
          class: 'relief-dark',
          treatment_section: true,
          ministerial_decree_step: true,
          next_decisions_options_count: 3,
          is_ot_validation: true,
        },
        // ----- decisions OT
        {
          status: 'unfavourable_agreement',
          etape: 'ACCRÉDITATION DU PROJET DÉFAVORABLE DE L\'ORGANE TECHNIQUE',
          action_label: "REFUSER L'ACCRÉDITATION DU PROJET",
          class: 'relief-danger',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'favourable_agreement_with_conditions',
          etape: 'ACCRÉDITATION DU PROJET FAVORABLE DE L\'ORGANE TECHNIQUE SOUS RÉSERVE DE PRISE EN COMPTE DES RECOMMANDATIONS',
          action_label: "ACCORDER L'ACCRÉDITATION DU PROJET SOUS RÉSERVE DE PRISE EN COMPTE DES RECOMMANDATIONS",
          class: 'relief-warning',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'favourable_agreement',
          etape: "ACCRÉDITATION DU PROJET FAVORABLE DE L'ORGANE TECHNIQUE",
          action_label: "ACCORDER L'ACCRÉDITATION DU PROJET",
          class: 'relief-primary',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ---------------------------------------------

        {
          status: 'transfert_pv_deliberation_to_minister',
          etape: 'TRANSFÉRER LE PV DE DÉLIBÉRATION AU MINISTRE',
          action_label: 'TRANSFÉRER LES RÉSULTATS AU MINISTRE',
          class: 'relief-dark',
          duration: 3,
          next_decisions_options_count: 3,
          treatment_section: true,
          ministerial_decree_step: true,
          hide_on_ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ----- decisions minister
        {
          status: 'minister_unfavourable_agreement',
          etape: 'ACCRÉDITATION DU PROJET DÉFAVORABLE DU MINISTRE',
          action_label: "REFUSER L'ACCRÉDITATION DU PROJET",
          class: 'relief-danger',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_unfavourable_agreement_minister',
          is_minister_validation: true,
        },
        {
          status: 'minister_favourable_agreement_with_conditions',
          etape: 'ACCRÉDITATION DU PROJET FAVORABLE DU MINISTRE AVEC PRISE EN COMPTES DES RECOMMANDATIONS',
          action_label: "ACCORDER L'ACCRÉDITATION DU PROJET SOUS RÉSERVE DE PRISE EN COMPTE DES RECOMMANDATIONS",
          class: 'relief-warning',
          decision: true,
          next_step: 'user_notification_favourable_agreement_minister',
          ministerial_decree_step: true,
        },
        {
          status: 'minister_favourable_agreement',
          etape: "ACCRÉDITATION DU PROJET FAVORABLE DU MINISTRE",
          action_label: "ACCORDER L'ACCRÉDITATION DU PROJET",
          class: 'relief-primary',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_favourable_agreement_minister',
          is_minister_validation: true,
        },
        // /decisions minister ------------------------------ 


        { // accusé reception décisions du ministre
          status: 'acknowledgement_of_receipt_minister_decisions',
          etape: 'ACCUSÉ DE RÉCEPTION DES DÉCISIONS DU MINISTRE',
          action_label: 'ACCUSER RÉCEPTION DES DÉCISIONS DU MINISTRE',
          class: 'relief-dark',
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          go_to: 'transfert_pv_deliberation_to_minister',
          offset: 1,
        },

        { // notification accord défavorable ministre
          status: 'user_notification_unfavourable_agreement_minister',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AU REQUÉRANT LA DECISION',
          class: 'relief-dark',
          parent_process: 'acknowledgement_of_receipt_minister_unfavourable_agreement',
          treatment_section: true,
          duration: 5,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          final_state: true,
        },
        { // notification accord favorable ministre
          status: 'user_notification_favourable_agreement_minister',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION ET COMPLÉTION DE SON DOSSIER',
          action_label: 'NOTIFIER AU REQUÉRANT DE LA DECISION ET LE BESOIN DE COMPLÉTION DU DOSSIER',
          class: 'relief-dark',
          treatment_section: true,
          duration: 5,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          next_step: 'procedure_completion_by_applicant',
          next_step_is_client_validation: ' ',
          upload_files: [ // application/pdf, image/jpeg, image/png
            { label: 'Justificatif de l\'assurance de l\'agence', key: 'assurance', type: 'application/pdf' },
          ]
        },

        // ------------------------------------------------------------------------------------
        { // validation de la complétion du document
          status: 'procedure_completion_by_applicant',
          etape: 'COMPLÉTION DU DOSSIER PAR LE REQUÉRANT EFFECTIVE',
          action_label: 'CONFIRMER LA COMPLÉTION DU DOSSIER',
          class: 'relief-dark',
          treatment_section: true,
          duration: 3,
          next_decisions_options_count: 2,
          is_applicant_validation: true,
        },
        // ----- decisions sur complétion du dossier
        {
          status: 'rejection_completion_of_the_procedure',
          etape: 'REJET DE LA COMPLÉTION DU DOSSIER PAR LE DEMANDEUR',
          action_label: 'REJETER LA COMPLÉTION DU DOSSIER PAR LE DEMANDEUR',
          class: 'relief-danger',
          decision: true,
          final_state: true,
        },
        {
          status: 'validation_completion_of_the_procedure',
          etape: 'VALIDATION DE LA COMPLÉTION DU DOSSIER PAR LE REQUÉRANT ET DILIVRANCE DE LA NOUVELLE LICENCE',
          action_label: 'CONFIRMER LA COMPLÉTION DU DOSSIER PAR LE REQUÉRANT ET DÉLIVRER LA NOUVELLE LICENCE',
          class: 'relief-primary',
          decision: true,
          final_state: true,
        },
      ],
      have_autorisation_visit: true,
    },
    {
      title: 'requests.travel_agency_notif.title',
      code_demande: 'UEAV_notification',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        // form: "FormEntreesFrontieresTerrestres",
        // preview: "PreviewEntreesFrontieresTerrestres",
        details: 'DetailsUEAV_notification',
        pv: 'PV_UEAV_notification',
        checklist: 'CheckList_UEAV_notification',
      },
      code_client: '9012345678',
      sector: 'UEAV',
      sector_label: 'Agence de voyage et de tourisme',
      resource: 'demandes',

      // description:
      //   "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      montant_demande: 100,
      demande_link:
        'https://pprod.service-public.bj/public/services/service/PS01290',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      process_steps: [ // UEAV_notification
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          next_step: 'treatment_start',
          class: 'relief-dark',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITETEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME',
          action_label: 'CONFIRMER LA VÉRIFICATION FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          request_update_section: true,
          next_decisions_options_count: 2,
        },

        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_notification_process',
        },

        // notification ------------------------------------------------------------------------------------------
        {
          status: 'in_notification_process',
          etape: 'PROCESSUS D\'ENREGISTREMENT DE LA NOTIFICATION',
          action_label: 'LANCER L\'ENREGISTREMENT DE LA NOTIFICATION',
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions about information
        {
          status: 'infirm_information',
          etape: 'INFIRMATION LA VÉRACITÉ DES INFORMATIONS',
          action_label: 'INFIRMER LA VÉRACITÉ DES INFORMATIONS',
          class: 'relief-danger',
          decision: true,
          next_step: 'user_notification_notfication_rejected',
        },
        {
          status: 'confirm_information',
          etape: 'CONFIRMATION LA VÉRACITÉ DES INFORMATIONS',
          action_label: 'CONFIRMER LA VÉRACITÉ DES INFORMATIONS',
          class: 'relief-primary',
          duration: 5,
          treatment_section: true,
          decision: true,
          next_step: 'update_registration',
        },
        // ---------------------------------------------
        {
          status: 'user_notification_notfication_rejected',
          etape: 'NOTIFICATION REJET DE LA NOTIFICATION',
          action_label: 'NOTIFIER AU REQUÉRANT LE REJET DE LA NOTIFICATION',
          class: 'relief-dark',
          final_state: true,
        },
        {
          status: 'update_registration',
          etape: 'MODIFICATION DES RÉGISTRES',
          action_label: 'CONFIRMER LA MODIFICATION DES RÉGISTRES',
          class: 'relief-dark',
          next_step: 'user_notification_notfication_accepted',

        },
        {
          status: 'user_notification_notfication_accepted',
          etape: 'NOTIFICATION ACCEPTATION DE LA NOTIFICATION',
          action_label: 'NOTIFIER AU REQUÉRANT LA PRISE EN COMPTE DE LA NOTIFICATION',
          class: 'relief-dark',
          final_state: true,
        },
      ],
    },
    // UEGT -------------------------------------------------------------------------------------------
    {
      title: 'requests.pro_card.title',
      code_demande: 'UEGT_carte_professionnelle',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        /*  form: "FormEntreesFrontieresTerrestres",
         preview: "PreviewEntreesFrontieresTerrestres", */
        details: 'DetailsUEGT_carte',
        pv: 'PV_UEGT_carte',
        checklist: 'CheckList_UEGT_carte',
      },
      code_client: '0123456789',
      sector: 'UEGT',
      sector_label: 'Guide de tourisme',
      resource: 'demandes',

      // description:
      //   "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      montant_demande: 100,
      demande_link: 'https://pprod.service-public.bj/public/services/service/PS01292',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      step_before_ot_decisions: 'deliberation_session_check',
      process_steps: [ // UEGT_carte_professionnelle
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          next_step: 'treatment_start',
          class: 'relief-dark',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITETEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME DE LA DEMANDE ÉFFECTUÉE',
          action_label: 'TERMINER LE CONTRÔLE DE LA FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          request_update_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_process_technical_control',
          show_result: 'have_metting_with_applicant_preliminaries',
        },
        // ---------------------------------------------
        {
          status: 'in_process_technical_control',
          etape: 'DÉBUT DU PROCESSUS DE DÉLIVRANCE DE LA CARTE PROFESSIONNELLE',
          action_label: 'LANCER LE PROCESSUS D\'OBTENTION DE LA CARTE PROFESSIONNELLE',
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          show_result: 'have_metting_with_applicant',
          metting_with_applicant: true,
          is_ot_validation: true,
          next_step: 'deliberation_session_check',
        },
        {
          status: 'deliberation_session_check',
          etape: 'SESSION DE DÉLIBÉRATION TENUE',
          action_label: 'LANCER LE PROCESSUS DE DÉLIBÉRATION',
          class: 'relief-dark',
          treatment_section: true,
          ministerial_decree_step: true,
          next_decisions_options_count: 2,
          is_ot_validation: true,
        },
        // ----- decisions OT
        {
          status: 'unfavourable_agreement',
          etape: 'ACCORD DÉFAVORABLE DE DÉLIVRANCE DE LA CARTE PROFESSIONNELLE',
          action_label: "REJETER L'ATTRIBUTION DE LA CARTE PROFESSIONNELLE",
          class: 'relief-danger',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'favourable_agreement',
          etape: 'ACCORD FAVORABLE DE DÉLIVRANCE DE LA CARTE PROFESSIONNELLE',
          action_label: "ACCEPTER L'ATTRIBUTION DE LA CARTE PROFESSIONNELLE",
          class: 'relief-primary',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ---------------------------------------------
        {
          status: 'transfert_pv_deliberation_to_minister',
          etape: 'TRANSFÉRER LE PV DE DÉLIBÉRATION AU MINISTRE',
          action_label: 'TRANSFÉRER LES RÉSULTATS AU MINISTRE',
          class: 'relief-dark',
          duration: 3,
          treatment_section: true,
          ministerial_decree_step: true,
          hide_on_ministerial_decree_step: true,
          next_decisions_options_count: 2,
          is_ot_validation: true,
        },
        // ----- decisions minister
        {
          status: 'minister_unfavourable_agreement',
          etape: 'ACCORD DÉFAVORABLE DU MINISTRE',
          action_label: 'REFUSER LA CARTE PROFESSIONNELLE',
          class: 'relief-danger',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_unfavourable_agreement_minister',
          is_minister_validation: true,
          duration: 10,
          treatment_section: true,
        },
        {
          status: 'minister_favourable_agreement',
          etape: 'ACCORD FAVORABLE DU MINISTRE',
          action_label: 'ACCORDER LA CARTE PROFESSIONNELLE',
          class: 'relief-primary',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_favourable_agreement_minister',
          is_minister_validation: true,
          duration: 10,
          treatment_section: true,
        },
        // ---------------------------------------------

        { // accusé reception décisions du ministre
          status: 'acknowledgement_of_receipt_minister_decisions',
          etape: 'ACCUSÉ DE RÉCEPTION DES DÉCISIONS DU MINISTRE',
          action_label: 'ACCUSER RÉCEPTION DES DÉCISIONS DU MINISTRE',
          class: 'relief-dark',
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          go_to: 'transfert_pv_deliberation_to_minister',
          offset: 1,
        },
        { // notification accord défavorable ministre
          status: 'user_notification_unfavourable_agreement_minister',
          etape: 'NOTIFICATIONS AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          parent_process: 'acknowledgement_of_receipt_minister_unfavourable_agreement',
          treatment_section: true,
          duration: 5,
          final_state: true,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
        },

        { // notification accord favorable ministre
          status: 'user_notification_favourable_agreement_minister',
          etape: 'NOTIFICATIONS AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          treatment_section: true,
          duration: 5,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          next_step: 'applicant_can_get_card',
          applicant_submit_document: 'du retrait de la carte profesionnelle',
        },
        {
          status: 'applicant_can_get_card',
          etape: 'RETRAIT DE LA CARTE PAR LE REQUÉRANT',
          action_label: 'NOTIFIER LE RETRAIT DE LA CARTE PROFESSIONNELLE',
          class: 'relief-dark',
          treatment_section: true,
          duration: 5,
          final_state: true,
        },
      ],
      have_metting_with_applicant_preliminaries: true,
      have_metting_with_applicant: true,
      
      applicant_submit_document: true,
    },
    {
      title: 'requests.renewal_pro_card.title',
      code_demande: 'UEGT_renew_carte_professionnelle',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        details: 'DetailsUEGT_renouvellement_carte',
        pv: 'PV_UEGT_renouvellement_carte',
        checklist: 'CheckList_UEGT_renouvellement_carte',
      },
      code_client: '1234567890',
      sector: 'UEGT',
      sector_label: 'Guide de tourisme',
      resource: 'demandes',

      // description:
      //   "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      montant_demande: 100,
      demande_link: 'https://pprod.service-public.bj/public/services/service/PS01293',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      step_before_ot_decisions: 'deliberation_session_check',
      process_steps: [ // UEGT_renew_carte_professionnelle
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          class: 'relief-dark',
          next_step: 'treatment_start',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITETEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME DE LA DEMANDE ÉFFECTUÉE',
          action_label: 'TERMINER LE CONTRÔLE DE LA FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          request_update_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_process_technical_control',
        },
        // ---------------------------------------------
        {
          status: 'in_process_technical_control',
          etape: 'DÉBUT DU PROCESSUS DE RENOUVELLEMENT DE LA CARTE PROFESSIONNELLE',
          action_label: 'LANCER LE PROCESSUS DE RENOUVELLEMENT DE LA CARTE PROFESSIONNELLE',
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_step: 'deliberation_session_check',
          is_ot_validation: true,
        },
        // {
        //   status: 'interview_with_the_applicant',
        //   etape: 'ÉFFECTUÉE L\'ENTRETIEN AVEC LE POSTULANT',
        //   action_label: 'CONFIRMER LA RÉALISATION D\'UN ENTRETIEN AVEC LE POSTULANT',
        //   class: 'relief-warning',
        //   next_step: 'deliberation_session_check',
        //   is_ot_validation: true,
        // },

        {
          status: 'deliberation_session_check',
          etape: 'SESSION DE DÉLIBÉRATION TENUE',
          action_label: 'LANCER LE PROCESSUS DE DÉLIBÉRATION',
          class: 'relief-dark',
          treatment_section: true,
          ministerial_decree_step: true,
          next_decisions_options_count: 2,
          is_ot_validation: true,
        },
        // ----- decisions OT
        {
          status: 'unfavourable_agreement',
          etape: 'ACCORD DÉFAVORABLE DE RENOUVELLEMENT DE LA CARTE PROFESSIONNELLE',
          action_label: 'REFUSER LE RENOUVELLEMENT DE LA CARTE PROFESSIONNELLE',
          class: 'relief-danger',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          duration: 20,
          treatment_section: true,
          is_ot_validation: true,
        },
        {
          status: 'favourable_agreement',
          etape: 'ACCORD FAVORABLE DE RENOUVELLEMENT DE LA CARTE PROFESSIONNELLE',
          action_label: 'ACCEPTER LE RENOUVELLEMENT DE LA CARTE PROFESSIONNELLE',
          class: 'relief-primary',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          duration: 20,
          treatment_section: true,
          is_ot_validation: true,
        },
        // ---------------------------------------------

        {
          status: 'transfert_pv_deliberation_to_minister',
          etape:
            'TRANSFÉRER LE PV DE DÉLIBÉRATION AU MINISTRE',
          action_label:
            'TRANSFÉRER LES RÉSULTATS AU MINISTRE',
          class: 'relief-dark',
          duration: 3,
          treatment_section: true,
          ministerial_decree_step: true,
          hide_on_ministerial_decree_step: true,
          next_decisions_options_count: 2,
          is_ot_validation: true,
        },
        // ----- decisions minister
        {
          status: 'minister_unfavourable_agreement',
          etape: 'ACCORD DÉFAVORABLE DU MINISTRE',
          action_label: 'REFUSER LE RENOUVELLEMENT CARTE PROFESSIONNELLE',
          class: 'relief-danger',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_unfavourable_agreement_minister',
          is_minister_validation: true,
          duration: 10,
          treatment_section: true,
        },
        {
          status: 'minister_favourable_agreement',
          etape: 'ACCORD FAVORABLE DU MINISTRE',
          action_label: 'ACCORDER LE RENOUVELLEMENT DE LA CARTE PROFESSIONNELLE',
          class: 'relief-primary',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_favourable_agreement_minister',
          is_minister_validation: true,
          duration: 10,
          treatment_section: true,
        },
        // ---------------------------------------------

        { // accusé reception décisions du ministre
          status: 'acknowledgement_of_receipt_minister_decisions',
          etape: 'ACCUSÉ DE RÉCEPTION DES DÉCISIONS DU MINISTRE',
          action_label: 'ACCUSER RÉCEPTION DES DÉCISIONS DU MINISTRE',
          class: 'relief-dark',
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          go_to: 'transfert_pv_deliberation_to_minister',
          offset: 1,
        },

        { // notification accord défavorable ministre
          status: 'user_notification_unfavourable_agreement_minister',
          etape: 'NOTIFICATIONS AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          treatment_section: true,
          duration: 5,
          final_state: true,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
        },
        { // notification accord favorable ministre
          status: 'user_notification_favourable_agreement_minister',
          etape: 'NOTIFICATIONS AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          treatment_section: true,
          duration: 5,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          next_step: 'applicant_can_get_card',
          applicant_submit_document: 'du retrait de la nouvelle carte profesionnelle',
        },
        {
          status: 'applicant_can_get_card',
          etape: 'RETRAIT DE LA NOUVELLE CARTE PAR LE REQUÉRANT',
          action_label: 'NOTIFIER LE RETRAIT DE LA NOUVELLE CARTE PROFESSIONNELLE',
          class: 'relief-dark',
          treatment_section: true,
          duration: 5,
          final_state: true,
        },
      ],
      applicant_submit_document: true,
    },
    {
      title: 'requests.affectation.title',
      code_demande: 'UEGT_changement_affectation',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        /*  form: "FormEntreesFrontieresTerrestres",
         preview: "PreviewEntreesFrontieresTerrestres", */
        details: 'DetailsUEGT_affectation',
        pv: 'PV_UEGT_affectation',
        checklist: 'CheckList_UEGT_affectation',
      },
      code_client: '0125456789',
      sector: 'UEGT',
      sector_label: 'Guide de tourisme',
      resource: 'demandes',

      // description:
      //   "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      montant_demande: 100,
      demande_link: 'https://pprod.service-public.bj/public/services/service/PS01292',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      step_before_ot_decisions: 'deliberation_session_check',
      process_steps: [ // UEGT_changement_affectation
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          next_step: 'treatment_start',
          class: 'relief-dark',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITETEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME DE LA DEMANDE ÉFFECTUÉE',
          action_label: 'TERMINER LE CONTRÔLE DE LA FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          request_update_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_process_technical_control',
        },
        // ---------------------------------------------
        {
          status: 'in_process_technical_control',
          etape: "DÉBUT DU PROCESSUS DU CHANGEMENT D'AFFECTATION",
          action_label: "LANCER LE PROCESSUS DU CHANGEMENT D'AFFECTATION",
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          // show_result: 'have_metting_with_applicant',
          metting_with_applicant: true,
          is_ot_validation: true,
          next_step: 'deliberation_session_check',
        },
        {
          status: 'deliberation_session_check',
          etape: 'SESSION DE DÉLIBÉRATION TENUE',
          action_label: 'LANCER LE PROCESSUS DE DÉLIBÉRATION',
          class: 'relief-dark',
          treatment_section: true,
          ministerial_decree_step: true,
          next_decisions_options_count: 2,
          is_ot_validation: true,
        },
        // ----- decisions OT
        {
          status: 'unfavourable_agreement',
          etape: "ACCORD DÉFAVORABLE DE CHANGEMENT D'AFFECTATION",
          action_label: "REJETER LE CHANGEMENT D'AFFECTATION",
          class: 'relief-danger',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'favourable_agreement',
          etape: "ACCORD FAVORABLE DE CHANGEMENT D'AFFECTATION",
          action_label: "ACCEPTER LE CHANGEMENT D'AFFECTATION",
          class: 'relief-primary',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ---------------------------------------------
        {
          status: 'transfert_pv_deliberation_to_minister',
          etape: 'TRANSFÉRER LE PV DE DÉLIBÉRATION AU MINISTRE',
          action_label: 'TRANSFÉRER LES RÉSULTATS AU MINISTRE',
          class: 'relief-dark',
          duration: 3,
          treatment_section: true,
          ministerial_decree_step: true,
          hide_on_ministerial_decree_step: true,
          next_decisions_options_count: 2,
          is_ot_validation: true,
        },
        // ----- decisions minister
        {
          status: 'minister_unfavourable_agreement',
          etape: "ACCORD DÉFAVORABLE DE CHANGEMENT D'AFFECTATION DU MINISTRE",
          action_label: "REJETER LE CHANGEMENT D'AFFECTATION",
          class: 'relief-danger',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_unfavourable_agreement_minister',
          is_minister_validation: true,
          duration: 10,
          treatment_section: true,
        },
        {
          status: 'minister_favourable_agreement',
          etape: "ACCORD FAVORABLE DE CHANGEMENT D'AFFECTATION DU MINISTRE",
          action_label: "ACCORDER LE CHANGEMENT D'AFFECTATION",
          class: 'relief-primary',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_favourable_agreement_minister',
          is_minister_validation: true,
          duration: 10,
          treatment_section: true,
        },
        // ---------------------------------------------

        { // accusé reception décisions du ministre
          status: 'acknowledgement_of_receipt_minister_decisions',
          etape: 'ACCUSÉ DE RÉCEPTION DES DÉCISIONS DU MINISTRE',
          action_label: 'ACCUSER RÉCEPTION DES DÉCISIONS DU MINISTRE',
          class: 'relief-dark',
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          go_to: 'transfert_pv_deliberation_to_minister',
          offset: 1,
        },
        { // notification accord défavorable ministre
          status: 'user_notification_unfavourable_agreement_minister',
          etape: 'NOTIFICATIONS AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          parent_process: 'acknowledgement_of_receipt_minister_unfavourable_agreement',
          treatment_section: true,
          duration: 5,
          final_state: true,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
        },

        { // notification accord favorable ministre
          status: 'user_notification_favourable_agreement_minister',
          etape: 'NOTIFICATIONS AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          treatment_section: true,
          duration: 5,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          next_step: 'applicant_can_get_card',
          applicant_submit_document: 'du retrait de la nouvelle carte profesionnelle avec la nouvelle affectation',
        },
        {
          status: 'applicant_can_get_card',
          etape: 'RETRAIT DE LA CARTE PAR LE REQUÉRANT',
          action_label: 'NOTIFIER LE RETRAIT DE LA CARTE PROFESSIONNELLE',
          class: 'relief-dark',
          treatment_section: true,
          duration: 5,
          final_state: true,
        },
      ],
      applicant_submit_document: true,
      // have_metting_with_applicant: true,
      // have_morality_investigation: true,
    },
    {
      title: 'requests.speciality.title',
      code_demande: 'UEGT_ajout_specialite',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        /*  form: "FormEntreesFrontieresTerrestres",
         preview: "PreviewEntreesFrontieresTerrestres", */
        details: 'DetailsUEGT_specialite',
        pv: 'PV_UEGT_specialite',
        checklist: 'CheckList_UEGT_specialite',
      },
      code_client: '4123456789',
      sector: 'UEGT',
      sector_label: 'Guide de tourisme',
      resource: 'demandes',

      // description:
      //   "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      montant_demande: 100,
      demande_link: 'https://pprod.service-public.bj/public/services/service/PS01294',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      step_before_ot_decisions: 'deliberation_session_check',
      process_steps: [ // UEGT_ajout_specialite
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          next_step: 'treatment_start',
          class: 'relief-dark',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITETEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME DE LA DEMANDE ÉFFECTUÉE',
          action_label: 'TERMINER LE CONTRÔLE DE LA FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          request_update_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_process_technical_control',
        },
        // ---------------------------------------------
        {
          status: 'in_process_technical_control',
          etape: "DÉBUT DU PROCESSUS D'AJOUT DE LA SPÉCIALITÉ",
          action_label: "LANCER LE PROCESSUS D'AJOUT DE LA SPÉCIALITÉ",
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          // show_result: 'have_metting_with_applicant',
          metting_with_applicant: true,
          is_ot_validation: true,
          next_step: 'deliberation_session_check',
        },
        {
          status: 'deliberation_session_check',
          etape: 'SESSION DE DÉLIBÉRATION TENUE',
          action_label: 'LANCER LE PROCESSUS DE DÉLIBÉRATION',
          class: 'relief-dark',
          treatment_section: true,
          ministerial_decree_step: true,
          next_decisions_options_count: 2,
          is_ot_validation: true,
        },
        // ----- decisions OT
        {
          status: 'unfavourable_agreement',
          etape: "ACCORD DÉFAVORABLE D'AJOUT DE LA SPÉCIALITÉ",
          action_label: "REJETER LA NOUVELLE SPÉCIALITÉ",
          class: 'relief-danger',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'favourable_agreement',
          etape: "ACCORD FAVORABLE D'AJOUT DE LA SPÉCIALITÉ",
          action_label: "ACCEPTER LA NOUVELLE SPÉCIALITÉ",
          class: 'relief-primary',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ---------------------------------------------
        {
          status: 'transfert_pv_deliberation_to_minister',
          etape: 'TRANSFÉRER LE PV DE DÉLIBÉRATION AU MINISTRE',
          action_label: 'TRANSFÉRER LES RÉSULTATS AU MINISTRE',
          class: 'relief-dark',
          duration: 3,
          treatment_section: true,
          ministerial_decree_step: true,
          hide_on_ministerial_decree_step: true,
          next_decisions_options_count: 2,
          is_ot_validation: true,
        },
        // ----- decisions minister
        {
          status: 'minister_unfavourable_agreement',
          etape: "ACCORD DÉFAVORABLE D'AJOUT DE LA SPÉCIALITÉ",
          action_label: "REJETER LA NOUVELLE SPÉCIALITÉ",
          class: 'relief-danger',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_unfavourable_agreement_minister',
          is_minister_validation: true,
          duration: 10,
          treatment_section: true,
        },
        {
          status: 'minister_favourable_agreement',
          etape: "ACCORD FAVORABLE D'AJOUT DE LA SPÉCIALITÉ",
          action_label: "ACCEPTER LA NOUVELLE SPÉCIALITÉ",
          class: 'relief-primary',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_favourable_agreement_minister',
          is_minister_validation: true,
          duration: 10,
          treatment_section: true,
        },
        // ---------------------------------------------

        { // accusé reception décisions du ministre
          status: 'acknowledgement_of_receipt_minister_decisions',
          etape: 'ACCUSÉ DE RÉCEPTION DES DÉCISIONS DU MINISTRE',
          action_label: 'ACCUSER RÉCEPTION DES DÉCISIONS DU MINISTRE',
          class: 'relief-dark',
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          go_to: 'transfert_pv_deliberation_to_minister',
          offset: 1,
        },
        { // notification accord défavorable ministre
          status: 'user_notification_unfavourable_agreement_minister',
          etape: 'NOTIFICATIONS AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          parent_process: 'acknowledgement_of_receipt_minister_unfavourable_agreement',
          treatment_section: true,
          duration: 5,
          final_state: true,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
        },

        { // notification accord favorable ministre
          status: 'user_notification_favourable_agreement_minister',
          etape: 'NOTIFICATIONS AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          treatment_section: true,
          duration: 5,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          next_step: 'applicant_can_get_card',
          applicant_submit_document: 'du retrait de la carte profesionnelle avec la nouvelle spécialité',
        },
        {
          status: 'applicant_can_get_card',
          etape: 'RETRAIT DE LA CARTE PAR LE REQUÉRANT',
          action_label: 'NOTIFIER LE RETRAIT DE LA CARTE PROFESSIONNELLE',
          class: 'relief-dark',
          treatment_section: true,
          duration: 5,
          final_state: true,
        },
      ],
      applicant_submit_document: true,
      // have_metting_with_applicant: true,
      // have_morality_investigation: true,
    },
    // UER -------------------------------------------------------------------------------------------
    {
      title: 'requests.operating_permit.title',
      code_demande: 'UER_autorisation',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        details: 'DetailsUER_autorisation',
        pv: 'PV_UER_renouvellement',
        checklist: 'CheckList_UER_renouvellement',
      },
      code_client: '6789012343',
      sector: 'UER',
      sector_label: 'Etablissement de restauration et assimilés',
      resource: 'demandes',
      montant_demande: 100,
      demande_link: 'https://pprod.service-public.bj/public/services/service/PS01297',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      step_before_ot_decisions: 'deliberation_session_check',
      process_steps: [ // UER_autorisation
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          next_step: 'treatment_start',
          class: 'relief-dark',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME DE LA DEMANDE ÉFFECTUÉE',
          action_label: 'TERMINER LE CONTRÔLE DE LA FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          request_update_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_process_technical_control',
        },
        // ---------------------------------------------
        {
          status: 'in_process_technical_control',
          etape: 'PROCESSUS CONTRÔL TECHNIQUE',
          action_label: "LANCER LE PROCESSUS DE CONTRÔLE TECHNIQUE",
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_step: 'technical_control_check',
          show_result: 'have_autorisation_visit',
          visite_autorization: true,
          is_ot_validation: true,
        },
        {
          status: 'technical_control_check',
          etape: 'VISITE TECHNIQUE ÉFFECTUÉE',
          action_label: 'CONFIRMER LA RÉALISATION DE LA VISITE TECHNIQUE',
          class: 'relief-dark',
          parent_process: 'in_process_technical_control',
          treatment_section: true,
          next_step: 'deliberation_session_check',
          is_ot_validation: true,
        },
        {
          status: 'deliberation_session_check',
          etape: 'SESSION DE DÉLIBÉRATION TENUE',
          action_label: 'LANCER LE PROCESSUS DE DÉLIBÉRATION',
          class: 'relief-dark',
          parent_process: 'in_process_technical_control',
          treatment_section: true,
          next_decisions_options_count: 3,
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ----- decisions OT
        {
          status: 'unfavourable_agreement',
          etape: 'ACCORD DÉFAVORABLE DE L\'ORGANE TECHNIQUE',
          action_label: "REFUSER LA DEMANDE",
          class: 'relief-danger',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'favourable_agreement_with_conditions',
          etape: 'ACCORD FAVORABLE AVEC RÉSERVES DE L\'ORGANE TECHNIQUE',
          action_label: "ACCEPTER LA DEMANDE SOUS RÉSERVE",
          class: 'relief-warning',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'favourable_agreement',
          etape: 'ACCORD FAVORABLE  DE L\'ORGANE TECHNIQUE',
          action_label: "ACCEPTER LA DEMANDE ",
          class: 'relief-primary',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ---------------------------------------------
        {
          status: 'transfert_pv_deliberation_to_minister',
          etape: 'TRANSFÉRER LE PV DE DÉLIBÉRATION AU MINISTRE',
          action_label: 'TRANSFÉRER LES RÉSULTATS AU MINISTRE',
          class: 'relief-dark',
          duration: 5,
          next_decisions_options_count: 3,
          treatment_section: true,
          is_ot_validation: true,
          ministerial_decree_step: true,
        },
        // ----- decisions minister
        {
          status: 'minister_unfavourable_agreement',
          etape: "ACCORD DÉFAVORABLE DU MINISTRE",
          action_label: "DÉCISION DÉFAVORABLE",
          class: 'relief-danger',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_unfavourable_agreement',
          is_minister_validation: true,
        },
        {
          status: 'minister_favourable_agreement_with_conditions',
          etape: 'ACCORD FAVORABLE DU MINISTRE SOUS RÉSERVES',
          action_label: 'DÉCISION FAVORABLE SOUS RÉSERVE',
          class: 'relief-warning',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_favourable_agreement_with_conditions',
          is_minister_validation: true,
        },
        {
          status: 'minister_favourable_agreement',
          etape: 'ACCORD FAVORABLE DU MINISTRE',
          action_label: 'DÉCISION FAVORABLE',
          class: 'relief-primary',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_favourable_agreement',
          is_minister_validation: true,
        },
        // ---------------------------------------------

        { // accusé reception décisions du ministre
          status: 'acknowledgement_of_receipt_minister_decisions',
          etape: 'ACCUSÉ DE RÉCEPTION DES DÉCISIONS DU MINISTRE',
          action_label: 'ACCUSER RÉCEPTION DES DÉCISIONS DU MINISTRE',
          class: 'relief-dark',
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          go_to: 'transfert_pv_deliberation_to_minister',
          offset: 1,
        },

        { // notification accord défavorable ministre
          status: 'user_notification_unfavourable_agreement',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          parent_process: 'acknowledgement_of_receipt_minister_unfavourable_agreement',
          treatment_section: true,
          final_state: true,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          send_mail: true,
        },
        { // notification accord favorable ministre suivant intégration correction
          status: 'user_notification_favourable_agreement_with_conditions',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          next_step: 'corrections',
          next_step_is_client_validation: 'corrections',
          send_mail: true,
        },
        { // notification accord favorable ministre
          status: 'user_notification_favourable_agreement',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          treatment_section: true,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          next_step_is_client_validation: 'opening',
          next_step: 'opening',
          send_mail: true,
        },

        { // validation prise en compte corrections par client
          status: 'corrections',
          etape: 'PRISE EN COMPTE DES RECOMMANDATIONS DU COMMITÉ TECHNIQUE',
          action_label: 'CONFIRMER LA PRISE EN COMPTE DES RECOMMANDATIONS DU COMMITÉ TECHNIQUE',
          class: 'relief-dark',
          treatment_section: true,
          duration: 90,
          next_decisions_options_count: 2,
          is_applicant_validation: true,
        },
        // ----- decisions sur intégration des corrections
        {
          status: 'rejection_integration_recommendations',
          etape:
            'REJET DE LA PRISE EN COMPTE DES RECOMMANDATIONS PAR LE DEMANDEUR ET NOTIFICATION AU REQUÉRANT',
          action_label:
            'CONFIRMER LA NON PRISE EN COMPTE DES RECOMMANDATIONS PAR LE DEMANDEUR',
          class: 'relief-danger',
          decision: true,
          final_state: true,
        },
        {
          status: 'validation_integration_recommendations',
          etape: 'VALIDATION DE LA PRISE EN COMPTE DES RECOMMANDATIONS PAR LE DEMANDEUR',
          action_label: 'CONFIRMER LA PRISE EN COMPTE DES RECOMMANDATIONS PAR LE DEMANDEUR ET NOTIFIACTION AU REQUÉRANT AVEC DÉLIVRANCE DU DOCUMENT FINAL',
          class: 'relief-primary',
          decision: true,
          next_step: 'opening',
          next_step_is_client_validation: 'opening',
        },
        // ---------------------------------------------

        { // validation ouverture etab par client
          status: 'opening',
          etape: "OUVERTURE DE L'ÉTABLISSEMENT",
          action_label: "CONFIRMER L'OUVERTURE DE L'ÉTABLILSSEMENT",
          class: 'relief-dark',
          treatment_section: true,
          duration: 90,
          next_decisions_options_count: 2,
          is_applicant_validation: true,
        },
        // ----- decisions sur ouverture
        {
          status: 'rejection_opening',
          etape: "NON CONFIRMATION DE L'OUVERTURE DE L'ÉTABLISSEMENT",
          action_label: "INFIRMER L'OUVERTURE DE L'ÉTABLISSEMENT",
          class: 'relief-danger',
          decision: true,
          final_state: true,
        },
        {
          status: 'validation_opening',
          etape: "CONFIRMATION DE L'OUVERTURE DE L'ÉTABLISSEMENT",
          action_label: "CONFIRMER L'OUVERTURE DE L'ÉTABLISSEMENT",
          class: 'relief-primary',
          decision: true,
          next_step: 'in_classification_process',
          send_mail: true,
          final_state: true,
        },
        // ------------------------------------------------------------------------------------------
      ],
      have_autorisation_visit: true,
    },
    {
      title: 'requests.classification.title',
      code_demande: 'UER_classement',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        // form: "FormEntreesFrontieresTerrestres",
        // preview: "PreviewEntreesFrontieresTerrestres",
        details: 'DetailsUER_classement',
        pv: 'PV_UER_classement',
        checklist: 'CheckList_UER_classement',
      },
      code_client: '5638601234',
      sector: 'UER',
      sector_label: 'Etablissement de restauration et assimilés',
      resource: 'demandes',

      // description: "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      montant_demande: 100,
      demande_link: 'https://pprod.service-public.bj/public/services/service/PS01299',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      step_before_ot_decisions: 'deliberation_session_check',
      process_steps: [ // UER_classement
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          next_step: 'treatment_start',
          class: 'relief-dark',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME DE LA DEMANDE ÉFFECTUÉE',
          action_label: 'TERMINER LE CONTRÔLE DE LA FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          request_update_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_process_technical_control',
        },

        {
          status: 'in_process_technical_control',
          etape: 'PROCESSUS DE CLASSEMENT',
          action_label: 'LANCER LE PROCESSUS DE CLASSEMENT',
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_step: 'classification_repport_check',
          visite_classification: true,
          show_result: 'have_classification_visit',
          is_ot_validation: true,
        },

        {
          status: 'classification_repport_check',
          etape: 'DÉPOT DU PV DE LA VISITE DE CLASSEMENT',
          action_label: 'CONFIRMER LE DEPÔT DU PV DE LA VISITE DE CLASSEMENT',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          next_step: 'deliberation_session_check',
          is_ot_validation: true,
        },
        // gestion commission examen ------------
        {
          status: 'deliberation_session_check',
          etape: 'SESSION DE DÉLIBÉRATION TENUE',
          action_label: 'LANCER LE PROCESSUS DE DÉLIBÉRATION',
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_decisions_options_count: 2,
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ----- decisions OT
        {
          status: 'classification_unfavourable_agreement',
          etape: 'ACCORD DÉFAVORABLE DE L\'ORGANE TECHNIQUE',
          action_label: 'REFUSER LA CLASSIFICATION SOUHAITÉE',
          class: 'relief-danger',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'classification_favourable_agreement',
          etape: 'ACCORD FAVORABLE  DE L\'ORGANE TECHNIQUE',
          action_label: 'ACCEPTER LA CLASSIFICATION SOUHAITÉE',
          class: 'relief-primary',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // --------------------------------

        {
          status: 'transfert_pv_deliberation_to_minister',
          etape: 'TRANSFERT DES RÉSULTATS AU MINISTRE POUR DÉCISION FINALE',
          action_label: 'CONFIRMER LE TRANSFERT DES RÉSULTATS AU MINISTRE POUR DÉCISION FINALE',
          class: 'relief-dark',
          treatment_section: true,
          next_decisions_options_count: 2,
          hide_on_ministerial_decree_step: true,
          is_ot_validation: true,
        },

        // ----- decisions d'attribution de classement du ministre
        {
          status: 'minister_reject_classsification',
          etape: 'REJET DE LA DEMANDE DE CLASSEMENT',
          action_label: 'NE PAS ACCORDER LE CLASSEMENT AU REQUÉRANT',
          class: 'relief-danger',
          decision: true,
          next_step: 'user_notification_final_classification_fail',
          is_minister_validation: true,
          ministerial_decree_step: true,
        },
        {
          status: 'minister_accept_classsification',
          etape: 'ATTRIBUTION DE LA DEMANDE DE CLASSEMENT',
          action_label: 'ACCORDER LE CLASSEMENT AU REQUÉRANT',
          class: 'relief-primary',
          decision: true,
          next_step: 'user_notification_final_classification_success',
          is_minister_validation: true,
          ministerial_decree_step: true,
        },
        // --------------------------------
        { // accusé reception décisions du ministre
          status: 'acknowledgement_of_receipt_minister_decisions',
          etape: 'ACCUSÉ DE RÉCEPTION DES DÉCISIONS DU MINISTRE',
          action_label: 'ACCUSER RÉCEPTION DES DÉCISIONS DU MINISTRE',
          class: 'relief-dark',
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          go_to: 'transfert_pv_deliberation_to_minister',
          offset: 1,
        },

        { // notification AU REQUÉRANT de la decision de classement fail
          status: 'user_notification_final_classification_fail',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION DE CLASSEMENT',
          action_label: 'NOTIFIER AU REQUÉRANT LA DÉCISION DE CLASSEMENT',
          class: 'relief-dark',
          send_mail: true,
          final_state: true,
          ministerial_decree_step: true,
        },
        { // notification AU REQUÉRANT de la decision de classement success
          status: 'user_notification_final_classification_success',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION DE CLASSEMENT',
          action_label: 'NOTIFIER AU REQUÉRANT LA DÉCISION DE CLASSEMENT',
          class: 'relief-dark',
          treatment_section: true,
          send_mail: true,
          next_step: 'applicant_get_banner',
          ministerial_decree_step: true,
          applicant_submit_document: 'du retrait du panonceau',
        },
        // end NOTIFICATION
        {
          status: 'applicant_get_banner',
          etape: 'RETRAIT DU PANNONCEAU PAR LE DEMANDEUR',
          action_label: 'CONFIRMER LE RETRAIT DU PONNONCEAU PAR LE DEMANDEUR ',
          class: 'relief-primary',
          final_state: true,
        },
      ],
      have_classification_visit: true,
      applicant_submit_document: true,
    },
    {
      title: 'requests.renewal_permit.title',
      code_demande: 'UER_renew_autorisation',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        // form: "FormEntreesFrontieresTerrestres",
        // preview: "PreviewEntreesFrontieresTerrestres",
        details: 'DetailsUER_renouvellement',
        pv: 'PV_UER_renouvellement',
        checklist: 'CheckList_UER_renouvellement',
      },
      code_client: '6789412343',
      sector: 'UER',
      sector_label: 'Etablissement de restauration et assimilés',
      resource: 'demandes',

      // description: "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      montant_demande: 100,
      demande_link: 'https://pprod.service-public.bj/public/services/service/PS01297',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      step_before_ot_decisions: 'deliberation_session_check',
      process_steps: [ // UER_renew_autorisation
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          next_step: 'treatment_start',
          class: 'relief-dark',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME DE LA DEMANDE ÉFFECTUÉE',
          action_label: 'TERMINER LE CONTRÔLE DE LA FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          request_update_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_process_technical_control',
        },
        // ---------------------------------------------
        {
          status: 'in_process_technical_control',
          etape: 'PROCESSUS CONTRÔL TECHNIQUE',
          action_label: "LANCER LE PROCESSUS DE RENOUVELLEMENT DE L'AUTORISATION D'EXPLOITATION",
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_step: 'technical_control_check',
          visite_autorization: true,
          is_ot_validation: true,
        },
        {
          status: 'technical_control_check',
          etape: 'VISITE TECHNIQUE ÉFFECTUÉE',
          action_label: 'CONFIRMER LA RÉALISATION DE LA VISITE TECHNIQUE',
          class: 'relief-dark',
          parent_process: 'in_process_technical_control',
          treatment_section: true,
          next_step: 'deliberation_session_check',
          is_ot_validation: true,
        },
        {
          status: 'deliberation_session_check',
          etape: 'SESSION DE DÉLIBÉRATION TENUE',
          action_label: 'LANCER LE PROCESSUS DE DÉLIBÉRATION',
          class: 'relief-dark',
          parent_process: 'in_process_technical_control',
          treatment_section: true,
          next_decisions_options_count: 3,
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ----- decisions OT
        {
          status: 'unfavourable_agreement',
          etape: 'ACCORD DÉFAVORABLE DE L\'ORGANE TECHNIQUE',
          action_label: "REFUSER LE RENOUVELLEMENT DE L'AUTORISATION D'EXPLOITATION",
          class: 'relief-danger',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'favourable_agreement_with_conditions',
          etape: 'ACCORD FAVORABLE AVEC RÉSERVES DE L\'ORGANE TECHNIQUE',
          action_label: "ACCORDER LE RENOUVELLEMENT L'AUTORISATION D'EXPLOITATION SOUS RÉSERVE DE CONSTATATION DE LA CONFORMITÉ DE L'ÉTABLISSEMENT AUX RECOMMANDATIONS",
          class: 'relief-warning',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'favourable_agreement',
          etape: 'ACCORD FAVORABLE  DE L\'ORGANE TECHNIQUE',
          action_label: "ACCEPTER LE RENOUVELLEMENT DE L'AUTORISATION D'EXPLOITATION SANS RÉSERVE",
          class: 'relief-primary',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ---------------------------------------------
        {
          status: 'transfert_pv_deliberation_to_minister',
          etape: 'TRANSFÉRER LE PV DE DÉLIBÉRATION AU MINISTRE',
          action_label: 'TRANSFÉRER LES RÉSULTATS AU MINISTRE',
          class: 'relief-dark',
          duration: 5,
          next_decisions_options_count: 3,
          treatment_section: true,
          is_ot_validation: true,
          ministerial_decree_step: true,
        },
        // ----- decisions minister
        {
          status: 'minister_unfavourable_agreement',
          etape: 'ACCORD DÉFAVORABLE DU MINISTRE ',
          action_label: "REFUSER LE RENOUVELLEMENT L'AUTORISATION D'EXPLOITATION",
          class: 'relief-danger',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_unfavourable_agreement',
          is_minister_validation: true,
        },
        {
          status: 'minister_favourable_agreement_with_conditions',
          etape: 'ACCORD FAVORABLE DU MINISTRE SOUS RÉSERVES',
          action_label: 'ACCORDER LE RENOUVELLEMENT DE L\'AUTORISATION D\'EXPLOITATION SOUS RÉSERVE DE CONSTATATION DE LA CONFORMITÉ DE L\'ÉTABLISSEMENTS AUX RECOMMANDATIONS',
          class: 'relief-warning',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_favourable_agreement_with_conditions',
          is_minister_validation: true,
        },
        {
          status: 'minister_favourable_agreement',
          etape: 'ACCORD FAVORABLE DU MINISTRE',
          action_label: 'ACCORDER LE RENOUVELLEMENT DE L\'AUTORISATION D\'EXPLOITATION',
          class: 'relief-primary',
          decision: true,
          ministerial_decree_step: true,
          next_step: 'user_notification_favourable_agreement',
          is_minister_validation: true,
        },
        // ---------------------------------------------

        { // accusé reception décisions du ministre
          status: 'acknowledgement_of_receipt_minister_decisions',
          etape: 'ACCUSÉ DE RÉCEPTION DES DÉCISIONS DU MINISTRE',
          action_label: 'ACCUSER RÉCEPTION DES DÉCISIONS DU MINISTRE',
          class: 'relief-dark',
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          go_to: 'transfert_pv_deliberation_to_minister',
          offset: 1,
        },


        { // notification accord défavorable ministre
          status: 'user_notification_unfavourable_agreement',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          treatment_section: true,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          send_mail: true,
          final_state: true,
        },
        { // notification accord favorable ministre suivant intégration correction
          status: 'user_notification_favourable_agreement_with_conditions',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION',
          action_label: 'NOTIFIER AUX DEMANDEURS LA DECISION',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          next_step: 'corrections',
          next_step_is_client_validation: 'corrections',
          send_mail: true,
        },
        { // notification accord favorable ministre
          status: 'user_notification_favourable_agreement',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION AVEC DÉLIVRANCE DU DOCUMENT FINAL',
          action_label: 'NOTIFIER AU REQUÉRANT LA DECISION ET DÉLIVRER LE DOCUMENT FINAL',
          class: 'relief-dark',
          treatment_section: true,
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          send_mail: true,
          final_state: true,
        },

        { // validation prise en compte corrections par client
          status: 'corrections',
          etape: 'PRISE EN COMPTE DES RECOMMANDATIONS DU COMMITÉ TECHNIQUE',
          action_label:
            'CONFIRMER LA PRISE EN COMPTE DES RECOMMANDATIONS DU COMMITÉ TECHNIQUE',
          class: 'relief-dark',
          treatment_section: true,
          duration: 90,
          next_decisions_options_count: 2,
          is_applicant_validation: true,
        },
        // ----- decisions sur intégration des corrections
        {
          status: 'rejection_integration_recommendations',
          etape:
            'REJET DE LA PRISE EN COMPTE DES RECOMMANDATIONS PAR LE DEMANDEUR ET NOTIFICATION AU REQUÉRANT',
          action_label:
            'CONFIRMER LA NON PRISE EN COMPTE DES RECOMMANDATIONS PAR LE DEMANDEUR',
          class: 'relief-danger',
          decision: true,
          final_state: true,
        },
        {
          status: 'validation_integration_recommendations',
          etape: 'VALIDATION DE LA PRISE EN COMPTE DES RECOMMANDATIONS PAR LE DEMANDEUR',
          action_label: 'CONFIRMER LA PRISE EN COMPTE DES RECOMMANDATIONS PAR LE DEMANDEUR ET NOTIFIACTION AU REQUÉRANT AVEC DÉLIVRANCE DU DOCUMENT FINAL',
          class: 'relief-primary',
          decision: true,
          final_state: true,
        },
      ],
      have_autorisation_visit: true,
    },
    {
      title: 'requests.renewal_classification.title',
      code_demande: 'UER_renew_classement',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        // form: "FormEntreesFrontieresTerrestres",
        // preview: "PreviewEntreesFrontieresTerrestres",
        details: 'DetailsUER_renouvellement_classement',
        pv: 'PV_UER_renouvellement_classement',
        checklist: 'CheckList_UER_renouvellement_classement',
      },
      code_client: '78901234560',
      sector: 'UER',
      sector_label: 'Etablissement de restauration et assimilés',
      resource: 'demandes',

      // description:
      //   "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      montant_demande: 100,
      demande_link: 'https://pprod.service-public.bj/public/services/service/PS01300',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      process_steps: [ // UER_renew_classement
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          next_step: 'treatment_start',
          class: 'relief-dark',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME DE LA DEMANDE ÉFFECTUÉE',
          action_label: 'TERMINER LE CONTRÔLE DE LA FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          request_update_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_process_technical_control',
        },
        // ---------------------------------------------
        // reclassement ----------------------------------------------------------------
        {
          status: 'in_process_technical_control',
          etape: 'PROCESSUS DE RECLASSEMENT',
          action_label: 'LANCER LE PROCESSUS DE RECLASSEMENT',
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_step: 'reclassification_control_check',
          show_result: 'have_classification_visit',
          visite_classification: true,
          is_ot_validation: true,
        },
        {
          status: 'reclassification_control_check',
          etape: 'VISITE DE RECLASSEMENT ÉFFECTUÉE',
          action_label: 'CONFIRMER LA RÉALISATION DE LA VISITE DE RECLASSEMENT',
          class: 'relief-primary',
          parent_process: 'in_process_technical_control',
          treatment_section: true,
          next_step: 'reclassification_repport_check',
          is_ot_validation: true,
        },

        {
          status: 'reclassification_repport_check',
          etape: 'DÉPOT DU PV DE LA VISITE DE RECLASSEMENT',
          action_label: 'CONFIRMER LE DEPÔT DU PV DE LA VISITE DE RECLASSEMENT',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          next_step: 'deliberation_session_check',
          is_ot_validation: true,
        },
        // gestion commission examen ------------
        {
          status: 'deliberation_session_check',
          etape: 'SESSION DE DÉLIBÉRATION TENUE',
          action_label: 'LANCER LE PROCESSUS DE DÉLIBÉRATION',
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_decisions_options_count: 2,
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // ----- decisions OT
        {
          status: 'reclassification_unfavourable_agreement',
          etape: 'ACCORD DÉFAVORABLE DE L\'ORGANE TECHNIQUE',
          action_label: 'REFUSER LA RECLASSIFICATION SOUHAITÉE',
          class: 'relief-danger',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        {
          status: 'reclassification_favourable_agreement',
          etape: 'ACCORD FAVORABLE  DE L\'ORGANE TECHNIQUE',
          action_label: 'ACCEPTER LA RECLASSIFICATION SOUHAITÉE',
          class: 'relief-primary',
          decision: true,
          next_step: 'transfert_pv_deliberation_to_minister',
          ministerial_decree_step: true,
          is_ot_validation: true,
        },
        // --------------------------------

        {
          status: 'transfert_pv_deliberation_to_minister',
          etape: 'TRANSFERT DES RÉSULTATS AU MINISTRE POUR DÉCISION FINALE',
          action_label: 'CONFIRMER LE TRANSFERT DES RÉSULTATS AU MINISTRE POUR DÉCISION FINALE',
          class: 'relief-dark',
          treatment_section: true,
          next_decisions_options_count: 2,
          is_ot_validation: true,
          hide_on_ministerial_decree_step: true,
        },

        // ----- decisions d'attribution de reclassement du ministre
        {
          status: 'minister_reject_reclasssification',
          etape: 'REJET DE LA DEMANDE DE RECLASSEMENT',
          action_label: 'NE PAS ACCORDER LE RECLASSEMENT AU REQUÉRANT',
          class: 'relief-danger',
          decision: true,
          next_step: 'user_notification_final_reclassification_fail',
          is_minister_validation: true,
          ministerial_decree_step: true,
        },
        {
          status: 'minister_accept_classsification',
          etape: 'ATTRIBUTION DE LA DEMANDE DE RECLASSEMENT',
          action_label: 'ACCORDER LE RECLASSEMENT AU REQUÉRANT',
          class: 'relief-primary',
          decision: true,
          next_step: 'user_notification_final_reclassification_success',
          is_minister_validation: true,
          ministerial_decree_step: true,
        },
        // --------------------------------

        { // accusé reception décisions du ministre
          status: 'acknowledgement_of_receipt_minister_decisions',
          etape: 'ACCUSÉ DE RÉCEPTION DES DÉCISIONS DU MINISTRE',
          action_label: 'ACCUSER RÉCEPTION DES DÉCISIONS DU MINISTRE',
          class: 'relief-dark',
          ministerial_decree_step: true,
          set_ministerial_decree_number: true,
          go_to: 'transfert_pv_deliberation_to_minister',
          offset: 1,
        },

        { // notification AU REQUÉRANT de la decision de classement fail
          status: 'user_notification_final_reclassification_fail',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION DE RECLASSEMENT',
          action_label: 'NOTIFIER AU REQUÉRANT LA DÉCISION DE RECLASSEMENT',
          class: 'relief-dark',
          send_mail: true,
          final_state: true,
          ministerial_decree_step: true,
        },
        { // notification AU REQUÉRANT de la decision de classement success
          status: 'user_notification_final_reclassification_success',
          etape: 'NOTIFICATION AU REQUÉRANT DE LA DÉCSION DE RECLASSEMENT',
          action_label: 'NOTIFIER AU REQUÉRANT LA DÉCISION DE RECLASSEMENT',
          class: 'relief-dark',
          treatment_section: true,
          next_step: 'applicant_get_banner',
          send_mail: true,
          ministerial_decree_step: true,
          applicant_submit_document: 'du retrait du nouveau panonceau',
        },
        // end NOTIFICATION
        {
          status: 'applicant_get_banner',
          etape: 'RETRAIT DU PANNONCEAU PAR LE DEMANDEUR',
          action_label: 'CONFIRMER LE RETRAIT DU NOUVEAU PONNONCEAU PAR LE DEMANDEUR ',
          class: 'relief-primary',
          final_state: true,
        },
      ],
      have_classification_visit: true,
      applicant_submit_document: true,
    },
    {
      title: 'requests.various_notifications.title',
      code_demande: 'UER_notification',
      isLaIcon: true,
      laIcon: 'la-folder',
      featherIcon: 'ShieldIcon',
      canBeSHownOnDashboard: true,
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        // form: "FormEntreesFrontieresTerrestres",
        // preview: "PreviewEntreesFrontieresTerrestres",
        details: 'DetailsUER_notification',
        pv: 'PV_UER_notification',
        checklist: 'CheckList_UER_notification',
      },
      code_client: '8901234567',
      sector: 'UER',
      sector_label: 'Etablissement de restauration et assimilés',
      resource: 'demandes',

      // description:
      //   "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      montant_demande: 100,
      demande_link: 'https://pprod.service-public.bj/public/services/service/PS01298',
      attributs_editables: [
        'type_etablissement',
        'unite_logement',
        'classement',
      ],
      process_steps: [
        {
          status: 'on_hold',
          etape: 'ENREGISTRÉ, EN ATTENTE DE TRAITEMENT',
          action_label: 'TRAITER LA DEMANDE',
          next_step: 'treatment_start',
          class: 'relief-dark',
        },
        {
          status: 'treatment_start',
          etape: 'TRAITEMENT DÉBUTÉ',
          action_label: 'COMMENCER LE TRAITEMENT DE LA DEMANDE',
          class: 'relief-dark',
          next_step: 'form_control',
          request_update_section: true,
        },
        {
          status: 'form_control',
          etape: 'VÉRIFICATION DE LA FORME DE LA DEMANDE ÉFFECTUÉE',
          action_label: 'TERMINER LE CONTRÔLE DE LA FORME DE LA DEMANDE',
          class: 'relief-dark',
          duration: 5,
          treatment_section: true,
          request_update_section: true,
          next_decisions_options_count: 2,
        },
        // ----- decisions form control
        {
          status: 'rejected_request',
          etape: 'DOSSIER REJETTÉ POUR VICE DE FORME',
          action_label: 'REJETER LE DOSSIER POUR VICE DE FORME',
          class: 'relief-danger',
          final_state: true,
          send_mail: true,
        },
        {
          status: 'accepted_request',
          etape: 'DOSSIER ACCEPTÉ',
          action_label: 'ACCEPTER LE DOSSIER',
          class: 'relief-primary',
          next_step: 'in_notification_process',
        },
        // ---------------------------------------------
        // notification ------------------------------------------------------------------------------------------
        {
          status: 'in_notification_process',
          etape: 'PROCESSUS D\'ENREGISTREMENT DE LA NOTIFICATION',
          action_label: 'LANCER L\'ENREGISTREMENT DE LA NOTIFICATION',
          class: 'relief-dark',
          duration: 30,
          treatment_section: true,
          next_step: 'reclassification_control_check',
          next_decisions_options_count: 2,
        },
        // ----- decisions about information
        {
          status: 'infirm_information',
          etape: 'INFIRMATION LA VÉRACITÉ DES INFORMATIONS',
          action_label: 'INFIRMER LA VÉRACITÉ DES INFORMATIONS',
          class: 'relief-danger',
          decision: true,
          next_step: 'user_notification_notfication_rejected',
        },
        {
          status: 'confirm_information',
          etape: 'CONFIRMATION LA VÉRACITÉ DES INFORMATIONS',
          action_label: 'CONFIRMER LA VÉRACITÉ DES INFORMATIONS',
          class: 'relief-primary',
          duration: 5,
          decision: true,
          next_step: 'user_notification_notfication_accepted',
        },
        // ---------------------------------------------
        {
          status: 'user_notification_notfication_rejected',
          etape: 'NOTIFICATION REJET DE LA NOTIFICATION',
          action_label: 'NOTIFIER AU REQUÉRANT LE REJET DE LA NOTIFICATION',
          class: 'relief-dark',
          final_state: true,
        },
        {
          status: 'update_registration',
          etape: 'MODIFICATION DES RÉGISTRES',
          action_label: 'CONFIRMER LA MODIFICATION DES RÉGISTRES',
          class: 'relief-dark',
          next_step: 'user_notification_notfication_accepted',
        },
        {
          status: 'user_notification_notfication_accepted',
          etape: 'NOTIFICATION ACCEPTATION DE LA NOTIFICATION',
          action_label: 'NOTIFIER AU REQUÉRANT LA PRISE EN COMPTE DE LA NOTIFICATION',
          class: 'relief-dark',
          final_state: true,
        },
      ],
    },
  ];

  sex = ['Masculin', 'Féminin'];

  constructor() {
    this.formulaires = this.formulaires
      .map((item, i) => {
        // const slug = generateSlug(item.code_demande);
        // const slug = item.code_demande;
        const slug = item.code_client
        const params = {
          slug,
          title: item.title,
        }
        return {
          ...item,
          id: i,
          img: require('@/assets/images/illustration/sales.svg'),
          icon: 'ActivityIcon',
          route: 'formulaires-list',
          path: `/formulaires/list/${slug}`,
          matchExact: true,
          isStatsListDropDownLink: true,
          slug,
          params,
          createRouteProps: {
            name: 'formulaires-create',
            path: `/formulaires/create/${slug}`,
            params,
          },
          previewRouteProps: {
            name: 'formulaires-preview',
            path: `/formulaires/preview/${slug}`,
            params,
          },
        }
      })
      .sort((a, b) => (a.title.length > b.title.length ? a : b))
    // this.getListFormulaires()
  }

  getListFormulaires(data) {
    if (data && data.privateOnly) {
      return this.formulaires.filter(item => item.auth === true)
    }
    if (data && data.publicOnly) {
      return this.formulaires.filter(item => item.auth === false)
    }
    return this.formulaires
  }

  getListFormulairesBySector(data) {
    if (this.userRoles) {
      const selectedForms = []

      this.formulaires.map((f, indexF) => {
        this.userRoles.map((r, indexR) => {
          if (f.sector === r.alias) selectedForms.push(f)
        })
      })

      return selectedForms
    }
    return []
  }

  getCountries() {
    return this.countries
  }

  getSex() {
    return this.sex
  }

  getCommunes() {
    return this.communes
  }

  getDepartements() {
    return this.departements
  }

  getListFormulairesForDashboard(data) {
    return this.formulaires.filter(item => item.canBeSHownOnDashboard)
  }

  getFormulaireBySlug(slug) {
    if (!slug) return ''
    const foundItem = this.formulaires.find(e => e.slug === slug)
    if (!foundItem) return null
    return foundItem
  }

  getFormPerTypeEtablissements() {
    const { nom } = this.userProfile() || {}
    const foundItem = this.formulaires.find(
      e => e.typeEts && e.typeEts.includes('agence_voyage'),
    )
    if (!foundItem) return null
    return foundItem
  }

  getFormulaireByModel(model) {
    if (!model) return ''
    const foundItem = this.formulaires.find(e => e.apiRoutePrefixe === model)
    if (!foundItem) return null
    return foundItem
  }

  getFormulaireByCodeDemande(code_demande) {
    if (!code_demande) return ''
    const foundItem = this.formulaires.find(
      e => e.code_demande === code_demande,
    )
    if (!foundItem) return null
    return foundItem
  }

  getSlugByCodeDemande(code_demande) {
    if (!code_demande) return ''
    const foundItem = this.formulaires.find(
      e => e.code_demande === code_demande,
    )
    if (!foundItem) return null
    return foundItem.code_client
  }

  getFormulaireByCodeClient(code_client) {
    if (!code_client) return ''
    const foundItem = this.formulaires.find(
      e => e.code_client === code_client,
    )
    if (!foundItem) return null
    return foundItem
  }

  getDemandeTitleBySlug(slug) {
    const { isEtablissementUser, userProfile, isSuperAdmin } = this.currentUserUtils()

    const appendEtablissementNameToTitle = input => {
      const { nomEtablissement: etsName } = userProfile
      let formattedName = ''
      if (isEtablissementUser) formattedName = ` ( ${etsName} ) `
      return input + formattedName
    }

    const item = this.getFormulaireBySlug(slug)
    if (!item) return ''
    const { roleBasedTitle } = item
    if (roleBasedTitle) {
      const { etablissementUserTitle } = roleBasedTitle
      if (isEtablissementUser) {
        return appendEtablissementNameToTitle(
          etablissementUserTitle || item.title,
        )
      }
    }
    return item.title
  }

  getSectorNameBySlug(slug) {
    const item = this.getFormulaireBySlug(slug)
    return item.sector_name
  }

  // eslint-disable-next-line class-methods-use-this
  getRandomInt(min = 10, max = 1000) {
    // eslint-disable-next-line no-param-reassign
    min = Math.ceil(min)
    // eslint-disable-next-line no-param-reassign
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  getHomePageFormulairesList() {
    return this.formulaires.map(item => ({
      ...item,
    }))
  }

  // eslint-disable-next-line class-methods-use-this
  getListYears(from, to) {
    const oldest = from || 1990
    const newest = to || new Date().getFullYear()
    const yearsDiff = newest - oldest
    const years = Array.from(
      { length: yearsDiff },
      (v, i) => oldest + i + 1,
    ).sort((a, b) => b - a)
    // console.log('years', oldest, newest, yearsDiff, years)
    return years
  }

  // eslint-disable-next-line class-methods-use-this
  getListMonths(locale = 'fr') {
    return [...Array(12).keys()]
      .map(key => new Date(0, key).toLocaleString('fr', { month: 'long' }).toString())
      .map(e => e.charAt(0).toUpperCase() + e.slice(1))
  }

  // eslint-disable-next-line class-methods-use-this
  getListCountries() {
    return countries
  }

  userProfile() {
    return this.userData && this.userData.profile
  }

  // get etablissementId() {
  //   return this.userProfile() && this.userProfile()
  // }

  commissariatDetails() {
    return this.userData && this.userData.commissariatAssociated
  }

  get commissariatId() {
    return this.commissariatDetails() && this.commissariatDetails()._id
  }

  // Pour la faq
  get faqId() {
    return this.faqDetail() && this.faqDetail()._id
  }

  // eslint-disable-next-line class-methods-use-this
  get userData() {
    return localstorageService.getUserData()
  }

  // eslint-disable-next-line class-methods-use-this
  get accessToken() {
    return localstorageService.getJwtToken()
  }

  get userRoles() {
    return (this.userData && this.userData.roles) || null
  }

  get userPermissions() {
    const permissions = (this.userData && this.userData.ability) || []
    return [...permissions, ...initialAbility]
  }

  get userId() {
    return (this.userData && this.userData.id) || null
  }

  // compileUniqueStatuses() {
  //   const formulaires = this.formulaires;
  //   const allProcessSteps = formulaires.flatMap(formulaire => formulaire.process_steps);
  //   const uniqueStatuses = [...new Set(allProcessSteps.map(step => step.status))];
  //   return uniqueStatuses;
  // }
  compileUniqueStatuses() {
    const formulaires = this.formulaires;
    const allProcessSteps = formulaires.flatMap(formulaire => formulaire.process_steps);

    const uniqueStatusesMap = new Map();

    allProcessSteps.forEach(step => {
      if (!uniqueStatusesMap.has(step.status)) {
        uniqueStatusesMap.set(step.status, { status: step.status, etape: step.etape });
      }
    });

    const uniqueStatuses = Array.from(uniqueStatusesMap.values());
    return uniqueStatuses;
  }


  isAdministrationUser() {
    // eslint-disable-next-line camelcase
    const alias_array = ['A', 'MIN', 'AUAT', 'UAT', 'TA', 'TAD', 'AUD']
    return this.userRoles && this.userRoles.find(r => alias_array.includes(r.alias))
  }

  isAdmin() {
    // eslint-disable-next-line camelcase
    return this.userRoles && this.userRoles.find(r => r.alias === 'A')
  }

  isMinister() {
    // eslint-disable-next-line camelcase
    return this.userRoles && this.userRoles.find(r => r.alias === 'MIN')
  }

  isMtcaAgent() {
    // eslint-disable-next-line camelcase
    return this.userRoles && this.userRoles.find(r => r.alias === 'AUAT')
  }

  isMtcaAdmin() {
    // eslint-disable-next-line camelcase
    return this.userRoles && this.userRoles.find(r => r.alias === 'UAT')
  }

  isOtAgent() {
    // eslint-disable-next-line camelcase
    return this.userRoles && this.userRoles.find(r => r.alias === 'TA')
  }

  isOtAdmin() {
    // eslint-disable-next-line camelcase
    return this.userRoles && this.userRoles.find(r => r.alias === 'TAD')
  }

  isAuditor() {
    // eslint-disable-next-line camelcase
    return this.userRoles && this.userRoles.find(r => r.alias === 'AUD')
  }

  isOperatorUEH() {
    // eslint-disable-next-line camelcase
    return this.userRoles && this.userRoles.find(r => r.alias === 'UEH')
  }

  isOperatorUEAV() {
    // eslint-disable-next-line camelcase
    return this.userRoles && this.userRoles.find(r => r.alias === 'UEAV')
  }

  isOperatorUEGT() {
    // eslint-disable-next-line camelcase
    return this.userRoles && this.userRoles.find(r => r.alias === 'UEGT')
  }

  isOperatorUER() {
    // eslint-disable-next-line camelcase
    return this.userRoles && this.userRoles.find(r => r.alias === 'UER')
  }

  // isAdminEtablissement() {
  //   // eslint-disable-next-line camelcase
  //   return this.userRole && this.userRole.code === role_admin_etablissement
  // }

  // isAgentEtablissement() {
  //   // eslint-disable-next-line camelcase
  //   return this.userRole && this.userRole.code === role_agent_etablissement
  // }

  // isCommissaire() {
  //   // eslint-disable-next-line camelcase
  //   return this.userRole && this.userRole.code === role_commissaire
  // }

  // isAgentPolice() {
  //   // eslint-disable-next-line camelcase
  //   return this.userRole && this.userRole.code === role_agent_police
  // }

  currentUserUtils() {
    return {
      // user
      currentUserId: this.userId,
      isAuthenticated: this.accessToken !== null,
      permissions: this.userPermissions,
      userData: this.userData,

      userProfile: this.userProfile(),
      compileUniqueStatuses: this.compileUniqueStatuses(),

      isAdmin: this.isAdmin(),
      isMinister: this.isMinister(),
      isMtcaAdmin: this.isMtcaAdmin(),
      isMtcaAgent: this.isMtcaAgent(),
      isOtAdmin: this.isOtAdmin(),
      isOtAgent: this.isOtAgent(),
      isAuditor: this.isAuditor(),
      isAdministrationUser: this.isAdministrationUser(),
      // isAdministrationUser: this.isAdministrationUser(),
      // isAdministrationUser: this.isAdmin || this.isMinister || this.isMtcaAgent
      // || this.isMtcaAdmin || this.isOtAdmin || this.isOtAgent || this.isAuditor,
      isOperatorUEH: this.isOperatorUEH(),
      isOperatorUEAV: this.isOperatorUEAV(),
      isOperatorUEGT: this.isOperatorUEGT(),
      isOperatorUER: this.isOperatorUER(),
      isOperator:
        this.isOperatorUEH()
        || this.isOperatorUEAV()
        || this.isOperatorUEGT()
        || this.isOperatorUER(),

      // role
      userRoles: this.userRoles,

      listFormulairesBySector: this.getListFormulairesBySector(),
      listFormulaires: this.getListFormulaires(),

      // to be removed
      countries: this.getCountries(),
      sexs: this.getSex(),
      communes: this.getCommunes(),
      departements: this.getDepartements(),
    }
  }

  countriesAndPlaces(params) {
    const { stringOnly } = params || {}
    const addNameKeyToObj = arr => arr.map(item => {
      if (typeof item === 'string') return { name: item }
      return item
    })

    if (!stringOnly) {
      return {
        countries: addNameKeyToObj(this.countries),
        departements: addNameKeyToObj(this.departements),
        communes: addNameKeyToObj(this.communes),
        arrondissements: addNameKeyToObj(this.arrondissements),
        quartiers: addNameKeyToObj(this.quartiers),
      }
    }

    return {
      countries: this.countries,
      departements: this.departements,
      communes: this.communes,
      arrondissements: this.arrondissements,
      quartiers: this.quartiers,
    }
  }

  turnToSnakeCase(val) {
    return val.toLowerCase().replace(/[\s|-]+/gi, '_')
  }

  turnToCamelCase(val) {
    let words = val
    words = words.replace(/é/gi, 'e')
    words = words.split(' ')

    const first = words.shift()

    words = words.map(word => {
      const firstLetter = word[0]
      return firstLetter.toUpperCase() + word.slice(1)
    })

    const result = first + words.join('')
    return result
  }

  // eslint-disable-next-line class-methods-use-this
  getDayCountBetweenTwoDates(options) {
    const { dates } = options || {}
    if (!dates) {
      return 0
    }
    const { first, second } = dates
    if (!first || !second) {
      return 0
    }
    const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
    const firstDate = new Date(first)
    const secondDate = new Date(second)
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))
    return diffDays
  }

  calculatePercentage(val, total, toFixed) {
    const notValid = !val || !total || val === 0 || total === 0

    if (notValid) return 0
    let result = (val * 100) / total
    // console.log('calculatePercentage', val, total, result)
    if (toFixed) {
      result = result.toFixed(toFixed)
    }
    return result
  }

  getTreatmentTimelineColor(statut) {
    const TreatmentTimeline = [
      {
        title: 'comment',
        color: 'secondary',
      },
      {
        title: 'not_treated',
        color: 'warning',
      },
      {
        title: 'in_treatment',
        color: 'success',
      },
    ]
    return (
      TreatmentTimeline.find(e => e.title === statut) || {
        color: 'primary',
      }
    ).color
  }
}

// export an instance of the class
export default new UtilsService()