export default [
  // {
  //   path: '/configs/parametres',
  //   name: 'config-parametres',
  //   component: () => import('@/views/configs/parametres/Index.vue'),
  //   meta: {
  //     action: 'access',
  //     resource: 'app_configuration',
  //       // action: 'read',
  //       // resource: 'Public',
  //   },
  // },
  {
    path: '/configs/forms',
    name: 'config-forms',
    component: () => import('@/views/configs/evaluations_forms/Index.vue'),
    meta: {
      action: 'access',
      resource: 'app_configuration',
    },
  },
  {
    path: '/meets/list',
    name: 'list-rdv',
    component: () => import('@/components/RDV_CalendarAdmin.vue'),
    meta: {
      action: 'access',
      resource: 'demandes',
    },
  },
  {
    path: '/configs/forms/details/:id/:type_hebergement_id',
    name: 'config-forms_details',
    component: () => import('@/views/configs/evaluations_forms/Details.vue'),
    meta: {
      action: 'access',
      resource: 'app_configuration',
    },
  },
  {
    path: '/configs/parametres',
    name: 'config-parametres',
    component: () => import('@/views/configs/parametres/Index.vue'),
    meta: {
      action: 'access',
      resource: 'app_configuration',
    },
  },
  {
    path: '/configs/parametres/otp',
    name: 'config-parametres-otp',
    component: () => import('@/views/configs/parametres/connexion/index.vue'),
    meta: {
      action: 'access',
      resource: 'app_configuration',
    },
  },
  {
    path: '/configs/parametres/langues',
    name: 'config-parametres-langues',
    component: () => import('@/views/configs/parametres/langues/index.vue'),
    meta: {
      action: 'access',
      resource: 'app_configuration',
    },
  },
  {
    path: '/configs/parametres/agregator',
    name: 'config-parametres-agregator',
    component: () => import('@/views/configs/parametres/agregators/AgregatorKeyCreate.vue'),
    meta: {
      action: 'access',
      resource: 'app_configuration',
    },
  },
  // roles
  {
    path: '/configs/roles',
    name: 'config-roles',
    component: () => import('@/views/configs/roles/Index.vue'),
    meta: {
      action: 'access',
      resource: 'app_configuration',
    },
  },
  {
    path: '/configs/roles-datail/:id',
    name: 'config-roles-datail',
    component: () => import('@/views/configs/roles/details.vue'),
    meta: {
      resource: 'app_configuration',
      action: 'access',
    },
  },
  {
    path: '/configs/permissions',
    name: 'config-permissions',
    component: () => import('@/views/configs/permissions/Index.vue'),
    meta: {
      action: 'access',
      resource: 'app_configuration',
    },
  },
  {
    path: '/configs/faqs-list',
    name: 'configs-faqs-list',
    component: () => import('@/views/pages/faq/faqs/index.vue'),
    meta: {
      resource: 'faq',
      action: 'list',
    },
  },
  {
    path: '/configs/faqs-create',
    name: 'configs-faqs-create',
    component: () => import('@/views/pages/faq/faqs/create.vue'),
    meta: {
      resource: 'faq',
      action: 'create',
    },
  },
  {
    path: '/configs/faqs-details/:id',
    name: 'configs-faqs-detail',
    component: () => import('@/views/pages/faq/faqs/details.vue'),
    meta: {
      resource: 'faq',
      action: 'show',
    },
  },
  {
    path: '/configs/faqs-edit/:id',
    name: 'configs-faqs-edit',
    component: () => import('@/views/pages/faq/faqs/update.vue'),
    meta: {
      resource: 'faq',
      action: 'edit',
    },
  },

  {
    path: '/configs/permissions',
    name: 'permissions-index',
    component: () => import('@/views/cruds/Permissions/Index.vue'),
  },
  {
    path: '/configs/secteurs',
    name: 'secteurs-index',
    component: () => import('@/views/cruds/Secteurs/Index.vue'),
  },
  {
    path: '/configs/typeetablissements',
    name: 'typeetablissements-index',
    component: () => import('@/views/cruds/TypeEtablissements/Index.vue'),
  },
]
